import React from 'react';
import Select from '../select';

export const CodeSelect = (props) => {
  return (
    <>
      <Select {...props}>
        {props.placeholder && (
          // <option value="" disabled selected>
          <option value="">{props.placeholder}</option>
        )}
        {props.data &&
          props.data.map((v, i) => (
            <option
              key={v.code}
              value={v.code}
              selected={v.code === props.defaultValue}
            >
              {v.name}
            </option>
          ))}
      </Select>
    </>
  );
};

export default CodeSelect;
