import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import signInReducer from './containers/SignIn/signInSlice';
import passwordResetReducer from './containers/PasswordReset/Index/passwordResetSlice';
import passwordResetFormReducer from './containers/PasswordReset/Form/passwordResetFormSlice';
import signUpReducer from './containers/SignUp/Index/signUpSlice';
import signUpEmailReducer from './containers/SignUp/Email/signUpEmailSlice';
import signUpFormReducer from './containers/SignUp/Form/signUpFormSlice';
import postsReducer from './containers/Posts/Index/postsSlice';
import projectsReducer from './containers/Projects/Index/projectsSlice';
import goodsReducer from './containers/Goods/Index/goodsSlice';
import partnersReducer from './containers/Partners/Index/partnersSlice';

import myPageReducer from './containers/myPage/Index/myPageSlice';
import myPageEmailReducer from './containers/myPage/Email/emailSlice';
import myPagePhoneNumberReducer from './containers/myPage/PhoneNumber/phoneNumberSlice';
import myPagePasswordReducer from './containers/myPage/Password/passwordSlice';
import myPageLeaveReducer from './containers/myPage/Leave/leaveSlice';

import userTypeChangeReducer from './containers/myPage/UserTypeChange/userTypeChangeSlice';

import myPartnersIntroductionReducer from './containers/myPartners/Introduction/introductionSlice';
import myPartnersTechnologyReducer from './containers/myPartners/Technology/technologySlice';
import myPartnersLicenseReducer from './containers/myPartners/License/licenseSlice';
import myPartnersEducationReducer from './containers/myPartners/Education/educationSlice';
import myPartnersGoodsReducer from './containers/myPartners/Goods/goodsSlice';

import myClientsReducer from './containers/myClients/Index/myClientsSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    signIn: signInReducer,
    passwordReset: passwordResetReducer,
    passwordResetForm: passwordResetFormReducer,
    signUp: signUpReducer,
    signUpEmail: signUpEmailReducer,
    signUpForm: signUpFormReducer,
    posts: postsReducer,
    projects: projectsReducer,
    goods: goodsReducer,
    partners: partnersReducer,

    myPage: myPageReducer,
    myPageEmail: myPageEmailReducer,
    myPagePhoneNumber: myPagePhoneNumberReducer,
    myPagePassword: myPagePasswordReducer,
    myPageLeave: myPageLeaveReducer,

    userTypeChange: userTypeChangeReducer,

    myPartnersIntroduction: myPartnersIntroductionReducer,
    myPartnersTechnology: myPartnersTechnologyReducer,
    myPartnersLicense: myPartnersLicenseReducer,
    myPartnersEducation: myPartnersEducationReducer,
    myPartnersGoods: myPartnersGoodsReducer,

    myClients: myClientsReducer
  }
});
