import React from 'react';

export const RecruitmentBadge = (props) => {
  const { diffDeadlineDays } = props;
  return (
    <div
      className={`${
        diffDeadlineDays >= 0 ? 'bg-green-600' : 'bg-gray-900'
      } py-1 px-4 rounded text-xs text-white mr-2 max-h-6`}
    >
      {diffDeadlineDays >= 0 ? '모집중' : '모집마감'}
    </div>
  );
};

export default RecruitmentBadge;
