import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
// import { Dialog, Transition } from '@headlessui/react';
import fieldsTreeCode from '../../code/fieldsTree';

import { Title2 } from '../title';
import Button from '../button';
// import Label from '../../../components/label';
// import Input from '../input';

const MenuContainer = styled.div`
  ${tw`
    // bg-gray-50
    border
  `}
`;
const Menu = styled.ul``;

const MenuList = styled.li`
  ${tw`
    relative
    block
    w-full
    text-base
    hover:bg-gray-100
    cursor-pointer
    py-2 px-2
  `}
  ${({ active }) => active && tw`bg-gray-100`}
`;

export default function FieldSelect(props) {
  const [fieldsData1, setFieldsData1] = useState(fieldsTreeCode);
  const [fieldsData2, setFieldsData2] = useState([]);
  const [fieldsData3, setFieldsData3] = useState([]);

  const [selectedField1, setSelectedField1] = useState('');
  const [selectedField2, setSelectedField2] = useState('');
  const [selectedField3, setSelectedField3] = useState('');

  const [fieldCode, setFieldCode] = useState('');

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const handleConfirm = () => {
    props.selected(fieldCode);
    console.log('xxx', 'xxx');
    setOpen(false);
  };

  const handleClickCode1 = (e, code) => {
    console.log('code', code);
    setFieldCode(code);
    setSelectedField1(code);
    setSelectedField2('');
    setSelectedField3('');
    for (let v of fieldsData1) {
      if (v.code === code) {
        // console.log('name', v);
        v.sub !== undefined && v.sub.length > 0
          ? setFieldsData2(v.sub)
          : setFieldsData2([]);
        setFieldsData3([]);
      }
    }
  };
  const handleClickCode2 = (e, code) => {
    setFieldCode(code);

    setSelectedField2(code);
    setSelectedField3('');
    console.log('code', code);
    for (let v of fieldsData2) {
      if (v.code === code) {
        // console.log('name', v);
        v.sub !== undefined && v.sub.length > 0
          ? setFieldsData3(v.sub)
          : setFieldsData3([]);
      }
    }
  };

  const handleClickCode3 = (e, code) => {
    setFieldCode(code);
    setSelectedField3(code);
    console.log('code', code);
  };

  return (
    <>
      <Button idx="outline" type="button" onClick={handleOpen}>
        {props.label || `전문분야 선택`}
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
        transitionDuration={0}
      >
        <DialogTitle id="form-dialog-title">
          <Title2>전문분야</Title2>
        </DialogTitle>
        <DialogContent>
          <form id="form">
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <div className="mt-5 mr-4">
                <div className="grid grid-cols-3 gap-5">
                  <MenuContainer>
                    <Menu>
                      {fieldsData1.map((v, i) => (
                        <MenuList
                          key={v.code}
                          onClick={(e) => handleClickCode1(e, v.code)}
                          active={v.code === selectedField1}
                          // className={classes.menuItem}
                        >
                          {v.name}
                        </MenuList>
                      ))}
                    </Menu>
                  </MenuContainer>
                  <MenuContainer>
                    <Menu>
                      {fieldsData2.map((v) => (
                        <MenuList
                          key={v.code}
                          onClick={(e) => handleClickCode2(e, v.code)}
                          active={v.code === selectedField2}
                          // className={classes.menuItem}
                        >
                          {v.name}
                        </MenuList>
                      ))}
                    </Menu>
                  </MenuContainer>
                  <MenuContainer>
                    <Menu>
                      {fieldsData3.map((v) => (
                        <MenuList
                          key={v.code}
                          value={v.code}
                          onClick={(e) => handleClickCode3(e, v.code)}
                          active={v.code === selectedField3}
                          // className={classes.menuItem}
                        >
                          {v.name}
                        </MenuList>
                      ))}
                    </Menu>
                  </MenuContainer>
                </div>
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={handleConfirm}
            >
              확인
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={handleClose}
            >
              취소
            </button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
