import Logo from '../logo';

export default function Bottom(props) {
  return (
    <div className="w-full mt-12 bg-gray-500">
      <div className="max-w-screen-lg m-auto text-white">
        <div className="flex py-8 border-b">
          <div className="text-sm font-medium">고객센터</div>
          <div className="text-sm ml-10">공지사항</div>
          <div className="text-sm ml-10">이용안내</div>
          <div className="text-sm ml-10">자주하는 질문</div>
        </div>
        <div className="flex py-6">
          <div className="w-48">
            <Logo color="white" />
          </div>
          <div>
            <div className="text-sm">
              메디비엔비 (대표: 김남수) / 서울특별시 강남구 테헤란로 000 빌딩
              3층 메디비엔비
            </div>
            <div className="text-sm mt-2">
              사업자등록번호: 000-00-00000 / 통신판매업신고:
              제0000-서울강남-0000000 호 / 직업정보제공사업 신고번호:
              J0000000000000
            </div>
            <div className="text-sm mt-6">© 2013 Mediconsole.</div>
          </div>
        </div>
      </div>
    </div>
  );
}
