import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  signUpEmailCreate,
  signUpEmailStore,
  signUpEmailSelector,
  clearState
} from './signUpEmailSlice';

import Button from '../../../components/button';
import Navbar from '../../../components/navbar';
// import { LockClosedIcon } from '@heroicons/react/solid';
import topImage from '../../../../assets/images/top.png';
import { TitleLg, TitleXl } from '../../../components/title';
import { Link, useHistory } from 'react-router-dom';
import Input from '../../../components/input';
import isEmail from 'validator/lib/isEmail';
import Label from '../../../components/label';
import goLogin from '../../../utils/goLogin';

const PageContiner = styled.div`
  ${tw`
    flex
    flex-col
    w-full
    h-full
    items-center
    overflow-x-hidden
  `}
`;

const TopImageContainer = styled.div`
  min-height: 81px;
  background-color: #e3f8f2;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  ${tw`
    w-full
    // items-center  
    justify-items-center
    
  `}
  img {
    margin: auto;
    /* width: auto; */
    /* height: 100%; */
  }
`;

const ContentsContiner = styled.div`
  ${tw`
    w-full
    max-w-screen-lg
    items-center
    px-4 py-2
    sm:px-80 sm:py-6
  `};
`;

export default function Email(props) {
  // auth
  const history = useHistory();

  // redux
  const dispatch = useDispatch();
  const {
    createStatus,
    createMessage,
    createData,
    storeStatus,
    storeMessage,
    storeData
  } = useSelector(signUpEmailSelector);

  // react-form-hook
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    getValues,
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    console.log('onSubmit data', data);
    dispatch(signUpEmailStore({ reqData: data }));
  };

  // page data
  const [pageLoading, setPageLoading] = useState(true);
  useEffect(() => {
    setPageLoading(true);
    dispatch(signUpEmailCreate());
    return () => {
      //
    };
  }, []);

  useEffect(() => {
    // console.log('showMessage2', showMessage);
    if (createStatus === 'completed') {
      // 로그인 자료 없으면 로그인으로 보낸다.
      if (!createMessage && createMessage !== 'success') {
        // alert(createMessage);
        // goLogin(history);
      } else {
        // setTotalPage(Math.ceil(rData.totalCount / rData.limitCount));
        setPageLoading(false);
      }
    }
    return () => {
      //
    };
  }, [createStatus]);

  return (
    <PageContiner>
      <Navbar />
      <TopImageContainer>
        <img src={topImage} />
      </TopImageContainer>
      <ContentsContiner>
        <div className="mt-7">
          <TitleXl>이메일 인증 회원가입 1/2</TitleXl>
        </div>
        <form id="form" onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-8">
            <Label htmlFor="email">이메일</Label>
            <Controller
              render={({ field, fieldState }) => (
                <Input
                  id="email"
                  value={field.value}
                  onChange={(e) => {
                    setValue('email', e.target.value);
                    field.onChange(e);
                  }}
                  inputRef={field.ref}
                  placeholder="이메일을 입력해 주세요"
                  error={fieldState.error}
                  errorMessage={errors.email && errors.email.message}
                />
              )}
              name="email"
              control={control}
              rules={{
                validate: (value) => {
                  // console.log('value', value);
                  if (!value) return '이메일을 입력해 주세요';
                  if (!isEmail(value)) return '이메일 형식이 잘못되었습니다';
                  return true;
                }
              }}
            />
          </div>
          <div class="mt-6">
            <Button type="submit" fullSize>
              이메일 재설정 링크 발송
            </Button>
          </div>
          <div className="mt-10">
            <span className="text-base">
              입력하신 이메일로 회원가입 링크를 발송 했습니다. <br />
              메일을 확인 하시고 링크를 통해 가입해 주세요. <br /> <br />
              메일을 받지 못하셨다면 스팸처리 되었는 지 확인 하시고 <br />
              다시 받기를 원하신다면{' '}
              <span className="text-blue-600">다시받기</span>를 클릭해주세요.
            </span>
          </div>
        </form>
      </ContentsContiner>
    </PageContiner>
  );
}
