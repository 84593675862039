import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

const HelpMenuContainer = styled.div`
  ${tw`
    w-60
    items-center
    relative
    float-right
    rounded
    border
    border-gray-300
  `};
`;

export default function HelpMenu(props) {
  return <HelpMenuContainer>searchMenu</HelpMenuContainer>;
}
