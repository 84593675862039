import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  signUpFormCreate,
  signUpFormStore,
  signUpFormSelector,
  clearState
} from './signUpFormSlice';
import { getToken } from '../../../utils/session';

import { useLocation, useHistory, Link } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';

import Navbar from '../../../components/navbar';
import topImage from '../../../../assets/images/top.png';
import Label from '../../../components/label';
import Button, { LoadingButton } from '../../../components/button';
import Input, { ErrorMessage, InputPassword } from '../../../components/input';
import { Title2, TitleXl } from '../../../components/title';
import A from '../../../components/a';
import Loading from '../../../components/loading';

const PageContiner = styled.div`
  ${tw`
    flex
    flex-col
    w-full
    h-full
    items-center
    overflow-x-hidden
  `}
`;

const TopImageContainer = styled.div`
  min-height: 81px;
  background-color: #e3f8f2;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  ${tw`
    w-full
    // items-center  
    justify-items-center
    
  `}
  img {
    margin: auto;
    /* width: auto; */
    /* height: 100%; */
  }
`;

const ContentsContiner = styled.div`
  ${tw`
    w-full
    max-w-screen-lg
    items-center
    px-4 py-2
    sm:px-80 sm:py-6
  `};
`;

export default function Form(props) {
  const code = props.match.params.code;
  // query-string
  const history = useHistory();
  const { search } = useLocation();

  // redux
  const dispatch = useDispatch();
  const { createStatus, createMessage, storeStatus, storeMessage } =
    useSelector(signUpFormSelector);

  // form
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm();

  // submit
  const onSubmit = (data) => {
    console.log(data);
    dispatch(signUpFormStore({ code: code, reqData: data }));
  };

  // submit callback
  useEffect(() => {
    if (storeStatus === 'loading') {
      // dispatch(clearState());
    }
    if (storeStatus === 'completed') {
      dispatch(clearState());
      if (storeMessage === 'success') {
        toast.success('회원가입이 완료 되었습니다.');
        history.push('/');
      } else {
        toast.error(storeMessage);
      }
      // history.replace('/')
      console.log('완료');
    }
    console.log('submitStatus', storeStatus);
  }, [storeStatus]);

  // page data
  const [pageLoading, setPageLoading] = useState(true);
  useEffect(() => {
    // setValue('code', code);
    dispatch(signUpFormCreate({ code: code }));
  }, []);

  // page data return
  // 로딩 처리
  const [errorMessage, setErrorMessage] = useState('');
  useEffect(() => {
    if (createStatus === 'completed') {
      // 로그인 자료 없으면 로그인으로 보낸다. (아무나)
      console.log('createMessage', createMessage);
      setPageLoading(false);
      if (createMessage && createMessage !== 'success') {
        // alert(createMessage);
        setErrorMessage(createMessage);
        // goLogin(history);
      } else {
        // setPageLoading(false);
      }
    }
  }, [createStatus]);

  console.log('props.match.params.code', props.match.params.code);

  return (
    <PageContiner>
      <Navbar />
      <TopImageContainer>
        <img src={topImage} />
      </TopImageContainer>
      {pageLoading ? (
        <ContentsContiner>
          <Loading />
        </ContentsContiner>
      ) : (
        <ContentsContiner>
          <div className="mt-7">
            <TitleXl>이메일 인증 회원가입 2/2</TitleXl>
          </div>
          {errorMessage ? (
            <div className="rounded-md bg-red-200 p-10 mt-10">
              {errorMessage}
              <div className="mt-5 text-blue-800">
                <Link to="/sign-up/email">
                  이메일 재설정 링크 발송 페이지로 이동
                </Link>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* <input type="text" {...register('code')} /> */}
              <div className="mt-8">
                <Label htmlFor="">이용목적 (가입 후 변경가능합니다)</Label>
                <div className="mt-2">
                  <div>
                    <input
                      type="radio"
                      id="user_type_client"
                      value="CLIENT"
                      {...register('userType', {
                        required: '이용목적을 선택해 주세요'
                      })}
                    />
                    <Label htmlFor="user_type_client" className="pl-1">
                      클라이언트 (프로젝트를 의뢰할 기업/ 개인)
                    </Label>
                  </div>
                  <div className="mt-1">
                    <input
                      type="radio"
                      id="user_type_partner"
                      value="PARTNER"
                      {...register('userType', {
                        required: '이용목적을 선택해 주세요'
                      })}
                    />
                    <Label htmlFor="user_type_partner" className="pl-1">
                      파트너스 (프로젝트를 수주할 기업/ 개인)
                    </Label>
                  </div>
                </div>
                {errors.userType && errors.userType.message && (
                  <ErrorMessage>{errors.userType.message}</ErrorMessage>
                )}
              </div>
              {/* <div className="mt-6">
            <Label htmlFor="name">이름</Label>
            <Controller
              render={({ field, fieldState }) => (
                <Input
                  id="name"
                  value={field.value}
                  onChange={field.onChange}
                  inputRef={field.ref}
                  placeholder="이름을 입력해 주세요"
                  error={fieldState.error}
                  errorMessage={errors.name && errors.name.message}
                />
              )}
              name="name"
              control={control}
              rules={{ required: '이름을 입력해 주세요' }}
            />
          </div> */}
              <div className="mt-6">
                <Label htmlFor="email">이메일</Label>
                <Controller
                  render={({ field, fieldState }) => (
                    <Input
                      id="email"
                      value={field.value}
                      onChange={field.onChange}
                      inputRef={field.ref}
                      placeholder="이메일을 입력해 주세요"
                      error={fieldState.error}
                      errorMessage={errors.email && errors.email.message}
                    />
                  )}
                  name="email"
                  control={control}
                  rules={{
                    validate: (value) => {
                      // console.log('value', value);
                      if (!value) return '이메일을 입력해 주세요';
                      if (!isEmail(value))
                        return '이메일 형식이 잘못되었습니다';
                      return true;
                    }
                  }}
                />
              </div>
              <div className="mt-6">
                <Label htmlFor="password">비밀번호</Label>
                <InputPassword
                  id="password"
                  placeholder="비밀번호"
                  error={errors.password}
                  errorMessage={errors.password && errors.password.message}
                  {...register('password', {
                    required: '비밀번호를 입력해 주세요'
                  })}
                />
              </div>
              <div className="mt-6">
                <input
                  id="term_check"
                  type="checkbox"
                  {...register('termCheck', {
                    required:
                      '이용약관 및 개인정보취급방침에 동의 하셔야 가입이 완료 됩니다'
                  })}
                />
                <Label htmlFor="term_check" className="pl-2">
                  이용약관 및 개인정보취급방침 에 동의합니다.
                </Label>
                {errors.termCheck && errors.termCheck.message && (
                  <ErrorMessage>{errors.termCheck.message}</ErrorMessage>
                )}
              </div>
              <div className="mt-8">
                <LoadingButton
                  type="submit"
                  fullSize
                  // disabled
                  disabled={storeStatus === 'loading'}
                >
                  회원가입
                </LoadingButton>
                {/* <Button fullSize>회원가입</Button> */}
              </div>
              <div className="flex items-center justify-between mt-6">
                <A to="/sign-in">로그인</A>
                <A to="/sign-in">비밀번호 재설정</A>
              </div>
              {/* <div>type : {sessionStorage.getItem('type')}</div> */}
              {/* <div>token : {getToken()}</div> */}
            </form>
          )}
        </ContentsContiner>
      )}
    </PageContiner>
  );
}
