import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import tw, { css } from 'twin.macro';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  introductionEdit,
  introductionSubmit,
  introductionSelector,
  clearState
} from './introductionSlice';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

// import { Dialog, Transition } from '@headlessui/react';
// import { ExclamationIcon } from '@heroicons/react/outline';
import { Title2 } from '../../../components/title';
import Label from '../../../components/label';
import Input, { ErrorMessage } from '../../../components/input';
import Select, { SelectDownIcon } from '../../../components/select';
import Button, { IconButton } from '../../../components/button';
import CodeSelect from '../../../components/codeSelect';

import fieldsCodeCode from '../../../code/fieldsCode';
import techLevelCode from '../../../code/techLevel';
import experienceCode from '../../../code/experience';
import FieldSelect from '../../../components/fieldSelect';
import { toast } from 'react-toastify';
import YearSelect from '../../../components/yearSelect';
import MonthSelect from '../../../components/monthSelect';
import Textarea from '../../../components/textarea';
import companyTypeCode from '../../../code/companyTypes';
import statesCode from '../../../code/states';
import citiesCode from '../../../code/cities';
import { fieldName2 } from '../../../utils/codeView';
import { CloseIcon } from '../../../components/icon';

export default function Edit(props) {
  const { open, editClose, refresh } = props;

  // redux
  const dispatch = useDispatch();
  const { editStatus, editMessage, editData, submitStatus, submitMessage } =
    useSelector(introductionSelector);

  // field
  //const [fieldCode, setFieldCode] = useState('');
  const [fields, setFields] = useState([]);
  const fieldView = (code, i) => {
    return (
      <div className={i === 0 ? 'flex' : 'flex mt-1'}>
        {fieldName2(code)}
        <span
          className="mt-1 ml-3 cursor-pointer"
          onClick={(e) => handleFieldRemove(e, code)}
        >
          <CloseIcon />
        </span>
      </div>
    );
  };

  // console.log('fields ++++', fields);

  const fieldSelected = (code) => {
    // console.log('code code code', code);
    // console.log('fields fields fields', fields);
    if (!fields || !fields.includes(code)) {
      const arr = [...fields];
      arr.push(code);
      setValue('fields', arr.join(','), {
        shouldValidate: true,
        shouldDirty: true
      });
      setFields([...fields, code]);
    }

    // console.log('fields selected', fields.join(','));
  };

  const handleFieldRemove = (e, code) => {
    const arr = [...fields.filter((v) => v !== code)];
    setValue('fields', arr.join(','), {
      shouldValidate: true,
      shouldDirty: true
    });
    setFields([...fields.filter((v) => v !== code)]);
  };

  // form
  // console.log('rererere');

  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      // setOpen(false);
      editClose();
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    getValues,
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    console.log('data', data);
    dispatch(introductionSubmit({ reqData: data }));
  };

  // form company type
  const [foundedHidden, setFoundedHidden] = useState(false);
  const handleTypeChange = (code) => {
    setFoundedHidden(code === 'FREE');
  };

  // form state - city
  const [cities, setCities] = useState([]);
  const handleStateChange = (code) => {
    setCities(code ? citiesCode[code] : []);
  };

  // page data status
  useEffect(() => {
    // console.log('submitStatus 1', submitStatus);
    if (submitStatus === 'completed') {
      toast.success('완료 되었습니다');
      refresh();
      editClose();
    }
    return () => {};
  }, [submitStatus]);

  // const [open, setOpen] = useState(false);
  // const handleOpen = () => {
  //   setOpen(true);
  // };

  const [pageLoading, setPageLoading] = useState(true);
  // page data
  useEffect(() => {
    console.log('시작 open', open);
    reset();
    // fieldSelected();

    if (open) {
      setPageLoading(true);
      // reset();
      dispatch(introductionEdit());
    }
    return () => {
      // setPageLoading(true);
      reset();
    };
  }, [open]);

  // console.log('렌더 open', open);

  // page data status
  useEffect(() => {
    console.log('editStatus 1', editStatus);

    if (editStatus === 'completed') {
      if (editData) {
        console.log('결과 있슴.', editData.companyName);
        reset({
          companyTypeCode: editData.companyTypeCode,
          founded: editData.founded,
          name: editData.name,
          addressState: editData.addressState,
          addressCity: editData.addressCity,
          inWork: editData.inWork,
          issueTax: editData.issueTax,
          summary: editData.summary,
          introduce: editData.introduce,
          fields: editData.fields
        });
        editData.fields && setFields(editData.fields?.split(','));
        handleTypeChange(editData.companyTypeCode);
      }
      setPageLoading(false);
    }
    return () => {
      //
    };
  }, [editStatus]);

  // const cancelButtonRef = useRef(null);
  return (
    <>
      {!pageLoading && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="sm"
          transitionDuration={0}
          className=""
        >
          <DialogTitle id="form-dialog-title">
            <div className="font-medium text-2xl pt-3 pl-3">기본정보</div>
          </DialogTitle>
          <DialogContent>
            <form id="form" onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-3 sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left">
                <div className="mt-5 ">
                  <Label htmlFor="image">프로필 이미지</Label>
                  <input type="file" {...register('image')} />
                </div>
                <div className="mt-5 ">
                  <div className="mt-5 sm:grid sm:grid-cols-2 sm:gap-5">
                    <div>
                      <Label htmlFor="company_type_code">파트너스 구분</Label>
                      <Controller
                        render={({ field, fieldState }) => (
                          <CodeSelect
                            id="company_type_code"
                            value={field.value}
                            onChange={(e) => {
                              handleTypeChange(e.target.value);
                              field.onChange(e);
                            }}
                            inputRef={field.ref} // wire up the input ref
                            placeholder="파트너스 구분을 선택해 주세요"
                            error={fieldState.error}
                            errorMessage={
                              errors.companyTypeCode &&
                              errors.companyTypeCode.message
                            }
                            data={companyTypeCode}
                          />
                        )}
                        name="companyTypeCode"
                        control={control}
                        rules={{ required: '파트너스 구분을 선택해 주세요' }}
                      />
                    </div>
                    <div className={foundedHidden && 'hidden'}>
                      {/* <div> */}
                      <Label htmlFor="founded">창업년도</Label>
                      <Controller
                        render={({ field, fieldState }) => (
                          <YearSelect
                            id="founded"
                            value={field.value}
                            onChange={field.onChange}
                            inputRef={field.ref} // wire up the input ref
                            placeholder="창업년도를 선택해 주세요"
                            error={fieldState.error}
                            errorMessage={
                              errors.founded && errors.founded.message
                            }
                            startY="1950"
                          />
                        )}
                        name="founded"
                        control={control}
                        rules={{
                          validate: (value) => {
                            return getValues('companyTypeCode') !== 'FREE' &&
                              value === ''
                              ? '창업년도를 선택해 주세요'
                              : true;
                            // console.log('value', value);
                            // console.log(
                            //   'getcompanyTypeCode',
                            //   getValues('companyTypeCode')
                            // );
                            // return true;
                          }
                        }}
                        // rules={{
                        //   required: (value) => {
                        //     console.log(value);
                        //     return '창업년도를 선택해 주세요11';
                        //   }
                        // }}
                      />
                    </div>
                  </div>
                  <div className="mt-5">
                    <Label htmlFor="name">파트너스명</Label>
                    <Controller
                      render={({ field, fieldState }) => (
                        <Input
                          id="name"
                          value={field.value}
                          onChange={field.onChange}
                          inputRef={field.ref} // wire up the input ref
                          placeholder="파트너스명을 입력해 주세요"
                          error={fieldState.error}
                          errorMessage={errors.name && errors.name.message}
                        />
                      )}
                      name="name"
                      control={control}
                      rules={{
                        required: '파트너스명을 입력해 주세요'
                      }}
                    />
                  </div>
                  <div className="mt-5 grid grid-cols-2 gap-5">
                    <div>
                      <Label htmlFor="address_state">파트너스 지역</Label>
                      <Controller
                        render={({ field, fieldState }) => (
                          <CodeSelect
                            id="address_state"
                            value={field.value}
                            onChange={(e) => {
                              handleStateChange(e.target.value);
                              field.onChange(e);
                            }}
                            inputRef={field.ref} // wire up the input ref
                            placeholder="파트너스 지역을 선택해 주세요"
                            error={fieldState.error}
                            errorMessage={
                              errors.addressState && errors.addressState.message
                            }
                            data={statesCode}
                          />
                        )}
                        name="addressState"
                        control={control}
                        rules={{ required: '파트너스 지역을 선택해 주세요' }}
                      />
                    </div>
                    <div>
                      <Label htmlFor="address_city">세부지역</Label>
                      <Controller
                        render={({ field, fieldState }) => (
                          <CodeSelect
                            id="address_city"
                            value={field.value}
                            onChange={field.onChange}
                            inputRef={field.ref} // wire up the input ref
                            placeholder="세부지역을 선택해 주세요"
                            error={fieldState.error}
                            errorMessage={
                              errors.addressCity && errors.addressCity.message
                            }
                            data={cities}
                          />
                        )}
                        name="addressCity"
                        control={control}
                        rules={{ required: '세부지역을 선택해 주세요' }}
                      />
                    </div>
                  </div>
                  <div className="mt-5 sm:flex">
                    <div>
                      <input
                        id="in_work"
                        type="checkbox"
                        {...register('inWork')}
                      />
                      <Label htmlFor="in_work" className="pl-2">
                        상주가능
                      </Label>
                    </div>
                    <div className="sm:ml-4 sm:mt-0 mt-4">
                      <input
                        id="issue_tax"
                        type="checkbox"
                        {...register('issueTax')}
                      />
                      <Label htmlFor="issue_tax" className="pl-2">
                        세금계산서 발행가능
                      </Label>
                    </div>
                  </div>
                  <div className="mt-5">
                    <Label htmlFor="summary">한줄 소개</Label>
                    <Controller
                      render={({ field, fieldState }) => (
                        <Input
                          id="summary"
                          value={field.value}
                          onChange={field.onChange}
                          inputRef={field.ref} // wire up the input ref
                          placeholder="한줄 소개를 입력해 주세요"
                          error={fieldState.error}
                          errorMessage={
                            errors.summary && errors.summary.message
                          }
                        />
                      )}
                      name="summary"
                      control={control}
                      rules={{
                        required: '한줄 소개를 입력해 주세요'
                      }}
                    />
                  </div>
                  <div className="mt-5">
                    <Label htmlFor="introduce">상세 소개</Label>
                    <Controller
                      render={({ field, fieldState }) => (
                        <Textarea
                          id="introduce"
                          value={field.value}
                          onChange={field.onChange}
                          inputRef={field.ref} // wire up the input ref
                          placeholder="개인정보 및 연락처는 입력을 금지 합니다"
                          error={fieldState.error}
                          errorMessage={
                            errors.introduce && errors.introduce.message
                          }
                          rows={5}
                        />
                      )}
                      name="introduce"
                      control={control}
                      rules={{ required: '상세 소개를 입력해 주세요' }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </DialogContent>
          <DialogActions className="flex w-full p-0 bg-gray-100 mt-5">
            <div className="w-full m-0 bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse flow-root">
              <Button
                type="submit"
                form="form"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm"
              >
                확인
              </Button>
              <IconButton
                type="button"
                onClick={handleClose}
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                취소
              </IconButton>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
