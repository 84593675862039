import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { SnsLoginKakao, SnsLoginNaver } from '../../components/snsLogin';
import { TitleXl } from '../../components/title';

const SignInContainer = styled.div`
  ${tw`
    h-full
    items-center
    pt-10 pr-20 pb-10 pl-20
  `};
`;

export default function SnsSignIn() {
  return (
    <SignInContainer>
      <div className="mt-7">
        <TitleXl className="pb-10">또는 SNS 아이디로 간편 로그인</TitleXl>
      </div>
      <form>
        <div className="mb-6">
          <SnsLoginNaver
            onClick={(e) => e.preventDefault}
            label="네이버로 로그인"
          />
        </div>
        <div className="mb-8">
          <SnsLoginKakao
            onClick={(e) => e.preventDefault}
            label="카카오로 로그인"
          />
        </div>
      </form>
    </SignInContainer>
  );
}
