import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  introductionIndex,
  introductionSelector,
  clearState
} from './introductionSlice';
import { useLocation, useHistory } from 'react-router-dom';

import { Title2, TitleXl } from '../../../components/title';

import { Link } from 'react-router-dom';
import Button, { IconButton } from '../../../components/button';
import Edit from './edit';

import {
  fieldName2,
  techLevelName,
  experienceName,
  companyTypeName,
  fieldNames2,
  inWorkName
} from '../../../utils/codeView';
import Loading from '../../../components/loading';
import { getSession } from '../../../utils/session';
import goLogin from '../../../utils/goLogin';
import VerifiedBadge from '../Index/verifiedBadge';
import { diffYM } from '../../../utils/calDate';
import nl2br from 'react-nl2br';
import Input from '../../../components/input';
import {
  CheckDoubleIcon,
  CheckIcon,
  CheckIcon2,
  CloseIcon,
  ContactPhone,
  ExclamationTriangle
} from '../../../components/icon';
import PartnersMenu from '../Index/partnersMenu';
import VerifyBox from '../Index/verifyBox';
import Toggle from '../../../components/toggle';

export default function Content(props) {
  // auth
  const history = useHistory();

  // redux
  const dispatch = useDispatch();
  const { indexStatus, indexMessage, indexData } =
    useSelector(introductionSelector);

  // form
  console.log('content re render');

  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      // setOpen(false);
      editClose();
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    // dispatch(technologySubmit(data));
    // dispatch(technologySubmit(data));
    // console.log('onSubmit technologyId', technologyId);
    // dispatch(technologySubmit({ technologyId: technologyId, reqData: data }));
  };

  // Edit Handle
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const handleEditOpen = (e) => {
    setEditOpen(true);
  };
  const editClose = () => {
    setEditOpen(false);
  };
  const handleDeleteOpen = (e, id) => {
    setDeleteOpen(true);
  };
  const deleteClose = () => {
    setDeleteOpen(false);
  };

  // refresh
  const [rowsRefreshKey, setRowsRefreshKey] = React.useState(0);
  const rowsRefresh = () => {
    const key = rowsRefreshKey + 1;
    setRowsRefreshKey(key);
  };

  // page data
  const [pageLoading, setPageLoading] = useState(true);
  const [rowsLoading, setRowsLoading] = useState(true);

  useEffect(() => {
    dispatch(introductionIndex());
    return () => {
      setRowsLoading(true);
    };
  }, [rowsRefreshKey]);

  useEffect(() => {
    if (indexStatus === 'completed') {
      if (indexMessage && indexMessage !== 'success') {
        alert(indexMessage);
        goLogin(history);
      } else {
        setPageLoading(false);
        setRowsLoading(false);
      }
    }
    return () => {
      //
    };
  }, [indexStatus]);

  return pageLoading ? (
    <div>
      <Loading />
    </div>
  ) : (
    <div>
      <div className="flex-grow text-xl my-4">파트너스 정보</div>
      <VerifyBox />
      <div className="border border-gray-500 rounded-md my-6">
        <PartnersMenu active="contact" />
        <div className="px-2 my-5">
          <div className="text-sm my-4 mx-4">기본정보를 입력해 주세요</div>

          {rowsLoading ? (
            <Loading />
          ) : (
            !rowsLoading &&
            indexData && (
              <>
                <div className="flex w-full h-44 mt-4 border-t border-b border-gray-500">
                  <div className="w-2/3 py-8 px-10 border-r border-gray-500">
                    <div className="font-medium text-xl">활동중</div>
                    <div className="mt-5">
                      '프로젝트 지원' 할 경우 해당 클라이언트에게만 공개 됩니다.
                      <br />
                      파트너스 검색할 경우 공개 되지 않습니다.
                    </div>
                  </div>
                  <div className="flex flex-grow justify-center items-center bg-gray-200">
                    <div className="justify-center">
                      <Toggle id="active" />
                    </div>
                  </div>
                </div>
                <div className="flex w-full h-44 mt-4 border-t border-b border-gray-500">
                  <div className="w-2/3 py-8 px-10 border-r border-gray-500">
                    <div className="font-medium text-xl">
                      이메일로 연락 가능
                    </div>
                    <div className="mt-5">
                      '프로젝트 지원' 할 경우 해당 클라이언트에게만 공개 됩니다.
                      <br />
                      파트너스 검색할 경우 공개 되지 않습니다.
                    </div>
                  </div>
                  <div className="flex flex-grow justify-center items-center bg-gray-200">
                    <div className="justify-center">
                      <Toggle id="email" />
                    </div>
                  </div>
                </div>
                <div className="flex w-full h-44 mt-4 border-t border-b border-gray-500">
                  <div className="w-2/3 py-8 px-10 border-r border-gray-500">
                    <div className="font-medium text-xl">
                      휴대전화로 연락 가능
                    </div>
                    <div className="mt-5">
                      '프로젝트 지원' 할 경우 해당 클라이언트에게만 공개 됩니다.
                      <br />
                      파트너스 검색할 경우 공개 되지 않습니다.
                    </div>
                  </div>
                  <div className="flex flex-grow justify-center items-center bg-gray-200">
                    <div className="justify-center">
                      <Toggle id="phone_number" checked={false} />
                    </div>
                  </div>
                </div>
              </>
            )
          )}
        </div>
      </div>
      <Edit open={editOpen} editClose={editClose} refresh={rowsRefresh} />
    </div>
  );
}
