import styled from 'styled-components';
import tw from 'twin.macro';

const Label = styled.label`
  ${tw`
      // block 
      text-gray-600 
      text-sm 
      font-bold 
      mb-2
  `}
`;

export default Label;
