import React from 'react';
import { LeftIcon, RightIcon } from '../icon';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Link, useHistory } from 'react-router-dom';
import objectToQueryString from '../../utils/objectToQueryString';

const Previous = styled(Link)`
  ${tw`
    relative 
    inline-flex 
    items-center 
    px-2 py-2 
    rounded-l-md 
    border 
    border-gray-300 
    bg-white 
    text-sm 
    font-medium 
    text-gray-500 
    hover:bg-gray-50  
  `}
`;

const Next = styled(Link)`
  ${tw`
    relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50
  `}
`;

const Paging = styled(Link)`
  ${tw`
    relative
    inline-flex 
    items-center
    px-4 py-2 
    border 
    text-sm 
    font-medium
  `}
  ${({ active }) =>
    active
      ? tw`
        z-10 
        bg-indigo-50 
        border-indigo-500 
        text-indigo-600 
      `
      : tw`
        bg-white 
        border-gray-300 
        text-gray-500 
        hover:bg-gray-50
  `}
`;

export default function Pagination(props) {
  const { totalPage, page, query } = props;
  const newQuery = { ...query };
  // const totalPage = 32,
  //   page = 5;
  const history = useHistory();

  let startPage, endPage;

  startPage = Math.floor((page - 1) / 10) * 10 + 1;
  endPage = Math.ceil(page / 10) * 10;
  if (endPage > totalPage) endPage = totalPage;

  // console.log('startPage', startPage);
  // console.log('endPage', endPage);

  // const objectToQueryString = (obj) => {
  //   var str = [];
  //   for (var p in obj)
  //     str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
  //   return str.join('&');
  // };

  const pagings = () => {
    const result = [];
    for (let i = startPage; i <= endPage; i++) {
      newQuery.page = i;
      result.push(
        <Paging
          to={history.location.pathname + '?' + objectToQueryString(newQuery)}
          active={i === page}
          key={i}
        >
          {i}
        </Paging>
      );
    }
    return result;
  };

  const previousPaging = () => {
    newQuery.page = startPage - 1;
    return startPage > 1 ? (
      <Link
        to={history.location.pathname + '?' + objectToQueryString(newQuery)}
        className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
      >
        <span className="sr-only">Previous</span>
        <LeftIcon />
      </Link>
    ) : (
      <div className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 cursor-not-allowed">
        <span className="sr-only">Previous</span>
        <LeftIcon />
      </div>
    );
  };

  const nextPaging = () => {
    newQuery.page = endPage + 1;
    return endPage < totalPage ? (
      <Link
        to={history.location.pathname + '?' + objectToQueryString(newQuery)}
        className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
      >
        <span className="sr-only">Next</span>
        <RightIcon />
      </Link>
    ) : (
      <div className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 cursor-not-allowed">
        <span className="sr-only">Next</span>
        <RightIcon />
      </div>
    );
  };

  return (
    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-center mt-10">
      <div>
        <nav
          className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
          aria-label="Pagination"
        >
          {/* <Previous href="#">
            <span className="sr-only">Previous</span>
            <LeftIcon />
          </Previous> */}
          {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
          {/* <Paging href="#" active>
            1
          </Paging> */}
          {previousPaging()}
          {pagings()}
          {nextPaging()}
          {/* <Next>
            <span className="sr-only">Next</span>
            <RightIcon />
          </Next> */}
        </nav>
      </div>
    </div>
  );
}
