import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { setSession, getToken } from '../../../utils/session';

// edit
export const phoneNumberEdit = createAsyncThunk(
  '/my-page/phone-number/edit',
  (_, thunkAPI) => {
    const url = `/my-page/phone-number/edit`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        console.log('response.data', response.data);
        setSession(response.data.user);
        if (response.data.success && response.data.error !== 'auth') {
          setSession(response.data.data?.user);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

// update
export const phoneNumberUpdate = createAsyncThunk(
  '/my-page/phone-number/update',
  ({ reqData }, thunkAPI) => {
    const url = `/my-page/phone-number`;
    const formData = {
      mode: reqData.mode,
      phoneNumber: reqData.phoneNumber,
      confirmNumber: reqData.confirmNumber
    };

    console.log('formData()', formData);
    console.log('getToken()', getToken());
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        // 'content-type': 'multipart/form-data'
        'Content-Type': 'application/json'
      }
    };
    return axios
      .post(url, formData, config)
      .then((response) => {
        console.log('response.data', response.data);
        if (response.data.success && response.data.error !== 'auth') {
          setSession(response.data.data?.user);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

// code
export const phoneNumberCode = createAsyncThunk(
  '/my-page/phone-number/code',
  ({ reqData }, thunkAPI) => {
    const url = `/my-page/phone-number/code`;
    const formData = {
      mode: reqData.mode,
      phoneNumber: reqData.phoneNumber
    };
    // console.log('formData()', formData);
    // console.log('getToken()', getToken());
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        // 'content-type': 'multipart/form-data'
        'Content-Type': 'application/json'
      }
    };
    return axios
      .post(url, formData, config)
      .then((response) => {
        console.log('response.data', response.data);
        if (response.data.success && response.data.error !== 'auth') {
          setSession(response.data.data?.user);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const phoneNumberSlice = createSlice({
  name: 'myPagePhoneNumber',
  initialState: {
    editStatus: '',
    editResult: {},
    editData: {},
    updateStatus: '',
    updateResult: {},
    updateData: {},
    codeStatus: '',
    codeResult: {},
    codeData: {}
  },
  reducers: {
    clearState: (state) => {
      state.editStatus = '';
      state.editResult = {};
      state.editData = {};
      state.updateStatus = '';
      state.updateResult = {};
      state.updateData = {};
      state.codeStatus = '';
      state.codeResult = {};
      state.codeData = {};
      return state;
    }
  },
  extraReducers: {
    [phoneNumberEdit.pending]: (state) => {
      state.editStatus = 'loading';
    },
    [phoneNumberEdit.fulfilled]: (state, { payload }) => {
      console.log('payload edit', payload);
      state.editStatus = 'completed';
      state.editResult = {
        success: payload?.success,
        message: payload?.message,
        error: payload?.error
      };
      state.editData = payload?.data?.pageData;
    },
    [phoneNumberEdit.rejected]: (state, { payload }) => {
      state.editStatus = 'error';
    },
    [phoneNumberUpdate.pending]: (state) => {
      state.updateStatus = 'loading';
    },
    [phoneNumberUpdate.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.updateStatus = 'completed';
      state.updateResult = {
        success: payload?.success,
        message: payload?.message,
        error: payload?.error
      };
    },
    [phoneNumberUpdate.rejected]: (state, { payload }) => {
      state.updateStatus = 'error';
    },
    [phoneNumberCode.pending]: (state) => {
      state.codeStatus = 'loading';
    },
    [phoneNumberCode.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.codeStatus = 'completed';
      state.codeResult = {
        success: payload?.success,
        message: payload?.message,
        error: payload?.error
      };
    },
    [phoneNumberCode.rejected]: (state, { payload }) => {
      state.codeStatus = 'error';
    }
  }
});

export const { clearState } = phoneNumberSlice.actions;
export const phoneNumberSelector = (state) => state.myPagePhoneNumber;
export default phoneNumberSlice.reducer;
