import React from 'react';
import { Link } from 'react-router-dom';

const menuListClass = (active, option) => {
  // console.log('active, option', active, option);
  let classes = ` text-center py-4 text-base `;
  classes += active ? ` bg-gray-100 ` : ` hover:bg-gray-100 `;
  classes += option === 'start' && ` rounded-t-md rounded-r-0 `;
  classes += option === 'end' && ` rounded-t-md rounded-l-0 `;
  return classes;
};

export default function PartnersMenu(props) {
  // console.log('props.active', props.active);
  return (
    <div className="w-full grid grid-cols-5 border-b border-gray-400">
      <Link
        to="/my-partners/introduction"
        className={menuListClass(props.active === 'introduction', 'start')}
      >
        기본정보
      </Link>
      <Link
        to="/my-partners/technology"
        className={menuListClass(props.active === 'technology')}
      >
        기술정보
      </Link>
      <Link
        to="/my-partners/license"
        className={menuListClass(props.active === 'license')}
      >
        자격정보
      </Link>
      <Link
        to="/my-partners/education"
        className={menuListClass(props.active === 'education')}
      >
        교육정보
      </Link>
      <Link
        to="/my-partners/contact"
        className={menuListClass(props.active === 'contact', 'end')}
      >
        연락정보
      </Link>
    </div>
  );
}
