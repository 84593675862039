import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Title3 } from '../../../components/title';
import { Link } from 'react-router-dom';
import { HomeIcon } from '../../../components/icon';

import categoriesCode from '../../../code/categories';

const MenuContainer = styled.div`
  ${tw`
    w-60
    items-center
    relative
    float-left
    rounded
    border
    border-gray-300
  `};
`;

const MenuTitleContainer = styled.div`
  ${tw`
    flex
    w-full
    py-6 px-6
    justify-between
  `}
`;

const MenuListContainer = styled.div``;

const MenuList = styled(Link)`
  ${tw`
    relative
    block
    w-full
    border-t
    py-3 px-8
    text-base
    hover:bg-gray-100
  `}
  ${({ active }) => active && tw`bg-gray-100`}
`;

export default function LeftMenu(props) {
  const { active } = props;
  return (
    <MenuContainer>
      <MenuTitleContainer>
        <Title3>정보센터</Title3>
        <Link to="/my-partners" className="pt-1.5">
          <HomeIcon />
        </Link>
      </MenuTitleContainer>
      <MenuListContainer>
        {categoriesCode.map((o) => {
          return (
            <MenuList
              to={`/posts?category=${o.code}`}
              active={o.code === active}
            >
              {o.name}
            </MenuList>
          );
        })}
      </MenuListContainer>
    </MenuContainer>
  );
}
