import React, { useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

import statesCode from '../../code/states';
import citiesCode from '../../code/cities';
import Button from '../button';
import { TitleLg } from '../title';

const MenuContainer = styled.div`
  ${tw`
    // bg-gray-50
    border
  `}
`;
const Menu = styled.ul``;

const MenuList = styled.li`
  ${tw`
    relative
    block
    w-full
    text-base
    hover:bg-gray-100
    cursor-pointer
    py-2 px-2
  `}
  ${({ active }) => active && tw`bg-gray-100`}
`;

export default function AddressSelect(props) {
  const [statesData, setStatesData] = useState(statesCode);
  const [citiesData, setCitiesData] = useState([]);

  const [addressState, setAddressState] = useState('');
  const [addressCity, setAddressCity] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  // open
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    if (addressState === '' || addressCity === '') {
      setErrorMessage('위치를 선택해 주세요');
      return false;
    } else {
      setErrorMessage('');
    }
    props.meetingLocationAdd(`${addressState} ${addressCity}`);
    setOpen(false);
  };

  const handleClickState = (e, code) => {
    console.log('code', code);
    setAddressState(code);
    setAddressCity('');
    setCitiesData(citiesCode[code]);
    setErrorMessage('');
    // console.log('statesData[code]', citiesCode);
    // console.log('statesData[code]', citiesCode[code]);
  };

  const handleClickCity = (e, code) => {
    console.log('code', code);
    setAddressCity(code);
    setErrorMessage('');
  };

  return (
    <>
      <Button idx="outline" type="button" onClick={handleClickOpen}>
        {props.label || `위치선택 선택`}
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
        transitionDuration={0}
      >
        <DialogTitle id="form-dialog-title">
          <TitleLg>위치추가</TitleLg>
        </DialogTitle>
        <DialogContent>
          <form id="form">
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <div className="mt-5 mr-4">
                <div className="grid grid-cols-2 gap-5">
                  <MenuContainer>
                    <Menu>
                      {statesData.map((v, i) => (
                        <MenuList
                          key={v.code}
                          onClick={(e) => handleClickState(e, v.code)}
                          selected={v.code === addressState}
                          // className={classes.menuItem}
                        >
                          {v.name}
                        </MenuList>
                      ))}
                    </Menu>
                  </MenuContainer>
                  <MenuContainer>
                    <Menu>
                      {citiesData &&
                        citiesData.map((v) => (
                          <MenuList
                            key={v.code}
                            onClick={(e) => handleClickCity(e, v.code)}
                            selected={v.code === addressCity}
                          >
                            {v.name}
                          </MenuList>
                        ))}
                    </Menu>
                  </MenuContainer>
                </div>
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={handleConfirm}
            >
              확인
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={handleClose}
            >
              취소
            </button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
