import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Home from './app/containers/Home';
import SignIn from './app/containers/SignIn';
import PasswordReset from './app/containers/PasswordReset/Index';
import PasswordResetForm from './app/containers/PasswordReset/Form';
import SignOut from './app/containers/SignOut';
import SignUp from './app/containers/SignUp/Index';
import SignUpEmail from './app/containers/SignUp/Email';
import SignUpForm from './app/containers/SignUp/Form';
// import MailVerification from './pages/Auth/MailVerification';

import Posts from './app/containers/Posts/Index';
import PostsCreate from './app/containers/Posts/Create';
import PostsShow from './app/containers/Posts/Show';

import Projects from './app/containers/Projects/Index';
import ProjectsShow from './app/containers/Projects/Show';
import ProjectsCreate from './app/containers/Projects/Create';

import Goods from './app/containers/Goods/Index';
import GoodsShow from './app/containers/Goods/Show';

import Partners from './app/containers/Partners/Index';
import PartnersShow from './app/containers/Partners/Show';

import MyPage from './app/containers/myPage/Index';
import UserTypeChange from './app/containers/myPage/UserTypeChange';

// import MyPartners from './app/containers/myPartners/Index';
import MyPartnersIntroduction from './app/containers/myPartners/Introduction';
import MyPartnersTechnology from './app/containers/myPartners/Technology';
import MyPartnersLicense from './app/containers/myPartners/License';
import MyPartnersEducation from './app/containers/myPartners/Education';
import MyPartnersContact from './app/containers/myPartners/Contact';
import MyPartnersGoods from './app/containers/myPartners/Goods';

// import MyClients from './app/containers/myClients/Index';
import MyClients from './app/containers/myClients/Index';

export default function router() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/sign-in" component={SignIn} />
      <Route exact path="/password-reset" component={PasswordReset} />
      <Route path="/password-reset/:code" component={PasswordResetForm} />
      <Route path="/sign-out" component={SignOut} />
      <Route exact path="/sign-up" component={SignUp} />
      <Route exact path="/sign-up/email" component={SignUpEmail} />
      <Route path="/sign-up/email/:code" component={SignUpForm} />

      <Route exact path="/posts" component={Posts} />
      <Route path="/posts/create" component={PostsCreate} />
      <Route exact path="/posts/:id" component={PostsShow} />
      {/* <Route path="/posts/:id/edit" component={PostsCreate} /> */}

      {/* <Route path="/mail_verification" component={MailVerification} /> */}
      <Route exact path="/projects" component={Projects} />
      <Route path="/projects/create" component={ProjectsCreate} />
      <Route exact path="/projects/:id" component={ProjectsShow} />

      <Route exact path="/goods" component={Goods} />
      <Route exact path="/goods/:id" component={GoodsShow} />

      <Route exact path="/partners" component={Partners} />
      <Route exact path="/partners/:id" component={PartnersShow} />

      {/* Mypage */}
      <Route exact path="/my-page" component={MyPage} />
      <Route
        exact
        path="/my-page/user-type-change"
        component={UserTypeChange}
      />

      {/* MyPartners */}
      <Route
        exact
        path="/my-partners/introduction"
        component={MyPartnersIntroduction}
      />
      <Route exact path="/my-partners">
        <Redirect to="/my-partners/introduction" />
      </Route>
      <Route path="/my-partners/technology" component={MyPartnersTechnology} />
      <Route path="/my-partners/license" component={MyPartnersLicense} />
      <Route path="/my-partners/education" component={MyPartnersEducation} />
      <Route path="/my-partners/contact" component={MyPartnersContact} />
      <Route path="/my-partners/goods" component={MyPartnersGoods} />

      {/* MyClients */}
      <Route exact path="/my-clients" component={MyClients} />

      {/* Etc */}
      <Route path="/">Not found</Route>
    </Switch>
  );
}
