const citiesCode = {
  서울특별시: [
    { code: '강북구', name: '강북구' },
    { code: '도봉구', name: '도봉구' },
    { code: '노원구', name: '노원구' },
    { code: '중랑구', name: '중랑구' },
    { code: '동대문구', name: '동대문구' },
    { code: '성북구', name: '성북구' },
    { code: '종로구', name: '종로구' },
    { code: '은평구', name: '은평구' },
    { code: '서대문구', name: '서대문구' },
    { code: '마포구', name: '마포구' },
    { code: '용산구', name: '용산구' },
    { code: '중구', name: '중구' },
    { code: '성동구', name: '성동구' },
    { code: '광진구', name: '광진구' },
    { code: '강동구', name: '강동구' },
    { code: '송파구', name: '송파구' },
    { code: '강남구', name: '강남구' },
    { code: '서초구', name: '서초구' },
    { code: '동작구', name: '동작구' },
    { code: '영등포구', name: '영등포구' },
    { code: '강서구', name: '강서구' },
    { code: '양천구', name: '양천구' },
    { code: '구로구', name: '구로구' },
    { code: '금천구', name: '금천구' },
    { code: '관악구', name: '관악구' }
  ],
  경기도: [
    { code: '김포시', name: '김포시' },
    { code: '고양시 일산서구', name: '고양시 일산서구' },
    { code: '고양시 일산동구', name: '고양시 일산동구' },
    { code: '고양시 덕양구', name: '고양시 덕양구' },
    { code: '파주시', name: '파주시' },
    { code: '연천군', name: '연천군' },
    { code: '포천시', name: '포천시' },
    { code: '동두천시', name: '동두천시' },
    { code: '양주시', name: '양주시' },
    { code: '의정부시', name: '의정부시' },
    { code: '구리시', name: '구리시' },
    { code: '남양주시', name: '남양주시' },
    { code: '가평군', name: '가평군' },
    { code: '양평군', name: '양평군' },
    { code: '여주시', name: '여주시' },
    { code: '광주시', name: '광주시' },
    { code: '하남시', name: '하남시' },
    { code: '성남시 수정구', name: '성남시 수정구' },
    { code: '성남시 중원구', name: '성남시 중원구' },
    { code: '성남시 분당구', name: '성남시 분당구' },
    { code: '과천시', name: '과천시' },
    { code: '안양시 만안구', name: '안양시 만안구' },
    { code: '안양시 동안구', name: '안양시 동안구' },
    { code: '광명시', name: '광명시' },
    { code: '부천시', name: '부천시' },
    { code: '시흥시', name: '시흥시' },
    { code: '안산시 상록구', name: '안산시 상록구' },
    { code: '안산시 단원구', name: '안산시 단원구' },
    { code: '군포시', name: '군포시' },
    { code: '의왕시', name: '의왕시' },
    { code: '수원시 장안구', name: '수원시 장안구' },
    { code: '수원시 영통구', name: '수원시 영통구' },
    { code: '수원시 팔달구', name: '수원시 팔달구' },
    { code: '수원시 권선구', name: '수원시 권선구' },
    { code: '용인시 수지구', name: '용인시 수지구' },
    { code: '용인시 기흥구', name: '용인시 기흥구' },
    { code: '용인시 처인구', name: '용인시 처인구' },
    { code: '이천시', name: '이천시' },
    { code: '안성시', name: '안성시' },
    { code: '평택시', name: '평택시' },
    { code: '오산시', name: '오산시' },
    { code: '화성시', name: '화성시' }
  ],
  인천광역시: [
    { code: '계양구', name: '계양구' },
    { code: '부평구', name: '부평구' },
    { code: '남동구', name: '남동구' },
    { code: '연수구', name: '연수구' },
    { code: '미추홀구', name: '미추홀구' },
    { code: '중구', name: '중구' },
    { code: '동구', name: '동구' },
    { code: '서구', name: '서구' },
    { code: '강화군', name: '강화군' },
    { code: '옹진군', name: '옹진군' }
  ],
  강원도: [
    { code: '철원군', name: '철원군' },
    { code: '화천군', name: '화천군' },
    { code: '춘천시', name: '춘천시' },
    { code: '양구군', name: '양구군' },
    { code: '인제군', name: '인제군' },
    { code: '고성군', name: '고성군' },
    { code: '속초시', name: '속초시' },
    { code: '양양군', name: '양양군' },
    { code: '홍천군', name: '홍천군' },
    { code: '횡성군', name: '횡성군' },
    { code: '평창군', name: '평창군' },
    { code: '강릉시', name: '강릉시' },
    { code: '동해시', name: '동해시' },
    { code: '삼척시', name: '삼척시' },
    { code: '태백시', name: '태백시' },
    { code: '정선군', name: '정선군' },
    { code: '영월군', name: '영월군' },
    { code: '원주시', name: '원주시' }
  ],
  충청북도: [
    { code: '단양군', name: '단양군' },
    { code: '제천시', name: '제천시' },
    { code: '충주시', name: '충주시' },
    { code: '음성군', name: '음성군' },
    { code: '진천군', name: '진천군' },
    { code: '증평군', name: '증평군' },
    { code: '괴산군', name: '괴산군' },
    { code: '청주시 청원구', name: '청주시 청원구' },
    { code: '청주시 흥덕구', name: '청주시 흥덕구' },
    { code: '청주시 서원구', name: '청주시 서원구' },
    { code: '청주시 상당구', name: '청주시 상당구' },
    { code: '보은군', name: '보은군' },
    { code: '옥천군', name: '옥천군' },
    { code: '영동군', name: '영동군' }
  ],
  세종특별자치시: [{ code: '세종특별자치시', name: '세종특별자치시' }],
  충청남도: [
    { code: '천안시 서북구', name: '천안시 서북구' },
    { code: '천안시 동남구', name: '천안시 동남구' },
    { code: '아산시', name: '아산시' },
    { code: '당진시', name: '당진시' },
    { code: '서산시', name: '서산시' },
    { code: '태안군', name: '태안군' },
    { code: '홍성군', name: '홍성군' },
    { code: '예산군', name: '예산군' },
    { code: '공주시', name: '공주시' },
    { code: '금산군', name: '금산군' },
    { code: '계룡시', name: '계룡시' },
    { code: '논산시', name: '논산시' },
    { code: '부여군', name: '부여군' },
    { code: '청양군', name: '청양군' },
    { code: '보령시', name: '보령시' },
    { code: '서천군', name: '서천군' }
  ],
  대전광역시: [
    { code: '유성구', name: '유성구' },
    { code: '대덕구', name: '대덕구' },
    { code: '동구', name: '동구' },
    { code: '중구', name: '중구' },
    { code: '서구', name: '서구' }
  ],
  경상북도: [
    { code: '영주시', name: '영주시' },
    { code: '봉화군', name: '봉화군' },
    { code: '울진군', name: '울진군' },
    { code: '영덕군', name: '영덕군' },
    { code: '영양군', name: '영양군' },
    { code: '안동시', name: '안동시' },
    { code: '예천군', name: '예천군' },
    { code: '문경시', name: '문경시' },
    { code: '상주시', name: '상주시' },
    { code: '의성군', name: '의성군' },
    { code: '청송군', name: '청송군' },
    { code: '포항시 북구', name: '포항시 북구' },
    { code: '포항시 남구', name: '포항시 남구' },
    { code: '경주시', name: '경주시' },
    { code: '청도군', name: '청도군' },
    { code: '경산시', name: '경산시' },
    { code: '영천시', name: '영천시' },
    { code: '군위군', name: '군위군' },
    { code: '구미시', name: '구미시' },
    { code: '김천시', name: '김천시' },
    { code: '칠곡군', name: '칠곡군' },
    { code: '성주군', name: '성주군' },
    { code: '고령군', name: '고령군' },
    { code: '울릉군', name: '울릉군' }
  ],
  대구광역시: [
    { code: '동구', name: '동구' },
    { code: '북구', name: '북구' },
    { code: '서구', name: '서구' },
    { code: '중구', name: '중구' },
    { code: '수성구', name: '수성구' },
    { code: '남구', name: '남구' },
    { code: '달서구', name: '달서구' },
    { code: '달성군', name: '달성군' }
  ],
  울산광역시: [
    { code: '동구', name: '동구' },
    { code: '북구', name: '북구' },
    { code: '중구', name: '중구' },
    { code: '남구', name: '남구' },
    { code: '울주군', name: '울주군' }
  ],
  부산광역시: [
    { code: '기장군', name: '기장군' },
    { code: '금정구', name: '금정구' },
    { code: '북구', name: '북구' },
    { code: '강서구', name: '강서구' },
    { code: '사상구', name: '사상구' },
    { code: '부산진구', name: '부산진구' },
    { code: '연제구', name: '연제구' },
    { code: '동래구', name: '동래구' },
    { code: '해운대구', name: '해운대구' },
    { code: '수영구', name: '수영구' },
    { code: '남구', name: '남구' },
    { code: '동구', name: '동구' },
    { code: '중구', name: '중구' },
    { code: '영도구', name: '영도구' },
    { code: '서구', name: '서구' },
    { code: '사하구', name: '사하구' }
  ],
  경상남도: [
    { code: '함양군', name: '함양군' },
    { code: '거창군', name: '거창군' },
    { code: '합천군', name: '합천군' },
    { code: '창녕군', name: '창녕군' },
    { code: '밀양시', name: '밀양시' },
    { code: '양산시', name: '양산시' },
    { code: '김해시', name: '김해시' },
    { code: '창원시 의창구', name: '창원시 의창구' },
    { code: '창원시 마산회원구', name: '창원시 마산회원구' },
    { code: '창원시 마산합포구', name: '창원시 마산합포구' },
    { code: '창원시 성산구', name: '창원시 성산구' },
    { code: '창원시 진해구', name: '창원시 진해구' },
    { code: '함안군', name: '함안군' },
    { code: '의령군', name: '의령군' },
    { code: '산청군', name: '산청군' },
    { code: '하동군', name: '하동군' },
    { code: '남해군', name: '남해군' },
    { code: '사천시', name: '사천시' },
    { code: '진주시', name: '진주시' },
    { code: '고성군', name: '고성군' },
    { code: '통영시', name: '통영시' },
    { code: '거제시', name: '거제시' }
  ],
  전라북도: [
    { code: '군산시', name: '군산시' },
    { code: '김제시', name: '김제시' },
    { code: '익산시', name: '익산시' },
    { code: '전주시 덕진구', name: '전주시 덕진구' },
    { code: '전주시 완산구', name: '전주시 완산구' },
    { code: '완주군', name: '완주군' },
    { code: '진안군', name: '진안군' },
    { code: '무주군', name: '무주군' },
    { code: '장수군', name: '장수군' },
    { code: '남원시', name: '남원시' },
    { code: '임실군', name: '임실군' },
    { code: '순창군', name: '순창군' },
    { code: '정읍시', name: '정읍시' },
    { code: '부안군', name: '부안군' },
    { code: '고창군', name: '고창군' }
  ],
  전라남도: [
    { code: '영광군', name: '영광군' },
    { code: '함평군', name: '함평군' },
    { code: '장성군', name: '장성군' },
    { code: '담양군', name: '담양군' },
    { code: '곡성군', name: '곡성군' },
    { code: '구례군', name: '구례군' },
    { code: '광양시', name: '광양시' },
    { code: '순천시', name: '순천시' },
    { code: '화순군', name: '화순군' },
    { code: '나주시', name: '나주시' },
    { code: '영암군', name: '영암군' },
    { code: '무안군', name: '무안군' },
    { code: '목포시', name: '목포시' },
    { code: '신안군', name: '신안군' },
    { code: '진도군', name: '진도군' },
    { code: '해남군', name: '해남군' },
    { code: '완도군', name: '완도군' },
    { code: '강진군', name: '강진군' },
    { code: '장흥군', name: '장흥군' },
    { code: '보성군', name: '보성군' },
    { code: '고흥군', name: '고흥군' },
    { code: '여수시', name: '여수시' }
  ],
  광주광역시: [
    { code: '북구', name: '북구' },
    { code: '동구', name: '동구' },
    { code: '남구', name: '남구' },
    { code: '서구', name: '서구' },
    { code: '광산구', name: '광산구' }
  ],
  제주특별자치도: [
    { code: '제주시', name: '제주시' },
    { code: '서귀포시', name: '서귀포시' }
  ]
};

export default citiesCode;
