import React, { Fragment } from 'react';
import fieldsCodeCode from '../code/fieldsCode';
import techLevelCode from '../code/techLevel';
import experienceCode from '../code/experience';
import companyTypesCode from '../code/companyTypes';
import workTypesCode from '../code/workTypes';
import userTypeCode from '../code/userTypes';

// field 관련
export const fieldName2 = (code) => {
  return (
    <>
      {fieldsCodeCode[code].upCodeInfo !== undefined &&
        fieldsCodeCode[code].upCodeInfo.map((d) => {
          return <Fragment key={d.code}>{d.name} &gt; </Fragment>;
        })}
      {fieldsCodeCode[code].name}
    </>
  );
};

export const fieldNames2 = (fields) => {
  const arr = fields?.split(',');
  return (
    arr &&
    arr.map((code, i) => {
      return (
        // <div className={i !== 0 && 'mt-2'} key={i}>
        <div className={i !== 0 && 'mt-2'} key={i}>
          {fieldName2(code)}
        </div>
      );
    })
  );
};

// code: techLevel
export const techLevelName = (code) => {
  let name;
  for (let o of techLevelCode) {
    if (o.code === code) {
      name = o.name;
      break;
    }
  }
  return <>{name}</>;
};

// code: experience
export const experienceName = (code) => {
  let name;
  for (let o of experienceCode) {
    if (o.code === code) {
      name = o.name;
      break;
    }
  }
  return <>{name}</>;
};

// company type
export const companyTypeName = (code) => {
  let name;
  for (let o of companyTypesCode) {
    if (o.code === code) {
      name = o.name;
      break;
    }
  }
  return <>{name}</>;
};

// in work view
export const inWorkName = (code) => {
  return code ? '가능' : '불가';
};

// work type
export const workTypeName = (code) => {
  let name;
  for (let o of workTypesCode) {
    if (o.code === code) {
      name = o.name;
      break;
    }
  }
  return <>{name}</>;
};

// user type
export const userTypeName = (code) => {
  let name;
  for (let o of userTypeCode) {
    if (o.code === code) {
      name = o.name;
      break;
    }
  }
  return <>{name}</>;
};
