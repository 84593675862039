import './style.css';
import topImage from '../../../assets/images/top.png';

export default function TopImage(props) {
  return (
    <div className="top-image-container w-full justify-items-center">
      <img src={topImage} className="m-auto" alt="topImage" />
    </div>
  );
}

// const TopImageContainer = styled.div`
//   min-height: 81px;
//   background-color: #e3f8f2;
//   border-top: 1px solid #e0e0e0;
//   border-bottom: 1px solid #e0e0e0;
//   ${tw`
//     w-full
//     // items-center
//     justify-items-center

//   `}
//   img {
//     margin: auto;
//     /* width: auto; */
//     /* height: 100%; */
//   }
// `;
