import styled from 'styled-components';
import tw from 'twin.macro';
import { Link } from 'react-router-dom';

const A = styled(Link)`
  ${tw`
    inline-block
    align-baseline
    font-bold
    text-sm
    text-blue-500
    hover:text-blue-800
  `}
`;
export default A;
// export default function A(props) {
//   return <Link></Link>
// };
