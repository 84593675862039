import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

export default function Loading(props) {
  return (
    <BeatLoader
      color="#1f163f"
      className="m-auto"
      loading={props.disabled}
      size={10}
    />
  );
}
