import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { goodsIndex, goodsSelector, clearState } from './goodsSlice';
import { useLocation, useHistory } from 'react-router-dom';

import { Title2 } from '../../../components/title';
import { Link } from 'react-router-dom';
import Button, { IconButton } from '../../../components/button';
import Edit from './edit';
import Delete from './delete';

import { fieldName2 } from '../../../utils/codeView';
import Loading from '../../../components/loading';
import { getSession } from '../../../utils/session';
import goLogin from '../../../utils/goLogin';
import VerifiedBadge from '../Index/verifiedBadge';
import { PlusIcon } from '../../../components/icon';
import VerifyBox from '../Index/verifyBox';
import PartnersMenu from '../Index/partnersMenu';
import priceView from '../../Goods/Index/priceView';

const ContentContiner = styled.div`
  ${tw` h-full items-center border border-gray-500 rounded-md`};
`;

const Table = styled.table`
  border-spacing: 0;
  ${tw` w-full `}
`;

const Th = styled.th`
  ${tw` py-2 px-2 text-left text-base font-medium border-t border-b bg-gray-50 border-gray-400 `}
  ${({ t1 }) => t1 && tw`w-3/12 border-l-0`}
  ${({ t2 }) => t2 && tw`w-4/12`}
  ${({ t3 }) => t3 && tw`w-3/12`}
  ${({ t4 }) => t4 && tw`w-2/12 border-r-0`}
`;

const Td = styled.td`
  ${tw` py-2 px-2 text-base font-normal border-b border-gray-300 `}
  ${({ t1 }) => t1 && tw`w-3/12 border-l-0`}
  ${({ t2 }) => t2 && tw`w-4/12`}
  ${({ t3 }) => t3 && tw`w-3/12`}
  ${({ t4 }) => t4 && tw`w-2/12 border-r-0 text-right`}
`;

export default function Content(props) {
  // auth
  const history = useHistory();

  // redux
  const dispatch = useDispatch();
  const { indexStatus, indexResult, indexData } = useSelector(goodsSelector);

  // Edit Handle
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [goodsId, setGoodsId] = useState();
  const handleEditOpen = (e, id) => {
    setGoodsId(id);
    setEditOpen(true);
  };
  const editClose = () => {
    setEditOpen(false);
  };
  const handleDeleteOpen = (e, id) => {
    setGoodsId(id);
    setDeleteOpen(true);
  };
  const deleteClose = () => {
    setDeleteOpen(false);
  };

  // refresh
  const [rowsRefreshKey, setRowsRefreshKey] = React.useState(0);
  const rowsRefresh = () => {
    const key = rowsRefreshKey + 1;
    setRowsRefreshKey(key);
  };

  // page data
  const [pageLoading, setPageLoading] = useState(true);
  const [rowsLoading, setRowsLoading] = useState(true);

  useEffect(() => {
    dispatch(goodsIndex());
    return () => {
      setRowsLoading(true);
    };
  }, [rowsRefreshKey]);

  useEffect(() => {
    if (indexStatus === 'completed') {
      if (!indexResult.success) {
        alert(indexResult.message);
        if (indexResult.error === 'auth') {
          goLogin(history);
        }
      } else {
        setPageLoading(false);
        setRowsLoading(false);
      }
    }
    return () => {
      //
    };
  }, [indexStatus]);

  console.log('process.env.REACT_APP_IMG_URL', process.env.REACT_APP_IMG_URL);

  return pageLoading ? (
    <ContentContiner>
      <Loading />
    </ContentContiner>
  ) : (
    <ContentContiner>
      <div className="flex-grow text-xl px-4 my-6">파트너스 상품관리</div>
      <div className="px-0 my-5">
        <div className="text-sm my-5 mx-4">상품을 등록해 주세요</div>
        <Table>
          <thead>
            <tr>
              <Th t1>사진</Th>
              <Th t2>상품명</Th>
              <Th t3>가격</Th>
              <Th t4></Th>
            </tr>
          </thead>
          <tbody>
            {rowsLoading ? (
              <Loading />
            ) : (
              indexData?.rows &&
              indexData?.rows.map((row, i) => {
                return (
                  <tr key={i}>
                    <Td t1>
                      <div className="w-20 h-20 border border-gray-200 rounded-xl flex">
                        <img
                          className="m-auto rounded-xl"
                          src={`${process.env.REACT_APP_IMG_URL}${row.image}`}
                          alt="partners"
                        />
                      </div>
                    </Td>
                    <Td t2>{row.name}</Td>
                    <Td t3>{priceView(row)}</Td>
                    <Td t4>
                      <Button
                        idx="text"
                        type="button"
                        color="blue"
                        className="mx-1"
                        onClick={(e) => handleEditOpen(e, row.id)}
                      >
                        수정
                      </Button>
                      <Button
                        idx="text"
                        color="red"
                        type="button"
                        className="mx-1"
                        onClick={(e) => handleDeleteOpen(e, row.id)}
                      >
                        삭제
                      </Button>
                    </Td>
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
      </div>
      <div className="flex flex-row-reverse w-full bg-gray-100 mt-10 p-3 items-end rounded-b">
        <Button type="button" onClick={handleEditOpen}>
          상품추가
        </Button>
      </div>

      <Edit
        open={editOpen}
        editClose={editClose}
        goodsId={goodsId}
        refresh={rowsRefresh}
      />
      <Delete
        open={deleteOpen}
        deleteClose={deleteClose}
        goodsId={goodsId}
        refresh={rowsRefresh}
      />
    </ContentContiner>
  );
}
