import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Title3, TitleLg } from '../../../components/title';
import { Link } from 'react-router-dom';
import VerifiedIcon from './verifiedIcon';
import HomeButton from './homeButton';

const MenuContainer = styled.div`
  ${tw`
    w-60
    items-center
    relative
    float-left
    rounded
    border
    border-gray-300
  `};
`;

const MenuTitleContainer = styled.div`
  ${tw`
    flex
    w-full
    py-6 px-6
    justify-between
  `}
`;

const MenuListContainer = styled.div``;

const MenuList = styled(Link)`
  ${tw`
    relative
    block
    w-full
    border-t
    py-3 px-8
    text-base
    hover:bg-gray-100
  `}
  ${({ active }) => active && tw`bg-gray-100`}
`;

export default function LeftMenu(props) {
  const { verified, active } = props;
  return (
    <MenuContainer>
      <MenuTitleContainer>
        <TitleLg>파트너스 정보</TitleLg>
        <Link to="/my-partners" className="pt-1.5">
          <HomeButton />
        </Link>
      </MenuTitleContainer>
      <MenuListContainer>
        <MenuList to="/my-partners/email" active={active === 'email'}>
          이메일
          <VerifiedIcon verified={verified && verified.email} />
        </MenuList>
        <MenuList
          to="/my-partners/phone-number"
          active={active === 'phoneNumber'}
        >
          휴대전화
          <VerifiedIcon verified={verified && verified.phoneNumber} />
        </MenuList>
        <MenuList
          to="/my-partners/information"
          active={active === 'information'}
        >
          기본정보
          <VerifiedIcon verified={verified && verified.information} />
        </MenuList>
        <MenuList to="/my-partners/technology" active={active === 'technology'}>
          기술정보
          <VerifiedIcon verified={verified && verified.technology} />
        </MenuList>
        <MenuList to="/my-partners/career" active={active === 'career'}>
          경력정보
          <VerifiedIcon verified={verified && verified.career} />
        </MenuList>
        <MenuList to="/my-partners/portfolio" active={active === 'portfolio'}>
          포트폴리오
          <VerifiedIcon verified={verified && verified.portfolio} />
        </MenuList>
        <MenuList to="/my-partners/identity" active={active === 'identity'}>
          신원 인증
          <VerifiedIcon verified={verified && verified.identity} />
        </MenuList>
      </MenuListContainer>
    </MenuContainer>
  );
}
