import styled from 'styled-components';
import tw from 'twin.macro';

export const Title2 = styled.h2`
  ${tw`
    text-2xl 
    font-semibold
    // pb-10
    text-gray-600
  `}
`;

export const Title3 = styled.h2`
  ${tw`
    text-lg
    font-semibold
    // pb-10
    text-gray-600
  `}
`;

export const TitleSm = styled.h2`
  ${tw`
    text-sm 
    font-semibold
    text-gray-600
  `}
`;

export const TitleBase = styled.h2`
  ${tw`
    text-base 
    font-semibold
    text-gray-800
  `}
`;

export const TitleLg = styled.h2`
  ${tw`
    text-lg 
    font-medium
    text-gray-600
  `}
`;

export const TitleXl = styled.h2`
  ${tw`
    text-xl 
    font-medium
    text-gray-600
  `}
`;

export const Title2Xl = styled.h2`
  ${tw`
    text-2xl 
    font-medium
    text-gray-600
  `}
`;

export const TextSm = styled.h2`
  ${tw`
    text-sm 
    // font-semibold
    // text-gray-600
  `}
`;
