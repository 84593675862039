import React, { Fragment, useEffect, useState } from 'react';
// import styled from 'styled-components';
// import tw from 'twin.macro';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  emailEdit,
  emailUpdate,
  emailSelector,
  clearState
} from './emailSlice';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

import Label from '../../../components/label';
import Input, { ErrorMessage } from '../../../components/input';
import Button, { IconButton } from '../../../components/button';

import { toast } from 'react-toastify';
import isEmail from 'validator/lib/isEmail';
import goLogin from '../../../utils/goLogin';
import { useHistory } from 'react-router';

export default function Edit(props) {
  const { open, editClose, refresh } = props;
  // auth
  const history = useHistory();

  // submit button
  const [submitEnable, setSubmitEnable] = useState(false);

  // redux
  const dispatch = useDispatch();
  const {
    editStatus,
    editResult,
    editData,
    updateStatus,
    updateResult,
    updateData
  } = useSelector(emailSelector);

  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      editClose();
    }
  };

  // react-form-hook
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    getValues,
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    console.log('onSubmit data', data);
    dispatch(emailUpdate({ reqData: data }));
  };

  // update return
  useEffect(() => {
    console.log('updateStatus 1', updateStatus);
    if (updateStatus === 'completed') {
      if (!updateResult.success) {
        alert(updateResult.message);
        if (updateResult.error === 'auth') {
          goLogin(history);
        }
      } else {
        toast.success('완료 되었습니다');
        refresh();
        editClose();
      }
    }
    return () => {};
  }, [updateStatus]);

  const [pageLoading, setPageLoading] = useState(true);
  // page data
  useEffect(() => {
    console.log('시작 open', open);
    // fieldSelected();

    if (open) {
      setSubmitEnable(false);
      reset();
      setPageLoading(true);
      dispatch(emailEdit());
    }
    return () => {
      // setPageLoading(true);
      reset();
    };
  }, [open]);

  // page data status
  useEffect(() => {
    console.log('editStatus 1', editStatus);

    if (editStatus === 'completed') {
      if (editData) {
        console.log('결과 있슴.', editData.companyName);
        // reset({});
      }
      setPageLoading(false);
    }
    return () => {};
  }, [editStatus]);

  // const cancelButtonRef = useRef(null);
  return (
    !pageLoading && (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
        transitionDuration={0}
        className=""
      >
        <DialogTitle id="form-dialog-title">
          <div className="font-medium text-2xl pt-3 pl-3">이메일 변경</div>
        </DialogTitle>
        <DialogContent>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left">
            <form id="form" onSubmit={handleSubmit(onSubmit)}>
              {/* <input type="text" {...register('') }/> */}
              <div className="mt-5">
                <Label htmlFor="email">새로운 이메일</Label>
                <div className="relative">
                  <Controller
                    render={({ field, fieldState }) => (
                      <Input
                        id="email"
                        value={field.value}
                        onChange={(e) => {
                          // setValue('email', e.target.value);
                          field.onChange(e);
                        }}
                        inputRef={field.ref}
                        placeholder="이메일을 입력해 주세요"
                        error={fieldState.error}
                        errorMessage={errors.email && errors.email.message}
                      />
                    )}
                    name="email"
                    control={control}
                    rules={{
                      validate: (value) => {
                        // console.log('value', value);
                        if (!value) return '이메일을 입력해 주세요';
                        if (!isEmail(value))
                          return '이메일 형식이 잘못되었습니다';
                        return true;
                      }
                    }}
                  />
                </div>
              </div>
              <div className="mt-3">
                새로운 이메일로 "이메일 변경 완료" 링크를 발송합니다.
                <br />
              </div>
            </form>
          </div>
        </DialogContent>
        <DialogActions className="flex w-full p-0 bg-gray-100 mt-5">
          <div className="w-full m-0 bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse flow-root">
            <Button
              type="submit"
              form="form"
              // disabled={!submitEnable}
              // className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm"
              className="sm:mx-1"
            >
              확인
            </Button>
            <Button
              type="button"
              idx="outline"
              onClick={handleClose}
              className="sm:mx-1"
              // className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              취소
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    )
  );
}
