import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';
import { setSession, getToken } from '../../../utils/session';

// index
export const postsIndex = createAsyncThunk(
  '/posts/index',
  ({ page }, thunkAPI) => {
    return axios
      .get('/posts', {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        },
        params: {
          page: page
        }
      })
      .then((response) => {
        console.log('response.data', response.data);
        console.log('response.data.user', response.data.user);
        setSession(response.data.user);
        // if (!response.data.success && response.data.error === 'auth') {
        //   alert(response.data.message);
        // }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

// show
export const postsShow = createAsyncThunk(
  '/posts/show',
  ({ postId }, thunkAPI) => {
    const url = `/posts/${postId}`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        console.log('response.data', response.data);
        setSession(response.data.user);
        if (!response.data.success && response.data.error === 'auth') {
          alert(response.data.message);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

// create
export const postsCreate = createAsyncThunk('/posts/create', (_, thunkAPI) => {
  const url = `/posts/create`;
  return axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
      console.log('response.data', response.data);
      setSession(response.data.user);
      if (!response.data.success && response.data.error === 'auth') {
        alert(response.data.message);
      }
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return thunkAPI.rejectWithValue(error);
    });
});

// const url = process.env.REACT_APP_BASE_URL + path;
// const postData = new FormData();
// postData.append('subject', formData.subject);
// postData.append('amount', formData.amount);
// postData.append('period', formData.period);
// postData.append('deadlineAt', formData.deadline_at.format('YYYY-MM-DD'));
// postData.append('startAt', formData.start_at.format('YYYY-MM-DD'));
// postData.append('workType', formData.work_type);
// postData.append('workContents', formData.work_contents);
// postData.append('workEnvironment', formData.work_environment);
// postData.append('companyTypes', companyTypes.join(','));
// postData.append('meetingLocations', meetingLocations.join(','));
// postData.append('fields', fields.join(','));
// postData.append('meetingOnline', formData.meeting_online ? '1' : '0');
//moment(data.deadlineAt).format('YYYY-MM-DD')

// store
export const postsStore = createAsyncThunk(
  '/posts/store',
  ({ reqData }, thunkAPI) => {
    console.log('xxx', 'xxx');
    const url = `/posts`;
    const formData = {
      subject: reqData.subject,
      category: reqData.category,
      content: reqData.content
    };

    console.log('formData()', formData);
    console.log('getToken()', getToken());
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        // 'content-type': 'multipart/form-data'
        'Content-Type': 'application/json'
      }
    };
    return axios
      .post(url, formData, config)
      .then((response) => {
        console.log('response.data', response.data);
        setSession(response.data.user);
        if (!response.data.success && response.data.error === 'auth') {
          // 시간만료등....
          alert(response.data.message);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

// edit
export const postsEdit = createAsyncThunk(
  '/posts/edit',
  ({ postId }, thunkAPI) => {
    const url = `/posts/${postId}/edit`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        console.log('response.data', response.data);
        setSession(response.data.user);
        if (!response.data.success && response.data.error === 'auth') {
          alert(response.data.message);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

// update
export const postsUpdate = createAsyncThunk(
  '/posts/update',
  ({ postId, reqData }, thunkAPI) => {
    const url = `/posts/${postId}`;
    const formData = {
      companyName: reqData.companyName,
      department: reqData.department,
      jobTitle: reqData.jobTitle,
      hireYear: reqData.hireYear,
      hireMonth: reqData.hireMonth,
      leaveYear: reqData.leaveYear,
      leaveMonth: reqData.leaveMonth,
      work: reqData.work
    };

    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        // 'content-type': 'multipart/form-data'
        'Content-Type': 'application/json'
      }
    };
    return axios
      .post(url, formData, config)
      .then((response) => {
        console.log('response.data', response.data);
        setSession(response.data.user);
        if (!response.data.success && response.data.error === 'auth') {
          // 시간만료등....
          alert(response.data.message);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const postsSlice = createSlice({
  name: 'posts',
  initialState: {
    indexStatus: '',
    indexMessage: '',
    indexData: {},
    // indexPage: 0,
    showStatus: '',
    showMessage: '',
    showData: {},
    createStatus: '',
    createMessage: '',
    createData: {},
    storeStatus: '',
    storeMessage: '',
    storeData: {},
    editStatus: '',
    editMessage: '',
    editData: {},
    updateStatus: '',
    updateMessage: '',
    updateData: {}
  },
  reducers: {
    clearState: (state) => {
      state.indexStatus = '';
      state.indexMessage = '';
      state.indexData = {};
      // state.indexPage = 0;
      state.showStatus = '';
      state.showMessage = '';
      state.showData = {};
      state.createStatus = '';
      state.createMessage = '';
      state.createData = {};
      state.storeStatus = '';
      state.storeMessage = '';
      state.storeData = {};
      state.editStatus = '';
      state.editMessage = '';
      state.editData = {};
      state.updateStatus = '';
      state.updateMessage = '';
      state.updateData = {};
      return state;
    }
    // setIndexPage: (state, { payload }) => {
    //   state.indexPage = payload;
    // }
  },
  extraReducers: {
    [postsIndex.pending]: (state) => {
      state.indexStatus = 'loading';
    },
    [postsIndex.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.indexStatus = 'completed';
      state.indexMessage = payload?.message;
      state.indexData = payload?.data?.pageData;
    },
    [postsIndex.rejected]: (state, { payload }) => {
      state.indexStatus = 'error';
    },
    [postsShow.pending]: (state) => {
      state.showStatus = 'loading';
    },
    [postsShow.fulfilled]: (state, { payload }) => {
      console.log('payload edit', payload);
      state.showStatus = 'completed';
      state.showMessage = payload?.message;
      state.showData = payload?.data?.pageData;
      if (payload && payload.data && payload.data.pageData) {
        state.showData.diffDeadlineDays = moment(
          payload.data.pageData.deadlineAt
        ).diff(moment(), 'days');
        state.showData.diffCreatedMinute = moment().diff(
          moment(payload.data.pageData.createdAt),
          'minutes'
        );
      }
    },
    [postsShow.rejected]: (state, { payload }) => {
      state.showStatus = 'error';
    },
    [postsCreate.pending]: (state) => {
      state.createStatus = 'loading';
    },
    [postsCreate.fulfilled]: (state, { payload }) => {
      console.log('payload edit', payload);
      state.createStatus = 'completed';
      state.createMessage = payload?.message;
      state.createData = payload?.data?.pageData;
    },
    [postsCreate.rejected]: (state, { payload }) => {
      state.createStatus = 'error';
    },
    [postsStore.pending]: (state) => {
      state.storeStatus = 'loading';
    },
    [postsStore.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.storeStatus = 'completed';
      state.storeMessage = payload.message;
    },
    [postsStore.rejected]: (state, { payload }) => {
      state.storeStatus = 'error';
    },

    [postsEdit.pending]: (state) => {
      state.editStatus = 'loading';
    },
    [postsEdit.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.editStatus = 'completed';
      state.editMessage = payload.message;
      state.editData = payload?.data?.pageData;
    },
    [postsEdit.rejected]: (state, { payload }) => {
      state.editStatus = 'error';
    },
    [postsUpdate.pending]: (state) => {
      state.updateStatus = 'loading';
    },
    [postsUpdate.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.updateStatus = 'completed';
      state.updateMessage = payload.message;
    },
    [postsUpdate.rejected]: (state, { payload }) => {
      state.updateStatus = 'error';
    }
  }
});

export const { clearState } = postsSlice.actions;
export const postsSelector = (state) => state.posts;
export default postsSlice.reducer;
