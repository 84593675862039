import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  phoneNumberEdit,
  phoneNumberUpdate,
  phoneNumberCode,
  phoneNumberSelector,
  clearState
} from './phoneNumberSlice';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

import Label from '../../../components/label';
import Input, { ErrorMessage } from '../../../components/input';
import Button, { IconButton } from '../../../components/button';

import { toast } from 'react-toastify';
import Timer from '../../../components/timer';
import { useHistory } from 'react-router';
import goLogin from '../../../utils/goLogin';

export default function Edit(props) {
  const { open, editClose, mode, refresh } = props;
  // auth
  const history = useHistory();

  console.log('mode', mode);

  // submit input & button
  const [inputPhoneNumberEnable, setInputPhoneNumberEnable] = useState(false);
  const [submitCodeEnable, setSubmitCodeEnable] = useState(false);
  const [inputCodeEnable, setInputCodeEnable] = useState(false);
  const [submitEnable, setSubmitEnable] = useState(false);

  // timer
  const [timerSeconds, setTimerSeconds] = useState(0);
  const startTimer = () => {
    setTimerSeconds(120);
  };
  const expiredTimer = () => {
    // reset();
    if (mode === 'register') {
      setInputPhoneNumberEnable(true);
      setSubmitCodeEnable(true);
      setInputCodeEnable(false);
      setSubmitEnable(false);
    } else if (mode === 'verify') {
      setInputPhoneNumberEnable(false);
      setSubmitCodeEnable(true);
      setInputCodeEnable(false);
      setSubmitEnable(false);
    }
    // setSubmitCodeEnable(true);
    // setSubmitEnable(false);
    setTimerSeconds(0);
  };

  // redux
  const dispatch = useDispatch();
  const {
    editStatus,
    editResult,
    editData,
    updateStatus,
    updateResult,
    updateData,
    codeStatus,
    codeResult,
    codeData
  } = useSelector(phoneNumberSelector);

  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      // setOpen(false);
      editClose();
    }
  };

  // react-form-hook code
  const {
    register: registerCode,
    handleSubmit: handleSubmitCode,
    setValue: setValueCode,
    reset: resetCode,
    control: controlCode,
    getValues: getValuesCode,
    formState: { errors: errorsCode }
  } = useForm();

  const onSubmitCode = (data) => {
    console.log('data code', data);
    dispatch(phoneNumberCode({ reqData: data }));
  };

  // code return
  useEffect(() => {
    console.log('codeStatus 1', codeStatus);
    if (codeStatus === 'completed') {
      dispatch(clearState());
      toast('인증번호를 발송했습니다.');
      // setSubmitCodeEnable(false);
      // setSubmitEnable(true);
      if (mode === 'register') {
        setInputPhoneNumberEnable(false);
        setSubmitCodeEnable(false);
        setInputCodeEnable(true);
        setSubmitEnable(true);
      } else if (mode === 'verify') {
        setInputPhoneNumberEnable(false);
        setSubmitCodeEnable(false);
        setInputCodeEnable(true);
        setSubmitEnable(true);
      }

      startTimer();
      // toast.success('완료 되었습니다');
      // refresh();
      // editClose();
    }
    return () => {};
  }, [codeStatus]);

  // react-form-hook
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    getValues,
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    console.log('onSubmit data', data);
    dispatch(phoneNumberUpdate({ reqData: data }));
  };

  // update return
  useEffect(() => {
    // console.log('updateStatus 1', updateStatus);
    if (updateStatus === 'completed') {
      if (!updateResult.success) {
        alert(updateResult.message);
        if (updateResult.error === 'auth') {
          goLogin(history);
        }
      } else {
        toast.success('완료 되었습니다');
        refresh();
        editClose();
      }
    }
    return () => {};
  }, [updateStatus]);

  const [pageLoading, setPageLoading] = useState(true);
  // page data
  useEffect(() => {
    console.log('시작 open', open);
    // fieldSelected();

    if (open) {
      if (mode === 'register') {
        setInputPhoneNumberEnable(true);
        setSubmitCodeEnable(true);
        setInputCodeEnable(false);
        setSubmitEnable(false);
      } else if (mode === 'verify') {
        setInputPhoneNumberEnable(false);
        setSubmitCodeEnable(true);
        setInputCodeEnable(false);
        setSubmitEnable(false);
      }
      console.log('open mode', mode);

      expiredTimer();
      reset();
      resetCode();
      console.log('effect ++++ ');
      setPageLoading(true);
      // reset();
      dispatch(phoneNumberEdit());
    }
    return () => {
      // setPageLoading(true);
      reset();
    };
  }, [open]);

  // console.log('렌더 open', open);

  // page data status
  useEffect(() => {
    // console.log('editStatus 1', editStatus);

    if (editStatus === 'completed') {
      if (editData && editData.row) {
        console.log('결과 있슴.', editData.row.companyName);
        // reset({});
      }
      setValueCode('mode', mode);
      setValue('mode', mode);
      setValueCode('phoneNumber', editData.row.phoneNumber);
      setValue('phoneNumber', editData.row.phoneNumber);
      setPageLoading(false);
    }
    return () => {};
  }, [editStatus]);

  // const cancelButtonRef = useRef(null);
  return (
    <>
      {!pageLoading && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="sm"
          transitionDuration={0}
          className=""
        >
          <DialogTitle id="form-dialog-title">
            <div className="font-medium text-2xl pt-3 pl-3">휴대전화 변경</div>
          </DialogTitle>
          <DialogContent>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left">
              <form id="formCode" onSubmit={handleSubmitCode(onSubmitCode)}>
                <input type="text" {...registerCode('mode')} />
                <div className="mt-5">
                  <Label htmlFor="phoneNumber">휴대전화</Label>
                  <div className="relative">
                    <Controller
                      render={({ field, fieldState }) => (
                        <Input
                          id="phoneNumber"
                          value={field.value}
                          onChange={(e) => {
                            setValue('phoneNumber', e.target.value);
                            field.onChange(e);
                          }}
                          disabled={!inputPhoneNumberEnable}
                          inputRef={field.ref}
                          placeholder="휴대전화를 입력해 주세요"
                          error={fieldState.error}
                          errorMessage={
                            errorsCode.phoneNumber &&
                            errorsCode.phoneNumber.message
                          }
                        />
                      )}
                      name="phoneNumber"
                      control={controlCode}
                      rules={{
                        validate: (value) => {
                          // console.log('value', value);
                          if (!value) return '휴대전화를 입력해 주세요';
                          // if (!isEmail(value))
                          //   return '이메일 형식이 잘못되었습니다';
                          return true;
                        }
                      }}
                    />
                    <button
                      className="absolute right-4 top-2.5"
                      variant="contained"
                      type="submit"
                      // onClick={handleCodeSubmit}
                      // disabled
                      disabled={!submitCodeEnable}
                    >
                      인증번호 받기
                    </button>
                  </div>
                </div>
              </form>
              <div className="mt-5">
                <form id="form" onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <input type="text" {...register('mode')} />
                    <input
                      type="text"
                      value={editData.row.phoneNumber}
                      {...register('phoneNumber')}
                    />
                  </div>
                  <Label htmlFor="confirm_number">인증번호</Label>
                  <div className="relative">
                    <Controller
                      render={({ field, fieldState }) => (
                        <Input
                          id="confirm_number"
                          value={field.value}
                          onChange={field.onChange}
                          inputRef={field.ref}
                          placeholder="인증번호를 입력해 주세요"
                          disabled={!inputCodeEnable}
                          error={fieldState.error}
                          errorMessage={
                            errors.confirmNumber && errors.confirmNumber.message
                          }
                        />
                      )}
                      name="confirmNumber"
                      control={control}
                      rules={{ required: '인증번호를 입력해 주세요' }}
                    />
                    <div className="absolute right-2 top-2">
                      {timerSeconds > 0 && (
                        <Timer seconds={timerSeconds} callback={expiredTimer} />
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </DialogContent>
          <DialogActions className="flex w-full p-0 bg-gray-100 mt-5">
            <div className="w-full m-0 bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse flow-root">
              <Button
                type="submit"
                form="form"
                disabled={!submitEnable}
                className="sm:mx-1"
              >
                확인
              </Button>
              <IconButton
                type="button"
                onClick={handleClose}
                className="sm:mx-1"
              >
                취소
              </IconButton>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
