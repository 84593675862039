import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { useHistory } from 'react-router-dom';
import { getSession } from '../../../utils/session';
import { useSelector, useDispatch } from 'react-redux';
import { myPageIndex, myPageSelector, clearState } from './myPageSlice';

import Navbar from '../../../components/navbar';
// import LeftMenu from '../Index/leftMenu';
// import topImage from '../../../../assets/images/top.png';
import goLogin from '../../../utils/goLogin';
import Content from './content';
import Bottom from '../../../components/bottom';
import TopImage from '../../../components/topImage';

const PageContiner = styled.div`
  ${tw`flex flex-col w-full h-full items-center overflow-x-hidden`}
`;

const ContentsContiner = styled.div`
  ${tw`relative w-full max-w-screen-lg items-start py-6 px-0.5`}
`;

export default function MyPage() {
  // auth
  const history = useHistory();
  useEffect(() => {
    dispatch(clearState());
    if (!getSession('login')) {
      goLogin(history);
    } else if (getSession('isExpires')) {
      alert('세션이 만료 되었습니다');
      goLogin(history);
    } else {
      setPageLoading(false);
    }
  }, []);

  // redux
  const dispatch = useDispatch();
  // const { indexStatus, indexMessage, indexData } =
  //   useSelector(myPageSelector);

  const [pageLoading, setPageLoading] = useState(true);

  // useEffect(() => {
  //   if (indexStatus === 'completed') {
  //     console.log('menu user', indexData.verified);
  //   }
  // }, [indexStatus]);

  return pageLoading ? (
    <></>
  ) : (
    <PageContiner>
      <Navbar />
      <TopImage />
      <ContentsContiner>
        <Content />
      </ContentsContiner>
      <Bottom />
    </PageContiner>
  );
}
