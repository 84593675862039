import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  userTypeChangeIndex,
  userTypeChangeUpdate,
  userTypeChangeSelector,
  clearState
} from './userTypeChangeSlice';
import { useLocation, useHistory } from 'react-router-dom';

import Button from '../../../components/button';

import Loading from '../../../components/loading';
import goLogin from '../../../utils/goLogin';
import { userTypeName } from '../../../utils/codeView';

export default function Content(props) {
  // auth
  const history = useHistory();

  // redux
  const dispatch = useDispatch();
  const {
    indexStatus,
    indexResult,
    indexData,
    updateStatus,
    updateResult,
    updateData
  } = useSelector(userTypeChangeSelector);

  // refresh
  const [rowsRefreshKey, setRowsRefreshKey] = React.useState(0);
  const rowsRefresh = () => {
    const key = rowsRefreshKey + 1;
    setRowsRefreshKey(key);
  };

  // form
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    dispatch(userTypeChangeUpdate({ reqData: data }));
  };

  // page data status
  useEffect(() => {
    if (updateStatus === 'completed') {
      // console.log('submitResult 111', submitResult);
      if (!updateResult.success) {
        alert(updateResult.message);
        if (updateResult.error === 'auth') {
          goLogin(history);
        }
      } else {
        toast.success('완료 되었습니다');
        rowsRefresh();
      }
    }
    return () => {};
  }, [updateStatus]);

  // page data
  const [pageLoading, setPageLoading] = useState(true);
  const [rowsLoading, setRowsLoading] = useState(true);

  useEffect(() => {
    dispatch(userTypeChangeIndex());
    return () => {
      setRowsLoading(true);
    };
  }, [rowsRefreshKey]);

  useEffect(() => {
    if (indexStatus === 'completed') {
      if (!indexResult.success) {
        alert(indexResult.message);
        if (indexResult.error === 'auth') {
          goLogin(history);
        }
      } else {
        setValue(
          'userType',
          indexData.userType === 'PARTNER' ? 'CLIENT' : 'PARTNER'
        );
        setPageLoading(false);
        setRowsLoading(false);
      }
    }
    return () => {
      //
    };
  }, [indexStatus]);

  return pageLoading ? (
    <div>
      <Loading />
    </div>
  ) : (
    <div>
      {rowsLoading ? (
        <Loading />
      ) : (
        indexData && (
          <>
            <div className="flex w-full h-44 mt-10 border border-gray-500 rounded-md">
              <div className="w-2/3 py-8 px-10 border-r border-gray-500">
                <div className="font-medium text-xl">회원전환</div>
                <div className="mt-5">
                  회원님은{' '}
                  <span className="font-semibold">
                    {userTypeName(indexData.userType)}
                  </span>
                  로 로그인 되어 있습니다.
                  <br />
                  <span className="font-semibold">
                    {userTypeName(
                      indexData.userType === 'PARTNER' ? 'CLIENT' : 'PARTNER'
                    )}
                  </span>
                  로 전환 하시겠습니까?
                </div>
              </div>
              <div className="flex flex-grow justify-center items-center rounded-r-md bg-gray-200">
                <div>
                  <form id="form" onSubmit={handleSubmit(onSubmit)}>
                    <input type="hidden" {...register('userType')} />
                    <div className="text-center">
                      <Button type="submit">
                        {userTypeName(
                          indexData.userType === 'PARTNER'
                            ? 'CLIENT'
                            : 'PARTNER'
                        )}
                        로 변환
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </>
        )
      )}
    </div>
  );
}
