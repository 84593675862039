import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
require('dotenv').config();

const contextClass = {
  success: 'bg-green-600',
  error: 'bg-red-600',
  info: 'bg-gray-600',
  warning: 'bg-orange-400',
  default: 'bg-indigo-600',
  dark: 'bg-white-600 font-gray-300'
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          toastClassName={({ type }) =>
            contextClass[type || 'default'] +
            ` relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer`
          }
          toastStyle={{
            backgroundImage: 'url("./assets/images/errorToast.svg")'
          }}
          bodyClassName={() => 'text-base font-white font-med block p-3'}
        />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
//.signup_area .kakao em { position: absolute; width: 25px; height: 23px; background:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24'%3e%3cdefs%3e%3cpath id='a' d='M0 0h24v23.1H0z'/%3e%3c/defs%3e%3cg fill='none' fill-rule='evenodd'%3e%3cmask id='b' fill='%23fff'%3e%3cuse xlink:href='%23a'/%3e%3c/mask%3e%3cpath fill='%23000' d='M12 2.9c-5.5 0-10 3.4-10 7.6 0 2.6 1.7 4.9 4.4 6.2l-1.1 4c-.1.4.3.6.6.4l4.8-3.1H12c5.5 0 10-3.3 10-7.5s-4.5-7.6-10-7.6' mask='url(%23b)'/%3e%3c/g%3e%3c/svg%3e") 50% 50% no-repeat; background-size:100% 100%;}
serviceWorker.unregister();
