import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

const BaseSelect = styled.select`
  ${tw`
    // shadow 
    appearance-none 
    border 
    rounded 
    w-full 
    py-3 px-4 
    text-gray-700 
    leading-tight 
    focus:outline-none 
    pr-3
    // focus:shadow-outline
  `}
  ${({ error }) => (error ? tw`border-red-500` : tw`border-gray-400`)}
`;

const ErrorMessage = styled.p`
  ${tw`
    text-red-500 
    text-xs 
    italic
  `}
`;

export default function Select(props) {
  return (
    <>
      <div className="relative">
        <BaseSelect {...props}>{props.children}</BaseSelect>
        <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
          <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
            <path
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
              fill-rule="evenodd"
            ></path>
          </svg>
        </div>
      </div>
      {props.errorMessage && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
    </>
  );
}
