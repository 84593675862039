import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { setSession, getToken } from '../../../utils/session';

// index
export const myPageIndex = createAsyncThunk(
  '/my-page/index',
  (reqData, thunkAPI) => {
    return axios
      .get('/my-page', {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        console.log('response.data index', response.data);
        console.log('response.data.data.user', response.data.data?.user);
        if (response.data.success && response.data.error !== 'auth') {
          setSession(response.data.data?.user);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const myPageSlice = createSlice({
  name: 'myPage',
  initialState: {
    indexStatus: '',
    indexResult: {},
    indexData: {}
  },
  reducers: {
    clearState: (state) => {
      state.indexStatus = '';
      state.indexResult = {};
      state.indexData = {};
      return state;
    }
  },
  extraReducers: {
    [myPageIndex.pending]: (state) => {
      state.indexStatus = 'loading';
    },
    [myPageIndex.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.indexStatus = 'completed';
      state.indexResult = {
        success: payload?.success,
        message: payload?.message,
        error: payload?.error
      };
      state.indexData = payload?.data?.pageData;
    },
    [myPageIndex.rejected]: (state, { payload }) => {
      state.indexStatus = 'error';
    }
  }
});

export const { clearState } = myPageSlice.actions;
export const myPageSelector = (state) => state.myPage;
export default myPageSlice.reducer;
