const code = [
  {
    code: 'A01000000',
    upCode: null,
    name: '의료기기',
    sub: [
      { code: 'A01006000', upCode: 'A01000000', name: '수리(repair)' },
      { code: 'A01005000', upCode: 'A01000000', name: '영업' },
      { code: 'A01004000', upCode: 'A01000000', name: '기획 / 마케팅' },
      { code: 'A01003000', upCode: 'A01000000', name: '수탁제조(OEM)' },
      {
        code: 'A01002000',
        upCode: 'A01000000',
        name: '개발',
        sub: [
          {
            code: 'A01002002',
            upCode: 'A01002000',
            name: 'Digital Therapeutics'
          },
          { code: 'A01002001', upCode: 'A01002000', name: 'S/W (SaMD)' }
        ]
      },
      {
        code: 'A01001000',
        upCode: 'A01000000',
        name: '컨설팅',
        sub: [
          { code: 'A01001004', upCode: 'A01001000', name: 'Other' },
          { code: 'A01001003', upCode: 'A01001000', name: '보험' },
          { code: 'A01001002', upCode: 'A01001000', name: 'GMP' },
          { code: 'A01001001', upCode: 'A01001000', name: '인허가' }
        ]
      }
    ]
  },
  { code: 'A05000000', upCode: null, name: '보험-치료재료' },
  { code: 'A04000000', upCode: null, name: '체외진단용기기' },
  { code: 'A03000000', upCode: null, name: '임상시험' },
  { code: 'A02000000', upCode: null, name: '의약품' },
  { code: 'A06000000', upCode: null, name: '해외인증' }
];
export default code;
