import React from 'react';

// seconds, callback()
export const Timer = (props) => {
  const [seconds, setSeconds] = React.useState(props.seconds);

  React.useEffect(() => {
    const countDown = setInterval(() => {
      setSeconds(seconds - 1);
      //   props.seconds = props.seconds - 1;
    }, 1000);
    if (seconds <= 0) props.callback();
    return () => clearInterval(countDown);
  }, [seconds]);

  return (
    <>
      {Math.floor(seconds / 60)} :{' '}
      {seconds % 60 < 10 ? '0' + (seconds % 60) : seconds % 60}
    </>
  );
};

export default Timer;
