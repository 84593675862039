import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import Navbar from '../../../components/navbar';
import HelpMenu from './helpMenu';

import topImage from '../../../../assets/images/top.png';
import Content from './content';
import Bottom from '../../../components/bottom';

const PageContiner = styled.div`
  ${tw` flex flex-col w-full h-full items-center overflow-x-hidden `}
`;

const TopImageContainer = styled.div`
  min-height: 81px;
  background-color: #e3f8f2;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  ${tw` w-full justify-items-center `}
  img {
    margin: auto;
  }
`;

const ContentsContiner = styled.div`
  ${tw` w-full max-w-screen-lg `};
`;

export default function Index(props) {
  console.log('props.match.params.id', props.match.params.id);
  return (
    <PageContiner>
      <Navbar active="goods" />
      <TopImageContainer>
        <img src={topImage} alt="top" />
      </TopImageContainer>
      <ContentsContiner>
        {/* <HelpMenu /> */}
        <Content {...props} />
      </ContentsContiner>
      <Bottom />
    </PageContiner>
  );
}
