import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import tw, { css } from 'twin.macro';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  technologyEdit,
  technologySubmit,
  technologySelector,
  clearState
} from './technologySlice';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

import Label from '../../../components/label';
import Input, { ErrorMessage } from '../../../components/input';
import Select, { SelectDownIcon } from '../../../components/select';
import Button, { IconButton } from '../../../components/button';
import CodeSelect from '../../../components/codeSelect';

import fieldsCodeCode from '../../../code/fieldsCode';
import techLevelCode from '../../../code/techLevel';
import experienceCode from '../../../code/experience';
import FieldSelect from '../../../components/fieldSelect';
import { toast } from 'react-toastify';
import goLogin from '../../../utils/goLogin';
import { useHistory } from 'react-router';

export default function Edit(props) {
  const { open, editClose, technologyId, refresh } = props;
  // auth
  const history = useHistory();

  // redux
  const dispatch = useDispatch();
  const { editStatus, editResult, editData, submitStatus, submitResult } =
    useSelector(technologySelector);

  // field
  const [fieldCode, setFieldCode] = React.useState('');
  const fieldView = (code) => {
    return (
      <>
        {fieldsCodeCode[code].upCodeInfo !== undefined &&
          fieldsCodeCode[code].upCodeInfo.map((d) => {
            return <React.Fragment key={d.code}>{d.name} &gt; </React.Fragment>;
          })}
        {fieldsCodeCode[code].name}
      </>
    );
  };
  const fieldSelected = (code) => {
    setFieldCode(code);
    setValue('fieldCode', code, {
      shouldValidate: true,
      shouldDirty: true
    });
    console.log('fieldSelected', code);
  };

  // form
  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      editClose();
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    dispatch(technologySubmit({ technologyId: technologyId, reqData: data }));
  };

  // page data status
  useEffect(() => {
    if (submitStatus === 'completed') {
      // console.log('submitResult 111', submitResult);
      if (!submitResult.success) {
        alert(submitResult.message);
        if (submitResult.error === 'auth') {
          goLogin(history);
        }
      } else {
        toast.success('완료 되었습니다');
        refresh();
        editClose();
      }
    }
    return () => {};
  }, [submitStatus]);

  const [pageLoading, setPageLoading] = useState(true);
  // page data
  useEffect(() => {
    console.log('시작 open', open);
    reset();
    fieldSelected();

    if (open) {
      console.log('effect ++++ ', technologyId);
      setPageLoading(true);
      // reset();
      dispatch(technologyEdit({ technologyId: technologyId }));
    }
    return () => {
      reset();
    };
  }, [open]);

  // page data status
  useEffect(() => {
    console.log('editStatus 1', editStatus);

    if (editStatus === 'completed') {
      if (editData) {
        // console.log('결과 있슴.', editData.name);
        reset({
          name: editData.name,
          techLevel: editData.techLevel,
          experience: editData.experience
        });
        fieldSelected(editData.fieldCode);
        // console.log('editData.row', editData.row);
        // console.log('editStatus completed', editStatus);
      }
      setPageLoading(false);
    }
    return () => {};
  }, [editStatus]);

  // const cancelButtonRef = useRef(null);
  return (
    !pageLoading && (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
        transitionDuration={0}
        className=""
      >
        <DialogTitle id="form-dialog-title">
          <div className="font-medium text-2xl pt-3 pl-3">기술정보</div>
        </DialogTitle>
        <DialogContent>
          <form id="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left">
              <div className="mt-5 ">
                <div>
                  <Label htmlFor="name">보유기술</Label>
                  <Controller
                    render={({ field, fieldState }) => (
                      <Input
                        id="name"
                        value={field.value}
                        onChange={field.onChange}
                        inputRef={field.ref} // wire up the input ref
                        placeholder="보유기술을 입력해 주세요"
                        error={fieldState.error}
                        errorMessage={errors.name && errors.name.message}
                      />
                    )}
                    name="name"
                    control={control}
                    rules={{ required: '보유기술을 입력해 주세요' }}
                  />
                </div>
                <div className="mt-5">
                  <Label htmlFor="field_code">전문분야</Label>
                  <div className="flex rounded p-0.5">
                    <FieldSelect id="field_code" selected={fieldSelected} />
                    <input
                      type="hidden"
                      {...register('fieldCode', {
                        required: '전분분야를 선택해 주세요'
                      })}
                    />
                    <div className="pl-4 pt-1.5 text-base">
                      {fieldCode ? fieldView(fieldCode) : ``}
                    </div>
                  </div>
                  {errors.fieldCode && errors.fieldCode.message && (
                    <ErrorMessage>{errors.fieldCode.message}</ErrorMessage>
                  )}
                </div>
                <div className="mt-5 grid grid-cols-2 gap-5">
                  <div>
                    <Label htmlFor="tech_level">숙련도</Label>
                    <Controller
                      render={({ field, fieldState }) => (
                        <CodeSelect
                          id="tech_level"
                          value={field.value}
                          onChange={field.onChange}
                          inputRef={field.ref} // wire up the input ref
                          placeholder="숙련도를 입력해 주세요"
                          error={fieldState.error}
                          errorMessage={
                            errors.techLevel && errors.techLevel.message
                          }
                          data={techLevelCode}
                        />
                      )}
                      name="techLevel"
                      control={control}
                      rules={{ required: '숙련도를 입력해 주세요' }}
                    />
                  </div>
                  <div>
                    <Label htmlFor="experience">경험</Label>
                    <Controller
                      render={({ field, fieldState }) => (
                        <CodeSelect
                          id="experience"
                          value={field.value}
                          onChange={field.onChange}
                          inputRef={field.ref}
                          placeholder="경험를 선택해 주세요"
                          error={fieldState.error}
                          errorMessage={
                            errors.experience && errors.experience.message
                          }
                          data={experienceCode}
                        />
                      )}
                      name="experience"
                      control={control}
                      rules={{ required: '경험를 선택해 주세요' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions className="flex w-full p-0 bg-gray-100 mt-5">
          <div className="w-full m-0 bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse flow-root">
            <Button type="submit" form="form" className="sm:mx-1" responsive>
              확인
            </Button>
            <Button
              type="button"
              idx="text"
              onClick={handleClose}
              className="sm:mx-1"
              responsive
            >
              취소
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    )
  );
}
