import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import Navbar from '../../../components/navbar';
import SearchMenu from './searchMenu';

import topImage from '../../../../assets/images/top.png';
import Content from './content';

import { Link } from 'react-router-dom';
import Bottom from '../../../components/bottom';

const PageContiner = styled.div`
  ${tw` flex flex-col w-full h-full items-center overflow-x-hidden `}
`;

const TopImageContainer = styled.div`
  min-height: 81px;
  background-color: #e3f8f2;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  ${tw` w-full justify-items-center `}
  img {
    margin: auto;
  }
`;

const ContentsContiner = styled.div`
  ${tw` relative w-full max-w-screen-lg items-start py-2 px-2 `};
`;

export default function Index() {
  return (
    <PageContiner>
      <Navbar active="projects" />
      {/* <TopImageContainer> */}
      {/* <img src={topImage} /> */}
      {/* </TopImageContainer> */}
      <ContentsContiner>
        {/* <SearchMenu /> */}
        <Content />
      </ContentsContiner>
      <Link to="/projects/create">작성하기</Link>
      <Bottom />
    </PageContiner>
  );
}
