import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  signUpCreate,
  signUpStore,
  signUpSelector,
  clearState
} from './signUpSlice';
import { getToken } from '../../../utils/session';

import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import isEmail from 'validator/lib/isEmail';

import Label from '../../../components/label';
import { LoadingButton } from '../../../components/button';
import Input, { ErrorMessage, InputPassword } from '../../../components/input';
import { Title2 } from '../../../components/title';
import A from '../../../components/a';

const SignUpContainer = styled.div`
  ${tw`
    h-full
    items-center
    pt-10 pr-20 pb-10 pl-20
  `};
`;

export default function Content() {
  // query-string
  const history = useHistory();
  const { search } = useLocation();
  const query = queryString.parse(search);

  // redux
  const dispatch = useDispatch();
  const { createStatus, createMessage, storeStatus, storeMessage } =
    useSelector(signUpSelector);

  // form
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();

  // submit
  const onSubmit = (data) => {
    console.log(data);
    dispatch(signUpStore({ reqData: data }));
  };

  // submit callback
  useEffect(() => {
    if (storeStatus === 'loading') {
      // dispatch(clearState());
    }
    if (storeStatus === 'completed') {
      dispatch(clearState());
      if (storeMessage === 'success') {
        toast.success('회원가입이 완료 되었습니다.');
        history.push('/');
      } else {
        toast.error(storeMessage);
      }
      // history.replace('/')
      console.log('완료');
    }
    console.log('submitStatus', storeStatus);
  }, [storeStatus]);

  // page data
  useEffect(() => {
    dispatch(signUpCreate());
  }, []);

  // page data return
  // 로딩 처리
  useEffect(() => {
    //
  }, [createStatus]);

  return (
    <SignUpContainer>
      <Title2>회원가입</Title2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-6">
          <Label htmlFor="">이용목적 (가입 후 변경가능합니다)</Label>
          <div className="mt-2">
            <div>
              <input
                type="radio"
                id="user_type_client"
                value="CLIENT"
                {...register('userType', {
                  required: '이용목적을 선택해 주세요'
                })}
              />
              <Label htmlFor="user_type_client" className="pl-1">
                클라이언트 (프로젝트를 의뢰할 기업/ 개인)
              </Label>
            </div>
            <div className="mt-1">
              <input
                type="radio"
                id="user_type_partner"
                value="PARTNER"
                {...register('userType', {
                  required: '이용목적을 선택해 주세요'
                })}
              />
              <Label htmlFor="user_type_partner" className="pl-1">
                파트너스 (프로젝트를 수주할 기업/ 개인)
              </Label>
            </div>
          </div>
          {errors.userType && errors.userType.message && (
            <ErrorMessage>{errors.userType.message}</ErrorMessage>
          )}
        </div>
        <div className="mt-6">
          <Label htmlFor="name">이름</Label>
          <Controller
            render={({ field, fieldState }) => (
              <Input
                id="name"
                value={field.value}
                onChange={field.onChange}
                inputRef={field.ref}
                placeholder="이름을 입력해 주세요"
                error={fieldState.error}
                errorMessage={errors.name && errors.name.message}
              />
            )}
            name="name"
            control={control}
            rules={{ required: '이름을 입력해 주세요' }}
          />
        </div>
        <div className="mt-6">
          <Label htmlFor="email">이메일</Label>
          <Controller
            render={({ field, fieldState }) => (
              <Input
                id="email"
                value={field.value}
                onChange={field.onChange}
                inputRef={field.ref}
                placeholder="이메일을 입력해 주세요"
                error={fieldState.error}
                errorMessage={errors.email && errors.email.message}
              />
            )}
            name="email"
            control={control}
            rules={{
              validate: (value) => {
                // console.log('value', value);
                if (!value) return '이메일을 입력해 주세요';
                if (!isEmail(value)) return '이메일 형식이 잘못되었습니다';
                return true;
              }
            }}
          />
        </div>
        <div className="mt-6">
          <Label htmlFor="password">비밀번호</Label>
          <InputPassword
            id="password"
            placeholder="비밀번호"
            error={errors.password}
            errorMessage={errors.password && errors.password.message}
            {...register('password', { required: '비밀번호를 입력해 주세요' })}
          />
        </div>
        <div className="mt-6">
          <input
            id="term_check"
            type="checkbox"
            {...register('termCheck', {
              required:
                '이용약관 및 개인정보취급방침에 동의 하셔야 가입이 완료 됩니다'
            })}
          />
          <Label htmlFor="term_check" className="pl-2">
            이용약관 및 개인정보취급방침 에 동의합니다.
          </Label>
          {errors.termCheck && errors.termCheck.message && (
            <ErrorMessage>{errors.termCheck.message}</ErrorMessage>
          )}
        </div>
        <div className="mt-8">
          <LoadingButton type="submit" disabled={storeStatus === 'loading'}>
            회원가입
          </LoadingButton>
        </div>
        <div className="flex items-center justify-between mt-6">
          <A to="sign-in">로그인</A>
          <A to="sign-in">비밀번호 찾기</A>
        </div>
        <div>type : {sessionStorage.getItem('type')}</div>
        <div>token : {getToken()}</div>
      </form>
    </SignUpContainer>
  );
}
