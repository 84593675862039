import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';

const ListContainer = styled.div`
  ${tw` flex h-full pl-9 `}
`;

const NavItemLink = styled(Link)`
  ${tw`
    flex
    items-center
    align-baseline
    font-medium
    text-base
    px-3
    text-gray-900
    hover:text-blue-900
    hover:bg-gray-100
    `}
  ${({ active }) => (active ? tw`text-blue-900 bg-gray-100` : tw` `)}
`;

export default function NavLeftItems(props) {
  return (
    <ListContainer>
      <NavItemLink to="/projects" active={props.active === 'projects'}>
        프로젝트
      </NavItemLink>
      <NavItemLink to="/partners" active={props.active === 'partners'}>
        파트너스
      </NavItemLink>
      <NavItemLink to="/goods" active={props.active === 'goods'}>
        파트너스 상품몰
      </NavItemLink>
    </ListContainer>
  );
}
