import React from 'react';
import { useHistory } from 'react-router-dom';
import { removeSession } from '../../utils/session';

export default function SignOut(props) {
  const history = useHistory();
  sessionStorage.clear();
  console.log('SignOut', sessionStorage);
  removeSession();
  history.replace('/');
  return <></>;
}
