import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { setSession, getToken } from '../../utils/session';

export const signInCreate = createAsyncThunk(
  '/sign-in/create',
  (_, thunkAPI) => {
    return axios
      .get('/sign-in', {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          // 'content-type': 'multipart/form-data'
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        console.log('response.data', response.data);
        if (response.data.success) {
          setSession(response.data.data.user);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const signInStore = createAsyncThunk(
  '/sign-in/store',
  ({ reqData }, thunkAPI) => {
    const url = '/sign-in';
    const formData = {
      email: reqData.email,
      password: reqData.password
    };
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        // 'content-type': 'multipart/form-data'
        'Content-Type': 'application/json'
      }
    };
    return axios
      .post(url, formData, config)
      .then((response) => {
        console.log('response.data', response.data);
        if (response.data.success) {
          setSession(response.data.data.user);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const signInSlice = createSlice({
  name: 'signIn',
  initialState: {
    createStatus: '',
    createMessage: '',
    createData: {},
    storeStatus: '',
    storeMessage: '',
    storeData: {}
  },
  reducers: {
    clearState: (state) => {
      state.createStatus = '';
      state.createMessage = '';
      state.createData = {};
      state.storeStatus = '';
      state.storeMessage = '';
      state.storeData = {};
      return state;
    }
  },
  extraReducers: {
    [signInCreate.pending]: (state) => {
      state.createStatus = 'loading';
    },
    [signInCreate.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.createStatus = 'completed';
      state.createMessage = payload?.message;
      state.createData = payload?.data?.pageData;
    },
    [signInCreate.rejected]: (state, { payload }) => {
      state.createStatus = 'error';
    },
    [signInStore.pending]: (state) => {
      state.storeStatus = 'loading';
    },
    [signInStore.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.storeStatus = 'completed';
      state.storeMessage = payload.message;
      state.storeData = payload?.data?.pageData;
    },
    [signInStore.rejected]: (state, { payload }) => {
      state.storeStatus = 'error';
    }
  }
});

export const { clearState } = signInSlice.actions;
export const signInSelector = (state) => state.signIn;
export default signInSlice.reducer;
