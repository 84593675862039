import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import tw, { css } from 'twin.macro';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  goodsEdit,
  goodsSubmit,
  goodsSelector,
  clearState
} from './goodsSlice';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

import { Title2 } from '../../../components/title';
import Label from '../../../components/label';
import Input, { ErrorMessage } from '../../../components/input';
import Select, { SelectDownIcon } from '../../../components/select';
import Button, { IconButton } from '../../../components/button';
import CodeSelect from '../../../components/codeSelect';

import fieldsCodeCode from '../../../code/fieldsCode';
import techLevelCode from '../../../code/techLevel';
import experienceCode from '../../../code/experience';
import FieldSelect from '../../../components/fieldSelect';
import { toast } from 'react-toastify';
import Textarea from '../../../components/textarea';
import goLogin from '../../../utils/goLogin';
import { useHistory } from 'react-router';

export default function Edit(props) {
  const { open, editClose, goodsId, refresh } = props;
  // auth
  const history = useHistory();

  // redux
  const dispatch = useDispatch();
  const { editStatus, editResult, editData, submitStatus, submitResult } =
    useSelector(goodsSelector);

  // field
  const [fieldCode, setFieldCode] = React.useState('');
  const fieldView = (code) => {
    return (
      <>
        {fieldsCodeCode[code].upCodeInfo !== undefined &&
          fieldsCodeCode[code].upCodeInfo.map((d) => {
            return <React.Fragment key={d.code}>{d.name} &gt; </React.Fragment>;
          })}
        {fieldsCodeCode[code].name}
      </>
    );
  };
  const fieldSelected = (code) => {
    setFieldCode(code);
    setValue('fieldCode', code, {
      shouldValidate: true,
      shouldDirty: true
    });
    console.log('fieldSelected', code);
  };

  // price option
  const [fixedPrice, setFixedPrice] = useState(false);
  const [rangePrice, setRangePrice] = useState(false);
  const [priceOption, setPriceOption] = useState();
  const priceOptionChange = (val) => {
    console.log('option', val);
    if (val === 'FIXED') {
      setFixedPrice(true);
      setRangePrice(false);
    } else if (val === 'RANGE') {
      setFixedPrice(false);
      setRangePrice(true);
    } else if (val === 'NEGOTIABLE') {
      setFixedPrice(false);
      setRangePrice(false);
    }
    setPriceOption(val);
  };

  // form
  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      editClose();
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    getValues,
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    // console.log('onSubmit 666', data);
    dispatch(goodsSubmit({ goodsId: goodsId, reqData: data }));
  };

  // page data status
  useEffect(() => {
    console.log('submitStatus 1', submitStatus);
    if (submitStatus === 'completed') {
      // console.log('submitResult 111', submitResult);
      if (!submitResult.success) {
        alert(submitResult.message);
        if (submitResult.error === 'auth') {
          goLogin(history);
        }
      } else {
        toast.success('완료 되었습니다');
        refresh();
        editClose();
      }
    }
    return () => {};
  }, [submitStatus]);

  const [pageLoading, setPageLoading] = useState(true);
  // page data
  useEffect(() => {
    console.log('시작 open', open);
    // reset({});

    if (open) {
      console.log('effect ++++ ', goodsId);
      setPageLoading(true);
      // reset();
      dispatch(goodsEdit({ goodsId: goodsId }));
    }
    return () => {
      reset({ priceOption: 'FIXED' });
      priceOptionChange('FIXED');
      fieldSelected();
    };
  }, [open]);

  // page data status
  useEffect(() => {
    console.log('editStatus 1', editStatus);

    if (editStatus === 'completed') {
      if (editData) {
        console.log('결과 있슴.', editData.name);
        reset({
          // id: editData.row.id,
          name: editData.name,
          summary: editData.summary,
          priceOption: editData.priceOption,
          price: editData.price,
          minPrice: editData.minPrice,
          maxPrice: editData.maxPrice,
          unit: editData.unit,
          description: editData.description,
          category: editData.category,
          fieldCode: editData.fieldCode,
          buyingLink: editData.buyingLink
        });
        fieldSelected(editData.fieldCode);
      }
      setPageLoading(false);
    }
    return () => {};
  }, [editStatus]);

  const handleTest = (e) => {
    e.preventDefault();
    console.log('3333', e);
  };

  // const cancelButtonRef = useRef(null);
  return (
    <>
      {!pageLoading && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="sm"
          transitionDuration={0}
          className=""
        >
          <DialogTitle id="form-dialog-title">
            <Title2 className="pt-3 pl-3">상품정보</Title2>
          </DialogTitle>
          <DialogContent>
            <form id="form" onSubmit={handleSubmit(onSubmit)}>
              {/* <input type1="hidden" {...register('id')} /> */}
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left">
                <div className="mt-5 ">
                  <Label htmlFor="image">상품 이미지</Label>
                  <input type="file" {...register('image')} />
                </div>
                <div className="mt-5 ">
                  <Label htmlFor="name">상품명</Label>
                  <Controller
                    render={({ field, fieldState }) => (
                      <Input
                        id="name"
                        value={field.value}
                        onChange={field.onChange}
                        inputRef={field.ref} // wire up the input ref
                        placeholder="상품명을 입력해 주세요"
                        error={fieldState.error}
                        errorMessage={errors.name && errors.name.message}
                      />
                    )}
                    name="name"
                    control={control}
                    rules={{ required: '상품명을 입력해 주세요' }}
                  />
                </div>
                <div className="mt-5">
                  <Label htmlFor="summary">한줄 요약설명</Label>
                  <Controller
                    render={({ field, fieldState }) => (
                      <Input
                        id="summary"
                        value={field.value}
                        onChange={field.onChange}
                        inputRef={field.ref} // wire up the input ref
                        placeholder="한줄 요약설명을 입력해 주세요"
                        error={fieldState.error}
                        errorMessage={errors.summary && errors.summary.message}
                      />
                    )}
                    name="summary"
                    control={control}
                    rules={{ required: '상품명을 입력해 주세요' }}
                  />
                </div>
                <div className="mt-5">
                  <Label htmlFor="field_code">전문분야</Label>
                  <div className="flex rounded p-0.5">
                    <FieldSelect id="field_code" selected={fieldSelected} />
                    <input
                      type="hidden"
                      {...register('fieldCode', {
                        required: '전분분야를 선택해 주세요'
                      })}
                    />
                    <div className="pl-4 pt-1.5 text-base">
                      {fieldCode ? fieldView(fieldCode) : ``}
                    </div>
                  </div>
                  {errors.fieldCode && errors.fieldCode.message && (
                    <ErrorMessage>{errors.fieldCode.message}</ErrorMessage>
                  )}
                </div>
                <div className="mt-5">
                  <div>
                    <Label htmlFor="summary">금액옵션</Label>
                  </div>
                  <div className="inline">
                    <Label>
                      <input
                        type="radio"
                        // name="price_option"
                        // name="priceOption"
                        value="FIXED"
                        onClick={(e) => priceOptionChange(e.target.value)}
                        // ref={register}
                        {...register('priceOption')}
                      />{' '}
                      고정가
                    </Label>
                  </div>
                  <div className="inline ml-3">
                    <Label>
                      <input
                        type="radio"
                        // name="price_option"
                        value="RANGE"
                        onClick={(e) => priceOptionChange(e.target.value)}
                        {...register('priceOption')}
                      />{' '}
                      범위가격
                    </Label>
                  </div>
                  <div className="inline ml-3">
                    <Label>
                      <input
                        type="radio"
                        // name="price_option"
                        value="NEGOTIABLE"
                        onClick={(e) => priceOptionChange(e.target.value)}
                        {...register('priceOption')}
                      />{' '}
                      협의가
                    </Label>
                  </div>
                </div>
                {/* 고정: FIXED / 범위: RANGE / 협의: NEGOTIABLE */}
                <div className={` mt-5 grid grid-cols-2 gap-5`}>
                  <div>
                    <Label htmlFor="unit">판매단위</Label>
                    <Controller
                      render={({ field, fieldState }) => (
                        <Input
                          type="text"
                          id="unit"
                          value={field.value}
                          onChange={field.onChange}
                          inputRef={field.ref} // wire up the input ref
                          placeholder="판매단위를 입력해 주세요"
                          error={fieldState.error}
                          errorMessage={errors.price && errors.price.message}
                        />
                      )}
                      name="unit"
                      control={control}
                      rules={{ required: '판매단위를 입력해 주세요' }}
                    />
                  </div>
                  <div className={!fixedPrice && `hidden`}>
                    <Label htmlFor="price">고정가격(원)</Label>
                    <Controller
                      render={({ field, fieldState }) => (
                        <Input
                          type="number"
                          id="price"
                          value={field.value}
                          onChange={field.onChange}
                          inputRef={field.ref} // wire up the input ref
                          placeholder="상품가격을 입력해 주세요"
                          error={fieldState.error}
                          errorMessage={errors.price && errors.price.message}
                        />
                      )}
                      name="price"
                      control={control}
                      rules={{
                        validate: (value) => {
                          return getValues('priceOption') === 'FIXED' && !value
                            ? '상품가격을 입력해 주세요'
                            : true;
                        }
                      }}
                    />
                  </div>
                </div>
                <div
                  className={`${
                    !rangePrice && `hidden`
                  } mt-5 grid grid-cols-2 gap-5`}
                >
                  <div>
                    <Label htmlFor="min_price">최소가격(원)</Label>
                    <Controller
                      render={({ field, fieldState }) => (
                        <Input
                          type="number"
                          id="min_price"
                          value={field.value}
                          onChange={field.onChange}
                          inputRef={field.ref} // wire up the input ref
                          placeholder="최소가격을 입력해 주세요"
                          error={fieldState.error}
                          errorMessage={
                            errors.minPrice && errors.minPrice.message
                          }
                        />
                      )}
                      name="minPrice"
                      control={control}
                      rules={{
                        validate: (value) => {
                          return getValues('priceOption') === 'RANGE' && !value
                            ? '최소가격을 입력해 주세요'
                            : true;
                        }
                      }}
                    />
                  </div>
                  <div>
                    <Label htmlFor="max_price">최대가격(원)</Label>
                    <Controller
                      render={({ field, fieldState }) => (
                        <Input
                          id="max_price"
                          value={field.value}
                          onChange={field.onChange}
                          inputRef={field.ref} // wire up the input ref
                          placeholder="최대가격을 입력해 주세요"
                          error={fieldState.error}
                          errorMessage={
                            errors.maxPrice && errors.maxPrice.message
                          }
                        />
                      )}
                      name="maxPrice"
                      control={control}
                      rules={{
                        validate: (value) => {
                          return getValues('priceOption') === 'RANGE' && !value
                            ? '최대가격을 입력해 주세요'
                            : true;
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="mt-5">
                  <Label htmlFor="description">상세설명</Label>
                  <Controller
                    render={({ field, fieldState }) => (
                      <Textarea
                        id="description"
                        value={field.value}
                        onChange={field.onChange}
                        inputRef={field.ref}
                        placeholder="개인정보 및 연락처는 입력을 금지 합니다"
                        error={fieldState.error}
                        errorMessage={
                          errors.description && errors.description.message
                        }
                        rows={5}
                      />
                    )}
                    name="description"
                    control={control}
                    rules={{ required: '상세설명을 입력해 주세요' }}
                  />
                </div>
              </div>
            </form>
          </DialogContent>
          <DialogActions
            className="flex w-full p-0 bg-gray-100 mt-5"
            // style={{ padding: 0 }}
          >
            <div className="w-full m-0 bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse flow-root">
              <Button
                type="submit"
                form="form"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm"
              >
                확인
              </Button>
              <IconButton
                type="button"
                onClick={handleClose}
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                취소
              </IconButton>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
