import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import tw, { css } from 'twin.macro';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  licenseEdit,
  licenseSubmit,
  licenseSelector,
  clearState
} from './licenseSlice';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

import { Title2 } from '../../../components/title';
import Label from '../../../components/label';
import Input, { ErrorMessage } from '../../../components/input';
import Select, { SelectDownIcon } from '../../../components/select';
import Button, { IconButton } from '../../../components/button';
import CodeSelect from '../../../components/codeSelect';

import { toast } from 'react-toastify';
import YearSelect from '../../../components/yearSelect';
import MonthSelect from '../../../components/monthSelect';
import goLogin from '../../../utils/goLogin';
import { useHistory } from 'react-router';

export default function Edit(props) {
  const { open, editClose, licenseId, refresh } = props;
  // auth
  const history = useHistory();

  // redux
  const dispatch = useDispatch();
  const { editStatus, editResult, editData, submitStatus, submitResult } =
    useSelector(licenseSelector);

  // form
  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      editClose();
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    dispatch(licenseSubmit({ licenseId: licenseId, reqData: data }));
  };

  // page data status
  useEffect(() => {
    console.log('submitStatus 1', submitStatus);
    if (submitStatus === 'completed') {
      if (!submitResult.success) {
        alert(submitResult.message);
        if (submitResult.error === 'auth') {
          goLogin(history);
        }
      } else {
        toast.success('완료 되었습니다');
        refresh();
        editClose();
      }
    }
    return () => {};
  }, [submitStatus]);

  const [pageLoading, setPageLoading] = useState(true);
  // page data
  useEffect(() => {
    console.log('시작 open', open);
    reset();

    if (open) {
      console.log('effect ++++ ', licenseId);
      setPageLoading(true);
      // reset();
      dispatch(licenseEdit({ licenseId: licenseId }));
    }
    return () => {
      // setPageLoading(true);
      reset();
    };
  }, [open]);

  // page data status
  useEffect(() => {
    console.log('editStatus 1', editStatus);

    if (editStatus === 'completed') {
      if (editData) {
        console.log('결과 있슴.', editData.name);
        reset({
          name: editData.name,
          issuer: editData.issuer,
          issueYear: editData.issueYear,
          issueMonth: editData.issueMonth
        });
      }
      setPageLoading(false);
    }
    return () => {};
  }, [editStatus]);

  return (
    !pageLoading && (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
        transitionDuration={0}
        className=""
      >
        <DialogTitle id="form-dialog-title">
          <Title2 className="pt-3 pl-3">자격정보</Title2>
        </DialogTitle>
        <DialogContent>
          <form id="form" onSubmit={handleSubmit(onSubmit)}>
            {/* <input type1="hidden" {...register('id')} /> */}
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left">
              <div className="mt-5 ">
                <div>
                  <Label htmlFor="name">자격(증)명</Label>
                  <Controller
                    render={({ field, fieldState }) => (
                      <Input
                        id="name"
                        value={field.value}
                        onChange={field.onChange}
                        inputRef={field.ref} // wire up the input ref
                        placeholder="자격(증)명을 입력해 주세요"
                        error={fieldState.error}
                        errorMessage={errors.name && errors.name.message}
                      />
                    )}
                    name="name"
                    control={control}
                    rules={{ required: '자격(증)명을 입력해 주세요' }}
                  />
                </div>
                <div className="mt-5">
                  <Label htmlFor="issuer">발급기관</Label>
                  <Controller
                    render={({ field, fieldState }) => (
                      <Input
                        id="issuer"
                        value={field.value}
                        onChange={field.onChange}
                        inputRef={field.ref} // wire up the input ref
                        placeholder="발급기관을 입력해 주세요"
                        error={fieldState.error}
                        errorMessage={errors.issuer && errors.issuer.message}
                      />
                    )}
                    name="issuer"
                    control={control}
                    rules={{ required: '발급기관을 입력해 주세요' }}
                  />
                </div>
                <div className="mt-5 grid grid-cols-2 gap-5">
                  <div>
                    <Label htmlFor="issue_year">발급년월(연)</Label>
                    <Controller
                      render={({ field, fieldState }) => (
                        <YearSelect
                          id="issue_year"
                          value={field.value}
                          onChange={field.onChange}
                          inputRef={field.ref} // wire up the input ref
                          placeholder="발급년월(연)을 입력해 주세요"
                          error={fieldState.error}
                          errorMessage={
                            errors.issueYear && errors.issueYear.message
                          }
                          startY="1970"
                        />
                      )}
                      name="issueYear"
                      control={control}
                      rules={{ required: '발급년월(연)을 입력해 주세요' }}
                    />
                  </div>
                  <div>
                    <Label htmlFor="issue_month">발급년월(월)</Label>
                    <Controller
                      render={({ field, fieldState }) => (
                        <MonthSelect
                          id="issue_month"
                          value={field.value}
                          onChange={field.onChange}
                          inputRef={field.ref} // wire up the input ref
                          placeholder="발급년월(월)을 선택해 주세요"
                          error={fieldState.error}
                          errorMessage={
                            errors.issueMonth && errors.issueMonth.message
                          }
                        />
                      )}
                      name="issueMonth"
                      control={control}
                      rules={{ required: '발급년월(월)을 선택해 주세요' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions className="flex w-full p-0 bg-gray-100 mt-5">
          <div className="w-full m-0 bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse flow-root">
            <Button type="submit" form="form" className="sm:mx-1" responsive>
              확인
            </Button>
            <Button
              type="button"
              idx="text"
              onClick={handleClose}
              className="sm:mx-1"
              responsive
            >
              취소
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    )
  );
}
