import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { setSession, getToken } from '../../../utils/session';

// index
export const userTypeChangeIndex = createAsyncThunk(
  '/my-page/user-type-change/index',
  (_, thunkAPI) => {
    const url = `/my-page/user-type-change`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        console.log('response.data', response.data);
        console.log('response.data.data.user', response.data.data?.user);
        if (response.data.success && response.data.error !== 'auth') {
          setSession(response.data.data?.user);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

// update
export const userTypeChangeUpdate = createAsyncThunk(
  '/my-page/user-type-change/update',
  ({ reqData }, thunkAPI) => {
    const url = `/my-page/user-type-change`;
    const formData = {
      userType: reqData.userType
    };

    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        // 'content-type': 'multipart/form-data'
        'Content-Type': 'application/json'
      }
    };
    return axios
      .post(url, formData, config)
      .then((response) => {
        console.log('response.data', response.data);
        console.log('response.data.data.user', response.data.data?.user);
        if (response.data.success && response.data.error !== 'auth') {
          setSession(response.data.data?.user);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const userTypeChangeSlice = createSlice({
  name: 'userTypeChange',
  initialState: {
    indexStatus: '',
    indexResult: {},
    indexData: {},
    updateStatus: '',
    updateResult: {},
    updateData: {}
  },
  reducers: {
    clearState: (state) => {
      state.indexStatus = '';
      state.indexResult = '';
      state.indexData = {};
      state.updateStatus = '';
      state.updateResult = '';
      state.updateData = {};
      return state;
    }
  },
  extraReducers: {
    [userTypeChangeIndex.pending]: (state) => {
      state.indexStatus = 'loading';
    },
    [userTypeChangeIndex.fulfilled]: (state, { payload }) => {
      console.log('payload index', payload);
      state.indexStatus = 'completed';
      state.indexResult = {
        success: payload?.success,
        message: payload?.message,
        error: payload?.error
      };
      state.indexData = payload?.data?.pageData;
    },
    [userTypeChangeIndex.rejected]: (state, { payload }) => {
      state.indexStatus = 'error';
    },
    [userTypeChangeUpdate.pending]: (state) => {
      state.updateStatus = 'loading';
    },
    [userTypeChangeUpdate.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.updateStatus = 'completed';
      state.updateResult = {
        success: payload?.success,
        message: payload?.message,
        error: payload?.error
      };
    },
    [userTypeChangeUpdate.rejected]: (state, { payload }) => {
      state.updateStatus = 'error';
    }
  }
});

export const { clearState } = userTypeChangeSlice.actions;
export const userTypeChangeSelector = (state) => state.userTypeChange;
export default userTypeChangeSlice.reducer;
