// 기간계산
export const diffYM = (startYear, startMonth, endYear, endMonth) => {
  let y = endYear - startYear;
  let m = endMonth - startMonth;
  if (m < 0) {
    y = y - 1;
    m = 12 + m;
  }
  return `${y}년 ${m}개월`;
};
