import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { setSession, getToken } from '../../../utils/session';

// edit
export const passwordEdit = createAsyncThunk(
  '/my-page/password/edit',
  (_, thunkAPI) => {
    const url = `/my-page/password/edit`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        console.log('response.data', response.data);
        console.log('response.data.data.user', response.data.data?.user);
        if (response.data.success && response.data.error !== 'auth') {
          setSession(response.data.data?.user);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

// update
export const passwordUpdate = createAsyncThunk(
  '/my-page/password/update',
  ({ reqData }, thunkAPI) => {
    const url = `/my-page/password`;
    const formData = {
      password: reqData.password,
      newPassword: reqData.newPassword
    };

    console.log('formData()', formData);
    console.log('getToken()', getToken());
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        // 'content-type': 'multipart/form-data'
        'Content-Type': 'application/json'
      }
    };
    return axios
      .post(url, formData, config)
      .then((response) => {
        console.log('response.data', response.data);
        console.log('response.data.data.user', response.data.data?.user);
        if (response.data.success && response.data.error !== 'auth') {
          setSession(response.data.data?.user);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const passwordSlice = createSlice({
  name: 'myPagePassword',
  initialState: {
    editStatus: '',
    editResult: {},
    editData: {},
    updateStatus: '',
    updateResult: {},
    updateData: {}
  },
  reducers: {
    clearState: (state) => {
      state.editStatus = '';
      state.editResult = '';
      state.editData = {};
      state.updateStatus = '';
      state.updateResult = '';
      state.updateData = {};
      return state;
    }
  },
  extraReducers: {
    [passwordEdit.pending]: (state) => {
      state.editStatus = 'loading';
    },
    [passwordEdit.fulfilled]: (state, { payload }) => {
      console.log('payload edit', payload);
      state.editStatus = 'completed';
      state.editResult = {
        success: payload?.success,
        message: payload?.message,
        error: payload?.error
      };
      state.editData = payload?.data?.pageData;
    },
    [passwordEdit.rejected]: (state, { payload }) => {
      state.editStatus = 'error';
    },
    [passwordUpdate.pending]: (state) => {
      state.updateStatus = 'loading';
    },
    [passwordUpdate.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.updateStatus = 'completed';
      state.updateResult = {
        success: payload?.success,
        message: payload?.message,
        error: payload?.error
      };
    },
    [passwordUpdate.rejected]: (state, { payload }) => {
      state.updateStatus = 'error';
    }
  }
});

export const { clearState } = passwordSlice.actions;
export const passwordSelector = (state) => state.myPagePassword;
export default passwordSlice.reducer;
