import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

const Container = styled.div`
  height: 50px;
  ${tw`
    relative
    flex
    w-full
    cursor-pointer
    text-black
    items-center
    text-center
    justify-center
    rounded
    px-6
  `};

  ${({ kakao }) => kakao && `background-color: #fee500`}
  ${({ naver }) => naver && `background-color: #1ec800`}
  ${({ email }) => email && `background-color: #efefef`}
`;

const SnsText = styled.h2`
  ${tw`text-center font-semibold`}
  ${({ kakao }) => kakao && `color: #000`}
  ${({ naver }) => naver && `color: #fff`}
  ${({ email }) => email && `color: #000`}
`;

export const SnsLoginKakao = (props) => {
  return (
    <Container kakao {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`absolute left-6 w-6 h-6 fill-current`}
        width="current"
        height="current"
        viewBox="0 0 24 24"
      >
        <path
          fill="#000"
          fill-rule="evenodd"
          d="M12 2.9c-5.5 0-10 3.4-10 7.6 0 2.6 1.7 4.9 4.4 6.2l-1.1 4c-.1.4.3.6.6.4l4.8-3.1H12c5.5 0 10-3.3 10-7.5s-4.5-7.6-10-7.6"
        />
      </svg>
      <SnsText kakao>{props.label}</SnsText>
    </Container>
  );
};

export const SnsLoginNaver = (props) => {
  return (
    <Container naver {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`absolute left-6 w-6 h-6 fill-current`}
        width="current"
        height="current"
        viewBox="0 0 24 24"
      >
        <path
          fill="#fff"
          fill-rule="evenodd"
          d="M15.2 3v9.2L8.8 3H2v18.3h6.8v-9.2l6.4 9.2H22V3z"
        />
      </svg>
      <SnsText naver>{props.label}</SnsText>
    </Container>
  );
};

export const EmailSignUp = (props) => {
  return (
    <Container email {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`absolute left-6 w-6 h-6 fill-current`}
        width="current"
        height="current"
        viewBox="0 0 24 24"
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z" />
      </svg>
      <SnsText email>{props.label}</SnsText>
    </Container>
  );
};
