import axios from 'axios';
import { useState } from 'react';
import { getToken, setSession } from '../../utils/session';
import { FavoriteIcon } from '../icon';

export default function FavoriteBotton(props) {
  const [checked, setChecked] = useState(props.checked);
  const [cnt, setCnt] = useState(props.cnt || 0);
  const { favType, favId } = props;

  const handleClick = (e) => {
    console.log('click');
    let url, params;
    if (favType === 'project') {
      url = '/projects/favorites';
      params = {
        projectId: favId,
        add: !checked
      };
    } else if (favType === 'partner') {
      url = '/partners/favorites';
      params = {
        partnerId: favId,
        add: !checked
      };
    } else if (favType === 'goods') {
      url = '/goods/favorites';
      params = {
        goodsId: favId,
        add: !checked
      };
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        },
        params: params
      })
      .then((response) => {
        console.log('response.data.data.pageData.checked');
        if (response?.data?.data?.pageData) {
          setChecked(response?.data?.data?.pageData.checked);
          setCnt(response?.data?.data?.pageData.cnt);
        }
        console.log('response.data', response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div
      onClick={handleClick}
      className="flex border rounded-full px-3 h-6 items-center cursor-pointer"
    >
      <FavoriteIcon className="" checked={checked} />
      <span className="pl-2 text-xs">{cnt}</span>
    </div>
  );
}
