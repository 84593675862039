import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import Navbar from '../../components/navbar';
import topImage from '../../../assets/images/top.png';
import Content from './content';
import SnsSignIn from './snsSignIn';
import Bottom from '../../components/bottom';

const PageContiner = styled.div`
  ${tw`
    flex
    flex-col
    w-full
    h-full
    items-center
    overflow-x-hidden
  `}
`;

const TopImageContainer = styled.div`
  min-height: 81px;
  background-color: #e3f8f2;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  ${tw`
    w-full
    // items-center  
    justify-items-center
    
  `}
  img {
    margin: auto;
    /* width: auto; */
    /* height: 100%; */
  }
`;

const ContentsContiner = styled.div`
  ${tw`
    w-full
    max-w-screen-xl
    items-start
    grid 
    grid-cols-2
    grid-rows-none
    divide-x
    divide-gray-300
    pt-6
    pl-6
    pr-6
    pb-6
  `};
`;

export default function SignInPage() {
  return (
    <PageContiner>
      <Navbar />
      <TopImageContainer>
        <img src={topImage} />
      </TopImageContainer>
      <ContentsContiner>
        <Content />
        <SnsSignIn />
      </ContentsContiner>
      <Bottom />
    </PageContiner>
  );
}
