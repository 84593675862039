import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { setSession, getToken } from '../../../utils/session';

// index
export const signUpFormCreate = createAsyncThunk(
  '/sign-up/form/create',
  ({ code }, thunkAPI) => {
    return axios
      .get(`/sign-up/email/${code}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          // 'content-type': 'multipart/form-data'
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        console.log('response.data', response.data);
        if (response.data.success && response.data?.data?.user)
          setSession(response.data.data.user);
        // if (response.data.success) {
        //   setSession(response.data.data.user);
        // }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const signUpFormStore = createAsyncThunk(
  '/sign-up/form/store',
  ({ code, reqData }, thunkAPI) => {
    const url = `/sign-up/email/${code}`;
    const formData = {
      // code: reqData.code,
      email: reqData.email,
      password: reqData.password,
      userType: reqData.userType
    };
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        // 'content-type': 'multipart/form-data'
        'Content-Type': 'application/json'
      }
    };
    return axios
      .post(url, formData, config)
      .then((response) => {
        console.log('response.data', response.data);
        if (response.data.success && response.data?.data?.user)
          setSession(response.data.data.user);
        if (!response.data.success && response.data.error === 'auth') {
          // 시간만료등....
          // alert(response.data.message);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const signUpFormSlice = createSlice({
  name: 'signUpForm',
  initialState: {
    createStatus: '',
    createMessage: '',
    createData: {},
    storeStatus: '',
    storeMessage: '',
    storeData: {}
  },
  reducers: {
    clearState: (state) => {
      state.createStatus = '';
      state.createMessage = '';
      state.createData = {};
      state.storeStatus = '';
      state.storeMessage = '';
      state.storeData = {};
      return state;
    }
  },
  extraReducers: {
    [signUpFormCreate.pending]: (state) => {
      state.createStatus = 'loading';
    },
    [signUpFormCreate.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.createStatus = 'completed';
      state.createMessage = payload?.message;
      state.createData = payload?.data?.pageData;
    },
    [signUpFormCreate.rejected]: (state, { payload }) => {
      state.createStatus = 'error';
    },
    [signUpFormStore.pending]: (state) => {
      state.storeStatus = 'loading';
    },
    [signUpFormStore.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.storeStatus = 'completed';
      state.storeMessage = payload.message;
      state.storeData = payload?.data?.pageData;
    },
    [signUpFormStore.rejected]: (state, { payload }) => {
      state.storeStatus = 'error';
    }
  }
});

export const { clearState } = signUpFormSlice.actions;
export const signUpFormSelector = (state) => state.signUpForm;
export default signUpFormSlice.reducer;
