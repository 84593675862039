import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { setSession, getToken } from '../../../utils/session';

// edit
export const leaveEdit = createAsyncThunk(
  '/my-page/leave/edit',
  (_, thunkAPI) => {
    const url = `/my-page/leave/edit`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        console.log('response.data', response.data);
        console.log('response.data.data.user', response.data.data?.user);
        if (response.data.success && response.data.error !== 'auth') {
          setSession(response.data.data?.user);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

// update
export const leaveUpdate = createAsyncThunk(
  '/my-page/leave/update',
  ({ reqData }, thunkAPI) => {
    const url = `/my-page/leave`;
    const formData = {
      leaveType: reqData.leaveType,
      comment: reqData.comment
    };

    console.log('formData()', formData);
    console.log('getToken()', getToken());
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        // 'content-type': 'multipart/form-data'
        'Content-Type': 'application/json'
      }
    };
    return axios
      .post(url, formData, config)
      .then((response) => {
        console.log('response.data', response.data);
        console.log('response.data.data.user', response.data.data?.user);
        if (response.data.success && response.data.error !== 'auth') {
          setSession(response.data.data?.user);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const leaveSlice = createSlice({
  name: 'myPageLeave',
  initialState: {
    editStatus: '',
    editResult: {},
    editData: {},
    updateStatus: '',
    updateResult: {},
    updateData: {}
  },
  reducers: {
    clearState: (state) => {
      state.editStatus = '';
      state.editResult = '';
      state.editData = {};
      state.updateStatus = '';
      state.updateResult = '';
      state.updateData = {};
      return state;
    }
  },
  extraReducers: {
    [leaveEdit.pending]: (state) => {
      state.editStatus = 'loading';
    },
    [leaveEdit.fulfilled]: (state, { payload }) => {
      console.log('payload edit', payload);
      state.editStatus = 'completed';
      state.editResult = {
        success: payload?.success,
        message: payload?.message,
        error: payload?.error
      };
      state.editData = payload?.data?.pageData;
    },
    [leaveEdit.rejected]: (state, { payload }) => {
      state.editStatus = 'error';
    },
    [leaveUpdate.pending]: (state) => {
      state.updateStatus = 'loading';
    },
    [leaveUpdate.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.updateResult = {
        success: payload?.success,
        message: payload?.message,
        error: payload?.error
      };
    },
    [leaveUpdate.rejected]: (state, { payload }) => {
      state.updateStatus = 'error';
    }
  }
});

export const { clearState } = leaveSlice.actions;
export const leaveSelector = (state) => state.myPageLeave;
export default leaveSlice.reducer;
