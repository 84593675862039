import { sprintf } from 'sprintf-js';

export const priceView = (row) => {
  // 고정: FIXED / 범위: RANGE / 협의: NEGOTIABLE
  if (row.priceOption === 'FIXED') {
    return (
      <>{new Intl.NumberFormat('ko-KR').format(sprintf('%d', row.price))} 원</>
    );
  } else if (row.priceOption === 'RANGE') {
    if (row.minPrice === 0) {
      return (
        <>
          ~ {new Intl.NumberFormat('ko-KR').format(sprintf('%d', row.maxPrice))}{' '}
          원
        </>
      );
    } else if (row.maxPrice === 0) {
      return (
        <>
          {new Intl.NumberFormat('ko-KR').format(sprintf('%d', row.minPrice))}{' '}
          원 ~
        </>
      );
    } else {
      return (
        <>
          {new Intl.NumberFormat('ko-KR').format(sprintf('%d', row.minPrice))} ~{' '}
          {new Intl.NumberFormat('ko-KR').format(sprintf('%d', row.maxPrice))}{' '}
          원
        </>
      );
    }
  } else if (row.priceOption === 'NEGOTIABLE') {
    return <>협의</>;
  }
  return <></>;
};

export const priceFormat = (v) => {
  return new Intl.NumberFormat('ko-KR').format(sprintf('%d', v.amount));
};

export default priceView;
