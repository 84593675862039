import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { setSession, getToken } from '../../../utils/session';

// index
export const goodsIndex = createAsyncThunk(
  '/my-partners/goods/index',
  (reqData, thunkAPI) => {
    return axios
      .get('/my-partners/goods', {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        console.log('response.data', response.data);
        console.log('response.data.data.user', response.data.data?.user);
        if (response.data.success && response.data.error !== 'auth') {
          setSession(response.data.data?.user);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

// edit
export const goodsEdit = createAsyncThunk(
  '/my-partners/goods/edit',
  ({ goodsId }, thunkAPI) => {
    const url = goodsId
      ? `/my-partners/goods/${goodsId}/edit`
      : `/my-partners/goods/create`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        console.log('response.data', response.data);
        console.log('response.data.data.user', response.data.data?.user);
        if (response.data.success && response.data.error !== 'auth') {
          setSession(response.data.data?.user);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

// store
export const goodsSubmit = createAsyncThunk(
  '/my-partners/goods/store',
  ({ goodsId, reqData }, thunkAPI) => {
    console.log('submit goodsId', goodsId);
    console.log('image image', reqData.image[0]);
    const url = goodsId
      ? `/my-partners/goods/${goodsId}`
      : `/my-partners/goods`;
    const formData = new FormData();
    formData.append('name', reqData.name);
    formData.append('summary', reqData.summary);
    formData.append('priceOption', reqData.priceOption);
    formData.append('price', reqData.price);
    formData.append('minPrice', reqData.minPrice);
    formData.append('maxPrice', reqData.maxPrice);
    formData.append('unit', reqData.unit);
    formData.append('description', reqData.description);
    formData.append('category', reqData.category);
    formData.append('fieldCode', reqData.fieldCode);
    formData.append('buyingLink', reqData.buyingLink);
    reqData.image[0] && formData.append('image', reqData.image[0]);
    console.log('getToken()', getToken());
    console.log('reqData.image', reqData.image);
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'multipart/form-data'
        // 'Content-Type': 'application/json'
      }
    };
    return axios
      .post(url, formData, config)
      .then((response) => {
        console.log('response.data', response.data);
        console.log('response.data.data.user', response.data.data?.user);
        if (response.data.success && response.data.error !== 'auth') {
          setSession(response.data.data?.user);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const goodsSlice = createSlice({
  name: 'myPartnersGoods',
  initialState: {
    indexStatus: '',
    indexResult: {},
    indexData: {},
    editStatus: '',
    editResult: {},
    editData: {},
    submitStatus: '',
    submitResult: {}
  },
  reducers: {
    clearState: (state) => {
      state.indexStatus = '';
      state.indexResult = {};
      state.indexData = {};
      state.editStatus = '';
      state.editResult = {};
      state.editData = {};
      state.submitStatus = '';
      state.submitResult = {};
      return state;
    }
  },
  extraReducers: {
    [goodsIndex.pending]: (state) => {
      state.indexStatus = 'loading';
    },
    [goodsIndex.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.indexStatus = 'completed';
      state.indexResult = {
        success: payload?.success,
        message: payload?.message,
        error: payload?.error
      };
      state.indexData = payload?.data?.pageData;
    },
    [goodsIndex.rejected]: (state, { payload }) => {
      state.indexStatus = 'error';
    },
    [goodsEdit.pending]: (state) => {
      state.editStatus = 'loading';
    },
    [goodsEdit.fulfilled]: (state, { payload }) => {
      console.log('payload edit', payload);
      state.editStatus = 'completed';
      state.editResult = {
        success: payload?.success,
        message: payload?.message,
        error: payload?.error
      };
      state.editData = payload?.data?.pageData;
    },
    [goodsEdit.rejected]: (state, { payload }) => {
      state.editStatus = 'error';
    },
    [goodsSubmit.pending]: (state) => {
      state.submitStatus = 'loading';
    },
    [goodsSubmit.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.submitStatus = 'completed';
      state.submitResult = {
        success: payload?.success,
        message: payload?.message,
        error: payload?.error
      };
    },
    [goodsSubmit.rejected]: (state, { payload }) => {
      state.submitStatus = 'error';
    }
  }
});

export const { clearState } = goodsSlice.actions;
export const goodsSelector = (state) => state.myPartnersGoods;
export default goodsSlice.reducer;
