import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import BeatLoader from 'react-spinners/BeatLoader';
import FadeLoader from 'react-spinners/FadeLoader';

// const BaseButton = styled.button`
//   ${tw`
//     relative
//     w-full
//     flex
//     justify-center
//     py-2
//     px-4
//     border
//     border-transparent
//     text-sm
//     font-medium
//     rounded-md
//     text-white
//     bg-indigo-600
//     hover:bg-indigo-700
//     focus:outline-none
//     focus:ring-2
//     focus:ring-offset-2
//     focus:ring-indigo-500
//   `}
// `;

const ButtonStyle = styled.button`
  ${tw`
    // relative
    // w-full
    // flex
    // justify-center
    // bg-blue-700 
    // hover:bg-blue-800 
    // text-white
    text-sm 
    font-semibold 
    py-1 
    px-4 
    rounded 
    focus:outline-none 
    focus:ring
  `}
  ${({ full }) => full && tw`w-full`}
  ${({ idx, disabled }) =>
    idx === 'text' && !disabled && tw`bg-white hover:bg-gray-100`}
  ${({ idx, disabled }) =>
    idx === 'text' && disabled && tw`text-gray-300 cursor-not-allowed`}
  ${({ idx, color, disabled }) =>
    idx === 'text' &&
    color === 'red' &&
    !disabled &&
    tw`text-red-400 hover:text-red-500`}
`;

// idx: text, color: blue, desabled
/* ${({ color }) => color === 'red' && tw`text-red-400 hover:bg-red-500`} */
/* ${({ disabled }) => disabled && tw`bg-blue-50 hover:bg-blue-50`} */

const BaseIconButton = styled.button`
  ${tw`
    relative
    // w-full
    // flex
    // justify-center
    text-blue-600
    font-semibold 
    py-2 px-4 
    rounded
    // border
    // pl-8
    hover:bg-blue-500 
    hover:text-white
    focus:outline-none 
  `}
  ${({ icon }) => icon && tw`pl-8`}
  ${({ full }) => full && tw`w-full`}
  ${({ color }) => color === 'red' && tw`text-red-400 hover:bg-red-500`}
  ${({ disabled }) => disabled && tw`bg-blue-50 hover:bg-blue-50`}
`;

const BaseTextButton = styled.button`
  ${tw`
    relative
    // w-full
    // flex
    // justify-center
    text-blue-600
    font-semibold 
    py-2 
    px-4 
    rounded
    // border
    // pl-8
    hover:bg-blue-500 
    hover:text-white
    focus:outline-none 
  `}
  ${({ icon }) => icon && tw`pl-8`}
  ${({ full }) => full && tw`w-full`}
  ${({ red }) => red && tw`text-red-400 hover:bg-red-500`}
  ${({ disabled }) => disabled && tw`bg-blue-50 hover:bg-blue-50`}
`;

// const submitLoadCss = css`
//   ${tw`
//     absolute
//   `}
// `;

// idx => text(default) / outline / fill
// color => blue(default) / red / etc
// size => 없음.

export function Button2(props) {
  return <BaseTextButton {...props}>{props.children}</BaseTextButton>;
}

const buttonStyles = (props) => {
  const { idx, color, disabled, fullSize, className, responsive } = props;

  const defaultClass = `
  text-sm 
  font-semibold 
  py-2 
  px-4 
  rounded 
  focus:outline-none 
  focus:ring-1
  `;

  let bgColorClass = '',
    textColorClass = '';

  if (idx === 'fill' || !idx) {
    if (disabled) {
      bgColorClass = ` text-white `;
      textColorClass = ` bg-gray-300 cursor-not-allowed `;
    } else {
      textColorClass = ` text-white `;
      bgColorClass = ` bg-${color || 'blue'}-600 hover:bg-${
        color || 'blue'
      }-500 focus:ring-${color || 'blue'}-600 `;
    }
  } else if (idx === 'text') {
    if (disabled) {
      textColorClass = ` text-gray-400 `;
      bgColorClass = ` bg-white cursor-not-allowed `;
    } else {
      textColorClass = ` text-${color || 'blue'}-500 `;
      bgColorClass = ` hover:bg-${color || 'blue'}-50  focus:ring-${
        color || 'blue'
      }-100 `;
    }
  } else if (idx === 'outline') {
    if (disabled) {
      textColorClass = ` text-gray-400 `;
      bgColorClass = ` bg-white cursor-not-allowed `;
    } else {
      textColorClass = ` text-${color || 'blue'}-500 `;
      bgColorClass = ` bg-white rounded border border-${
        color || 'blue'
      }-500 hover:bg-${color || 'blue'}-50 focus:ring-${color || 'blue'}-100 `;
    }
  }

  const fullSizeClass = fullSize && ` w-full `;
  const responsiveClass =
    responsive && ` w-full inline-flex justify-center sm:w-auto `;
  // console.log('fullSize', fullSize);
  // console.log('fullSizeClass', fullSizeClass);
  const customClass = className ? ` ${className} ` : ``;
  // console.log('customClass', customClass);

  return (
    defaultClass +
    bgColorClass +
    textColorClass +
    fullSizeClass +
    responsiveClass +
    customClass
  );
};

export default function Button(props) {
  // return <ButtonStyle {...props}>{props.children}</ButtonStyle>;
  // const props2 = { ...props };
  // delete props2.className;
  // const className2 = props.className;
  // const classNames = className2 ? className2.split(' ') : [];

  // console.log('++', classNames);
  // props.map((v) => console.log('++', v));
  // console.log('++++', props);
  return (
    <button {...props} className={buttonStyles(props)}>
      {props.children}
    </button>
  );
}

// export default function Button(props) {
//   return <BaseTextButton {...props}>{props.children}</BaseTextButton>;
// }

export function IconButton(props) {
  return (
    <BaseIconButton {...props}>
      <div className="absolute top-3 left-3">{props.icon && props.icon}</div>
      {props.children}
    </BaseIconButton>
  );
}

export const TextButton = (props) => {
  return <BaseIconButton {...props}>{props.children}</BaseIconButton>;
};

export function LoadingButton(props) {
  return (
    <div className="relative">
      <Button {...props}>
        {props.children}
        <div
          className={`${
            props.fullSize && `w-full -left-0.5`
          }  absolute top-2.5`}
        >
          <BeatLoader
            color="#1f163f"
            loading={props.disabled}
            // loading={true}
            size={10}
            // css={submitLoadCss}
          />
        </div>
      </Button>
    </div>
  );
}
