import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Title3 } from '../../../components/title';
import { SnsLoginKakao, SnsLoginNaver } from '../../../components/snsLogin';

const SignInContainer = styled.div`
  ${tw`
    h-full
    items-center
    pt-10 pr-20 pb-10 pl-20
  `};
`;

export default function SnsSignUp() {
  return (
    <SignInContainer>
      <div className="mt-7">
        <Title3>또는 SNS 아이디로 간편 로그인</Title3>
      </div>
      <form>
        <div className="mt-10">
          <SnsLoginNaver
            onClick={() => console.log('네이버')}
            label="네이버로 회원가입"
          />
        </div>
        <div className="mt-8">
          <SnsLoginKakao
            onClick={() => console.log('카카오')}
            label="카카오로 회원가입"
          />
        </div>
      </form>
    </SignInContainer>
  );
}
