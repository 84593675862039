import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { useSelector, useDispatch } from 'react-redux';
import { postsShow, postsSelector, clearState } from '../Index/postsSlice';
import moment from 'moment';
import nl2br from 'react-nl2br';
import Loading from '../../../components/loading';
import {
  TextSm,
  Title2Xl,
  TitleBase,
  TitleSm,
  TitleXl
} from '../../../components/title';

const ContentContiner = styled.div`
  ${tw`
    h-full
    items-center
    ml-66
    py-5 
    px-10
    rounded
    border
    border-gray-300
    bg-white
  `};
`;

const Td = styled.td`
  ${tw`
    relative
    py-4 px-10
    text-base
    text-gray-800
    border-b
    border-gray-200
    w-3/4
  `}
  ${({ header }) =>
    header && tw`w-1/4 p-0 text-center bg-gray-100 font-semibold`}
  ${({ start }) => start && tw`border-0`}
`;

export default function Content(props) {
  // page
  const postId = props.match.params.id;

  // redux
  const dispatch = useDispatch();
  const { showStatus, showMessage, showData } = useSelector(postsSelector);

  // page data
  const [pageLoading, setPageLoading] = useState(true);
  useEffect(() => {
    setPageLoading(true);
    dispatch(postsShow({ postId: postId }));
    return () => {
      //
    };
  }, [postId]);

  useEffect(() => {
    // console.log('showMessage2', showMessage);
    if (showStatus === 'completed') {
      // 로그인 자료 없으면 로그인으로 보낸다. (아무나)
      if (!showMessage && showMessage !== 'success') {
        // alert(indexMessage);
        // goLogin(history);
      } else {
        // setTotalPage(Math.ceil(rData.totalCount / rData.limitCount));
        setPageLoading(false);
      }
    }
    return () => {
      //
    };
  }, [showStatus]);

  return pageLoading ? (
    <ContentContiner>
      <Loading />
    </ContentContiner>
  ) : (
    <ContentContiner>
      <div className="flex py-4 relative">
        <TitleXl>{showData.subject}</TitleXl>
      </div>
      <div className="flex py-2 border-b relative">
        <TitleSm>{moment(showData.createdAt).format('YYYY.MM.DD')}</TitleSm>
      </div>
      <div className="mt-5">{nl2br(showData.content)}</div>
    </ContentContiner>
  );
}
