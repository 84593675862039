import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { setSession, getToken } from '../../../utils/session';

// index
export const educationIndex = createAsyncThunk(
  '/my-partners/education/index',
  (reqData, thunkAPI) => {
    return axios
      .get('/my-partners/education', {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        console.log('response.data', response.data);
        console.log('response.data.data.user', response.data.data?.user);
        if (response.data.success && response.data.error !== 'auth') {
          setSession(response.data.data?.user);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

// edit
export const educationEdit = createAsyncThunk(
  '/my-partners/education/edit',
  ({ educationId }, thunkAPI) => {
    const url = educationId
      ? `/my-partners/education/${educationId}/edit`
      : `/my-partners/education/create`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        console.log('response.data', response.data);
        console.log('response.data.data.user', response.data.data?.user);
        if (response.data.success && response.data.error !== 'auth') {
          setSession(response.data.data?.user);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

// store
export const educationSubmit = createAsyncThunk(
  '/my-partners/education/store',
  ({ educationId, reqData }, thunkAPI) => {
    console.log('submit educationId', educationId);
    const url = educationId
      ? `/my-partners/education/${educationId}`
      : `/my-partners/education`;
    const formData = {
      name: reqData.name,
      experience: reqData.experience,
      fieldCode: reqData.fieldCode
    };
    console.log('getToken()', getToken());
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        // 'content-type': 'multipart/form-data'
        'Content-Type': 'application/json'
      }
    };
    return axios
      .post(url, formData, config)
      .then((response) => {
        console.log('response.data', response.data);
        setSession(response.data.data.user);
        if (!response.data.success && response.data.error === 'auth') {
          // 시간만료등....
          alert(response.data.message);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const educationSlice = createSlice({
  name: 'myPartnersEducation',
  initialState: {
    indexStatus: '',
    indexResult: {},
    indexData: {},
    editStatus: '',
    editResult: {},
    editData: {},
    submitStatus: '',
    submitResult: {}
  },
  reducers: {
    clearState: (state) => {
      state.indexStatus = '';
      state.indexResult = {};
      state.indexData = {};
      state.editStatus = '';
      state.editResult = {};
      state.editData = {};
      state.submitStatus = '';
      state.submitResult = {};
      return state;
    }
  },
  extraReducers: {
    [educationIndex.pending]: (state) => {
      state.indexStatus = 'loading';
    },
    [educationIndex.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.indexStatus = 'completed';
      state.indexResult = {
        success: payload?.success,
        message: payload?.message,
        error: payload?.error
      };
      state.indexData = payload?.data?.pageData;
    },
    [educationIndex.rejected]: (state, { payload }) => {
      state.indexStatus = 'error';
    },
    [educationEdit.pending]: (state) => {
      state.editStatus = 'loading';
    },
    [educationEdit.fulfilled]: (state, { payload }) => {
      console.log('payload edit', payload);
      state.editStatus = 'completed';
      state.editResult = {
        success: payload?.success,
        message: payload?.message,
        error: payload?.error
      };
      state.editData = payload?.data?.pageData;
    },
    [educationEdit.rejected]: (state, { payload }) => {
      state.editStatus = 'error';
    },
    [educationSubmit.pending]: (state) => {
      state.submitStatus = 'loading';
    },
    [educationSubmit.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.submitStatus = 'completed';
      state.submitResult = {
        success: payload?.success,
        message: payload?.message,
        error: payload?.error
      };
    },
    [educationSubmit.rejected]: (state, { payload }) => {
      state.submitStatus = 'error';
    }
  }
});

export const { clearState } = educationSlice.actions;
export const educationSelector = (state) => state.myPartnersEducation;
export default educationSlice.reducer;
