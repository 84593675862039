import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  MenuList,
  MenuItem,
  Paper,
  Popper,
  Grow,
  ClickAwayListener,
  Divider
} from '@material-ui/core';
import { ArrowDownIcon, PersonIcon } from '../icon';
import { getSession } from '../../utils/session';
import { userTypeName } from '../../utils/codeView';

export default function MyMenu(props) {
  // myMenu
  const [myMenuOpen, setMyMenuOpen] = React.useState(false);
  const myMenuAnchorRef = React.useRef(null);

  const handleMyMenuToggle = () => {
    setMyMenuOpen((prevOpen) => !prevOpen);
  };

  const handleMyMenuClose = (event) => {
    if (
      myMenuAnchorRef.current &&
      myMenuAnchorRef.current.contains(event.target)
    ) {
      return;
    }

    setMyMenuOpen(false);
  };

  function handleMyMenuListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setMyMenuOpen(false);
    }
  }

  const prevMyMenuOpen = React.useRef(myMenuOpen);
  React.useEffect(() => {
    if (prevMyMenuOpen.current === true && myMenuOpen === false) {
      myMenuAnchorRef.current.focus();
    }
    prevMyMenuOpen.current = myMenuOpen;
  }, [myMenuOpen]);

  return (
    <>
      <button
        // variant="outlined"
        // color="default"
        // size="small"
        // className={classes.toolbarButton}
        className="bg-gray-100 relative pl-1 pr-7 rounded text-gray-900  hover:text-gray-600"
        ref={myMenuAnchorRef}
        aria-controls={myMenuOpen ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleMyMenuToggle}
      >
        <PersonIcon />
        <ArrowDownIcon className="absolute top-0 right-0" />
      </button>
      <Popper
        open={myMenuOpen}
        anchorEl={myMenuAnchorRef.current}
        role={undefined}
        transition
        disablePortal
        className="z-30"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleMyMenuClose}>
                <MenuList
                  autoFocusItem={myMenuOpen}
                  id="menu-list-grow"
                  onKeyDown={handleMyMenuListKeyDown}
                  className="rounded border border-gray-300"
                >
                  <MenuItem
                    onClick={handleMyMenuClose}
                    component={RouterLink}
                    to="/my-page"
                    className="py-3 text-xs"
                  >
                    <sapn className="text-sm font-semibold">마이페이지</sapn>
                  </MenuItem>
                  {/* <MenuItem
                    onClick={handleMyMenuClose}
                    component={RouterLink}
                    to="/my-page"
                    className="py-3 text-xs"
                  >
                    <sapn className="text-sm font-semibold">
                      abc: {getSession('type')}
                    </sapn>
                  </MenuItem> */}
                  {getSession('type') === 'PARTNER' && (
                    <MenuItem
                      onClick={handleMyMenuClose}
                      component={RouterLink}
                      to="/my-partners"
                    >
                      <sapn className="text-sm font-semibold">
                        파트너스 정보
                      </sapn>
                    </MenuItem>
                  )}
                  {getSession('type') === 'PARTNER' && (
                    <MenuItem
                      onClick={handleMyMenuClose}
                      component={RouterLink}
                      to="/my-partners/goods"
                    >
                      <sapn className="text-sm font-semibold">
                        파트너스 상품관리
                      </sapn>
                    </MenuItem>
                  )}
                  {getSession('type') === 'CLIENT' && (
                    <MenuItem
                      onClick={handleMyMenuClose}
                      component={RouterLink}
                      to="/my-clients"
                    >
                      <sapn className="text-sm font-semibold">
                        클라이언트 정보
                      </sapn>
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={handleMyMenuClose}
                    component={RouterLink}
                    to="/my-clients"
                  >
                    <sapn className="text-sm font-semibold">프로젝트 관리</sapn>
                  </MenuItem>
                  {/* <MenuItem
                    onClick={handleMyMenuClose}
                    component={RouterLink}
                    to="/posts"
                  >
                    <sapn className="text-sm font-semibold">알림설정</sapn>
                  </MenuItem> */}
                  <Divider />
                  <MenuItem
                    onClick={handleMyMenuClose}
                    component={RouterLink}
                    to="/my-page/user-type-change"
                  >
                    <sapn className="text-sm font-semibold">
                      {userTypeName(
                        getSession('type') === 'PARTNER' ? 'CLIENT' : 'PARTNER'
                      )}
                      로 전환
                    </sapn>
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={handleMyMenuClose}
                    component={RouterLink}
                    to="/sign-out"
                  >
                    <sapn className="text-sm font-semibold">로그아웃</sapn>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
