import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';
import { setSession, getToken } from '../../../utils/session';

// index
export const goodsIndex = createAsyncThunk(
  '/goods/index',
  ({ reqData }, thunkAPI) => {
    return axios
      .get('/goods', {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        },
        params: {
          page: reqData?.page || 1
        }
      })
      .then((response) => {
        console.log('response.data', response.data);
        console.log('response.data.data.user', response.data.data?.user);
        setSession(response.data.data.user);
        if (response.data.success && response.data.error !== 'auth') {
          setSession(response.data.data?.user);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

// show
export const goodsShow = createAsyncThunk(
  '/goods/show',
  ({ goodsId }, thunkAPI) => {
    const url = `/goods/${goodsId}`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        console.log('response.data', response.data);
        console.log('response.data.data.user', response.data.data?.user);
        setSession(response.data.data.user);
        if (response.data.success && response.data.error !== 'auth') {
          setSession(response.data.data?.user);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const goodsSlice = createSlice({
  name: 'goods',
  initialState: {
    indexStatus: '',
    indexResult: {},
    indexData: {},
    showStatus: '',
    showResult: {},
    showData: {}
  },
  reducers: {
    clearState: (state) => {
      state.indexStatus = '';
      state.indexResult = {};
      state.indexData = {};
      state.showStatus = '';
      state.showResult = {};
      state.showData = {};
      return state;
    }
  },
  extraReducers: {
    [goodsIndex.pending]: (state) => {
      state.indexStatus = 'loading';
    },
    [goodsIndex.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.indexStatus = 'completed';
      state.indexResult = {
        success: payload?.success,
        message: payload?.message,
        error: payload?.error
      };
      state.indexData = payload?.data?.pageData;
    },
    [goodsIndex.rejected]: (state, { payload }) => {
      state.indexStatus = 'error';
    },
    [goodsShow.pending]: (state) => {
      state.showStatus = 'loading';
    },
    [goodsShow.fulfilled]: (state, { payload }) => {
      console.log('payload edit', payload);
      state.showStatus = 'completed';
      state.showResult = {
        success: payload?.success,
        message: payload?.message,
        error: payload?.error
      };
      state.showData = payload?.data?.pageData;
      if (payload && payload.data && payload.data.pageData) {
        state.showData.diffDeadlineDays = moment(
          payload.data.pageData.deadlineAt
        ).diff(moment(), 'days');
        state.showData.diffCreatedMinute = moment().diff(
          moment(payload.data.pageData.createdAt),
          'minutes'
        );
      }
    },
    [goodsShow.rejected]: (state, { payload }) => {
      state.showStatus = 'error';
    }
  }
});

export const { clearState } = goodsSlice.actions;
export const goodsSelector = (state) => state.goods;
export default goodsSlice.reducer;
