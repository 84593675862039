import { sprintf } from 'sprintf-js';

export const goodsPrice = (row) => {
  // 고정: FIXED / 범위: RANGE / 협의: NEGOTIABLE
  if (row.priceOption === 'FIXED') {
    return <>{basePrice(row.price)} 원</>;
  } else if (row.priceOption === 'RANGE') {
    if (row.minPrice === 0) {
      return <>~ {basePrice(row.maxPrice)} 원</>;
    } else if (row.maxPrice === 0) {
      return <>{basePrice(row.minPrice)} 원 ~</>;
    } else {
      return (
        <>
          {basePrice(row.minPrice)} ~ {basePrice(row.maxPrice)} 원
        </>
      );
    }
  } else if (row.priceOption === 'NEGOTIABLE') {
    return <>협의</>;
  }
  return <></>;
};

export const basePrice = (v) => {
  return `${new Intl.NumberFormat('ko-KR').format(sprintf('%d', v))} 원`;
};
