import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { getSession } from '../../../utils/session';
import { useSelector, useDispatch } from 'react-redux';
import { projectsSelector, clearState } from '../Index/projectsSlice';

import Navbar from '../../../components/navbar';
import HelpMenu from './helpMenu';

import topImage from '../../../../assets/images/top.png';
import Content from './content';
import goLogin from '../../../utils/goLogin';

const PageContiner = styled.div`
  ${tw`
    flex
    flex-col
    w-full
    h-full
    items-center
    overflow-x-hidden
  `}
`;

const TopImageContainer = styled.div`
  min-height: 81px;
  background-color: #e3f8f2;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  ${tw`
    w-full
    // items-center  
    justify-items-center
    
  `}
  img {
    margin: auto;
  }
`;

const ContentsContiner = styled.div`
  ${tw`
    // bg-red-50
    relative
    w-full
    max-w-screen-lg
    items-start
    py-6 px-6 
    `};
`;

export default function Index(props) {
  // auth
  const history = useHistory();

  // redux
  const dispatch = useDispatch();
  const { createStatus, createResult, createData } =
    useSelector(projectsSelector);
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    dispatch(clearState());
    if (!getSession('login')) {
      goLogin(history);
    } else if (getSession('isExpires')) {
      alert('세션이 만료 되었습니다');
      goLogin(history);
    } else {
      setPageLoading(false);
    }
  }, []);

  return (
    <PageContiner>
      <Navbar />
      <TopImageContainer>
        <img src={topImage} />
      </TopImageContainer>
      <ContentsContiner>
        {/* <HelpMenu
        // verified={indexData && indexData.verified}
        // active="information"
        /> */}
        <Content />
      </ContentsContiner>
    </PageContiner>
  );
}
