import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import Button from '../../../components/button';
import Navbar from '../../../components/navbar';
// import { LockClosedIcon } from '@heroicons/react/solid';
import topImage from '../../../../assets/images/top.png';
import Content from './content';
import SnsSignUp from './snsSignUp';
import { TitleLg, TitleXl } from '../../../components/title';
import {
  EmailSignUp,
  SnsLoginKakao,
  SnsLoginNaver
} from '../../../components/snsLogin';
import { Link } from 'react-router-dom';

const PageContiner = styled.div`
  ${tw`
    flex
    flex-col
    w-full
    h-full
    items-center
    overflow-x-hidden
  `}
`;

const TopImageContainer = styled.div`
  min-height: 81px;
  background-color: #e3f8f2;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  ${tw`
    w-full
    // items-center  
    justify-items-center
    
  `}
  img {
    margin: auto;
    /* width: auto; */
    /* height: 100%; */
  }
`;

// const ContentsContiner = styled.div`
//   ${tw`
//     w-full
//     max-w-screen-xl
//     items-start
//     grid
//     grid-cols-2
//     grid-rows-none
//     divide-x
//     divide-gray-300
//     pt-6
//     pl-6
//     pr-6
//     pb-6
//   `};
// `;

const ContentsContiner = styled.div`
  ${tw`
    w-full
    // flex
    max-w-screen-lg
    items-center
    px-80 py-6
  `};
`;

export default function SignUp() {
  return (
    <PageContiner>
      <Navbar />
      <TopImageContainer>
        <img src={topImage} />
      </TopImageContainer>
      <ContentsContiner>
        <div className="mt-7">
          <TitleXl>SNS 아이디로 간편 회원가입</TitleXl>
        </div>
        <form>
          <div className="mt-10">
            <SnsLoginNaver
              onClick={() => console.log('네이버')}
              label="네이버로 회원가입"
            />
          </div>
          <div className="mt-8">
            <SnsLoginKakao
              onClick={() => console.log('카카오')}
              label="카카오로 회원가입"
            />
          </div>
        </form>
        <div className="mt-16">
          <TitleLg>또는 이메일 인증으로 회원가입</TitleLg>
        </div>
        <div className="mt-8">
          <Link to="/sign-up/email">
            <EmailSignUp label="이메일인증으로 회원가입" />
          </Link>
        </div>
      </ContentsContiner>
    </PageContiner>
  );
}
