import React from 'react';
import Select from '../select';

export const YearSelect = (props) => {
  const getOptions = () => {
    const result = [];
    const startY = props.startY || 1900;
    const nowDate = new Date();
    const endY = props.endY || nowDate.getFullYear();
    for (let i = startY; i <= endY; i++) {
      result.push(
        // <option key={i} value={i} selected={i === props.defaultValue}>
        <option key={i} value={i} selected={i === props.defaultValue}>
          {i}
        </option>
      );
    }
    return result;
  };

  return (
    <>
      <Select {...props}>
        {props.placeholder && (
          // <option value="" disabled selected>
          <option value="">{props.placeholder}</option>
        )}
        {getOptions()}
      </Select>
    </>
  );
};

export default YearSelect;
