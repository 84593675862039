import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import Button from '../../components/button';
import Navbar from '../../components/navbar';
// import { LockClosedIcon } from '@heroicons/react/solid';

const PageContiner = styled.div`
  ${tw`
    flex
    flex-col
    w-full
    h-full
    items-center
    overflow-x-hidden
  `}
`;

export default function HomePage() {
  return (
    <PageContiner>
      <Navbar />

      {/* <Button>로그인</Button> */}
    </PageContiner>
  );
}
