import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import moment from 'moment';
import queryString from 'query-string';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { projectsIndex, projectsSelector, clearState } from './projectsSlice';
import {
  fieldName2,
  techLevelName,
  experienceName
} from '../../../utils/codeView';
import Loading from '../../../components/loading';

import {
  CheckIcon,
  CommentIcon,
  FavoriteIcon,
  NewIcon
} from '../../../components/icon';
import { TextSm, TitleSm, TitleXl } from '../../../components/title';

import RecruitmentBadge from './recruitmentBadge';
import WorkTypeBadge from './workTypeBadge';
import FieldsView from './fieldsView';
import NewBadge from './newBadge';
import Pagination from '../../../components/pagination';
import FavoriteBotton from '../../../components/favoriteButton';
import { basePrice } from '../../../utils/priceFormat';
import SearchMenu from './searchMenu';
import goLogin from '../../../utils/goLogin';

const ContentContainer = styled.div`
  ${tw` h-full items-center `};
`;

const SortContainer = styled.div`
  ${tw` bg-gray-100 border-b border-gray-300 mt-8 pl-5 hidden sm:flex `}
`;

const SortItem = (props) => {
  const textColor = props.active ? 'text-blue-800' : 'text-gray-700';
  return (
    <div className={`text-gray-700 px-2 py-2 text-sm relative ${textColor}`}>
      <CheckIcon className="absolute" />
      <TextSm className="pl-6">{props.children}</TextSm>
    </div>
  );
};

export default function Content(props) {
  // query-string
  const history = useHistory();
  const { search } = useLocation();
  const query = queryString.parse(search);

  // auth
  // const history = useHistory();
  if (!query?.page) {
    history.replace(history.location.pathname + '?page=1');
  }

  // redux
  const dispatch = useDispatch();
  const { indexStatus, indexResult, indexData } = useSelector(projectsSelector);

  // page data
  const [pageLoading, setPageLoading] = useState(true);
  const [rowsLoading, setRowsLoading] = useState(true);
  // const [limitCount, setLimitCount] = React.useState(3);
  // const [page, setPage] = React.useState();
  console.log('>> query.page', query.page);

  // if (query && query.page && query.page !== page) {
  //   setPage(query.page);
  // }

  // console.log('----> page', page);

  useEffect(() => {
    console.log('xxx useEffect page', query.page);
    // console.log('sword', query.sword);
    // console.log('fields', query.fields);
    // console.log('workTypes', query.workTypes);
    // dispatch(projectsIndex({ page: query.page }));
    dispatch(projectsIndex({ reqData: query }));
    return () => {
      setRowsLoading(true);
    };
  }, [query.page, query.sword, query.fields, query.workTypes]);

  useEffect(() => {
    if (indexStatus === 'completed') {
      if (!indexResult.success) {
        alert(indexResult.message);
        // if (indexResult.error === 'auth') {
        //   goLogin(history);
        // }
      } else {
        setPageLoading(false);
        setRowsLoading(false);
      }
    }
    return () => {
      //
    };
  }, [indexStatus]);

  return pageLoading ? (
    <ContentContainer>
      <Loading />
    </ContentContainer>
  ) : (
    <ContentContainer>
      <div className="flex mt-4 mb-0 rounded relative">
        <div className="font-medium text-xl">프로젝트</div>
      </div>
      <SearchMenu query={query} />
      <SortContainer>
        <SortItem active>기본정렬</SortItem>
        <SortItem>최신 등록순</SortItem>
        <SortItem>마감 임박순</SortItem>
        <SortItem>금액 높은순</SortItem>
        <SortItem>금액 낮은순</SortItem>
      </SortContainer>
      <div>
        {rowsLoading ? (
          <Loading />
        ) : (
          indexData?.rows &&
          indexData.rows.map((row) => {
            const diffDeadlineDays = moment(row.deadlineAt).diff(
              moment(),
              'days'
            );
            const diffCreatedMinute = moment().diff(
              moment(row.createdAt),
              'minutes'
            );
            return (
              <div className="py-5 px-2 border-b border-gray-300" key={row.id}>
                <div className="flex pt-1 items-center">
                  <div className="flex-grow">
                    <Link
                      to={`/projects/${row.id}`}
                      className="text-xl font-medium"
                    >
                      {row.subject}
                    </Link>
                  </div>
                  <NewBadge diffCreatedMinute={diffCreatedMinute} />
                  <WorkTypeBadge code={row.workType} />
                  <RecruitmentBadge diffDeadlineDays={diffDeadlineDays} />
                  <div className="py-1 text-xs mr-2">
                    {moment(row.createdAt).format('YYYY.MM.DD')}
                  </div>
                  <FavoriteBotton
                    checked={row.favorite}
                    cnt={row.favoriteCnt}
                    favType="project"
                    favId={row.id}
                  />
                </div>
                <div className="flex pt-3">
                  <div className="flex flex-grow">
                    <FieldsView fields={row.fields} />
                  </div>
                  {/* <div className="flex">
                    <NewBadge diffCreatedMinute={diffCreatedMinute} />
                    <WorkTypeBadge code={row.workType} />
                    <RecruitmentBadge diffDeadlineDays={diffDeadlineDays} />
                  </div> */}

                  {/* <div className="relative">
                    <CommentIcon className="absolute top-0 left-0" />
                    <TextSm className="pl-6 pt-0">999</TextSm>
                  </div>
                  <div className="relative ml-4">
                    <FavoriteIcon className="absolute top-0 left-0" />
                    <TextSm className="pl-6">999</TextSm>
                  </div> */}
                </div>
                <div className="grid grid-cols-4 pt-6">
                  <div className="border-l-2 border-gray-600 pl-5 text-base text-gray-800 font-semibold">
                    예상금액: {basePrice(row.amount)}
                  </div>
                  <div className="border-l-2 border-gray-600 pl-5 text-base text-gray-800 font-semibold">
                    예상기간일: {row.period} 일
                  </div>
                  <div className="border-l-2 border-gray-600 pl-5 text-base text-gray-800 font-semibold">
                    지원자: 0 명
                  </div>
                  <div className="border-l-2 border-gray-600 pl-5 text-base text-gray-800 font-semibold">
                    {diffDeadlineDays >= 0
                      ? `마감: ${diffDeadlineDays} 일 전`
                      : '모집마감'}
                  </div>
                </div>
                <div className="flex pt-6">
                  <div className="w-3/4 text-sm text-gray-500 pr-8">
                    {row.workContents}
                  </div>
                  <div className="w-1/4 border border-gray-300 h-full rounded">
                    <div className="flex py-4 px-4">
                      <div className="w-12 h-12 flex">
                        <img
                          className="m-auto rounded-xl"
                          src={`${process.env.REACT_APP_IMG_URL}/${row.client.image}`}
                          alt="client"
                        />
                      </div>
                      <div className="flex-grow ml-3 mt-1">
                        <p className="text-xs">
                          {row.client.name} ({row.client.companyTypeCode})
                        </p>
                        <p className="text-xs pt-1">
                          {row.client.addressState} &gt;{' '}
                          {row.client.addressCity}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
      <Pagination
        page={indexData.page}
        totalPage={indexData.totalPage}
        query={query}
      />
    </ContentContainer>
  );
}
