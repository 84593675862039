import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import tw from 'twin.macro';
import { slide as Menu } from 'react-burger-menu';
import { useMediaQuery } from 'react-responsive';
import { SCREENS } from '../responsive';
import menuStyles from './menuStyles';

import { getSession } from '../../utils/session';
import MyMenu from './myMenu';

const ListContainer = styled.div`
  ${tw` flex `}
`;

const NavItem = styled.div`
  ${tw`
    text-sm
    md:text-base
    // mr-1
    md:ml-5
    cursor-pointer
    transition
    duration-300
    ease-in-out
    hover:text-gray-700
  `};

  ${({ menu }) =>
    menu &&
    css`
      ${tw`
      text-white
      text-xl
      mb-3
      focus:text-white
    `};
    `};
`;

const NavItemLink = styled(Link)`
  ${tw`
    block
    align-baseline
    font-medium
    text-base
    px-2
    text-gray-900
    hover:text-blue-800
  `}
  ${({ yellow }) =>
    yellow &&
    tw`
    py-0.5 
    px-2
    rounded
    font-medium
    text-base
    text-gray-200
    bg-yellow-600  
    hover:text-gray-200
    hover:bg-yellow-700
  `};
`;

export default function NavItems() {
  const isMobile = useMediaQuery({ maxWidth: SCREENS.sm });

  if (isMobile)
    return (
      <Menu right styles={menuStyles}>
        <ListContainer>
          <NavItem menu>
            <a href="#">Home</a>
          </NavItem>
          <NavItem menu>
            <a href="#">Cars</a>
          </NavItem>
          <NavItem menu>
            <a href="#">Services</a>
          </NavItem>
          <NavItem menu>
            <a href="#">Contact Us</a>
          </NavItem>
        </ListContainer>
      </Menu>
    );

  return (
    <ListContainer>
      {/* <NavItem>
        <NavItemLink to="/posts">바이오 관련정보</NavItemLink>
      </NavItem> */}
      {/* <NavItem>
        <NavItemLink to="/posts">이용안내</NavItemLink>
      </NavItem> */}
      {/* <NavItem>
        <NavItemLink to="/projects">프로젝트 찾기</NavItemLink>
      </NavItem>
      <NavItem>
        <NavItemLink to="/partners">파트너스 찾기</NavItemLink>
      </NavItem>
      <NavItem>
        <NavItemLink to="/goods">파트너스 상품몰</NavItemLink>
      </NavItem> */}
      {/* <div className="flex-grow"></div> */}
      <NavItem>
        <NavItemLink to="/projects/create" yellow>
          무료프로젝트 등록
        </NavItemLink>
      </NavItem>
      {!getSession('login') ? (
        <>
          <NavItem>
            <NavItemLink to="/sign-in">로그인</NavItemLink>
          </NavItem>
          <NavItem>
            <NavItemLink to="/sign-up">회원가입</NavItemLink>
          </NavItem>
        </>
      ) : (
        <NavItem>
          <MyMenu />
        </NavItem>
      )}
    </ListContainer>
  );
}
