import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import tw from 'twin.macro';
import Button from '../../../components/button';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CloseIcon
} from '../../../components/icon';
import Input, { InputSearch } from '../../../components/input';
import queryString from 'query-string';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { projectsIndex, projectsSelector, clearState } from './projectsSlice';
import { fieldName2 } from '../../../utils/codeView';
import FieldSelect from '../../../components/fieldSelect';
import AddressSelect from '../../../components/addressSelect';
import Label from '../../../components/label';
import objectToQueryString from '../../../utils/objectToQueryString';

const SearchMenuContainer = styled.div`
  ${tw` w-full border border-gray-500 rounded-lg `};
`;

export default function SearchMenu(props) {
  const { query } = props;

  const history = useHistory();
  // 상세검색
  const [isDetail, setIsDetail] = useState(false);

  const handleDetail = () => {
    return setIsDetail(!isDetail);
  };

  // redux
  const dispatch = useDispatch();
  const { indexStatus, indexResult, indexData } = useSelector(projectsSelector);

  // work types  (in work)
  const [workTypes, setWorkTypes] = React.useState([]);
  const handleWorkTypesChange = (e) => {
    const code = e.target.value;
    if (e.target.checked) {
      if (!workTypes.includes(code)) {
        const arr = [...workTypes];
        arr.push(code);
        setValue('workTypes', arr.join(','), {
          shouldValidate: true,
          shouldDirty: true
        });
        setWorkTypes([...workTypes, code]);
      }
    } else {
      const arr = [...workTypes.filter((v) => v !== code)];
      setValue('workTypes', arr.join(','), {
        shouldValidate: true,
        shouldDirty: true
      });
      setWorkTypes([...workTypes.filter((v) => v !== code)]);
    }
  };

  // field
  //const [fieldCode, setFieldCode] = useState('');
  const [fields, setFields] = useState([]);
  // const fieldView0 = (code, i) => {
  //   return (
  //     <div className={i === 0 ? 'flex' : 'flex mt-1'}>
  //       {fieldName2(code)}
  //       <span
  //         className="mt-1 ml-3 cursor-pointer"
  //         onClick={(e) => handleFieldRemove(e, code)}
  //       >
  //         <CloseIcon />
  //       </span>
  //     </div>
  //   );
  // };
  const fieldsView = (fields) => {
    return fields.length > 0 && fields.map((d, i) => fieldView(d, i));
  };

  const handleFieldCheck = (e) => {
    if (!e.target.checked) {
      const arr = [...fields.filter((v) => v !== e.target.value)];
      setValue('fields', arr.join(','), {
        shouldValidate: true,
        shouldDirty: true
      });
      setFields([...fields.filter((v) => v !== e.target.value)]);
    }
  };

  const fieldView = (code, i) => {
    return (
      <span className={i !== -1 && 'ml-4'} key={i}>
        <input
          type="checkbox"
          name={`work_types_${i}`}
          color="primary"
          value={code}
          checked={fields.includes(code)}
          onChange={handleFieldCheck}
        />
        <Label className="pl-2">{fieldName2(code)}</Label>
      </span>
    );
  };

  const fieldSelected = (code) => {
    // console.log('code code code', code);
    // console.log('fields fields fields', fields);
    if (!fields || !fields.includes(code)) {
      const arr = [...fields];
      arr.push(code);
      setValue('fields', arr.join(','), {
        shouldValidate: true,
        shouldDirty: true
      });
      setFields([...fields, code]);
    }

    // console.log('fields selected', fields.join(','));
  };

  // const handleFieldRemove = (e, code) => {
  //   const arr = [...fields.filter((v) => v !== code)];
  //   setValue('fields', arr.join(','), {
  //     shouldValidate: true,
  //     shouldDirty: true
  //   });
  //   setFields([...fields.filter((v) => v !== code)]);

  //   // setValue('fields', fields.join(','), {
  //   //   shouldValidate: true,
  //   //   shouldDirty: true
  //   // });
  //   // console.log('fields remove', fields.join(','));
  //   // // setFormData({ ...formData, fields: fields.join(',') });
  // };

  // meeting_locations
  const [meetingLocations, setMeetingLocations] = React.useState([]);
  const meetingLocationAdd = (code) => {
    // console.log('존재하지 않으면 추가시킨다.', code);
    if (!meetingLocations.includes(code)) {
      const arr = [...meetingLocations];
      arr.push(code);
      setValue('meetingLocations', arr.join(','), {
        shouldValidate: true,
        shouldDirty: true
      });
      setValue('meetingOnline', getValues('meetingOnline'), {
        shouldValidate: true
      });
      setMeetingLocations([...meetingLocations, code]);
      // setFormData({ ...formData, fields: fields.join(',') });
    }
  };
  const handleMeetingLocationCheck = (e) => {
    if (!e.target.checked) {
      const arr = [...meetingLocations.filter((v) => v !== e.target.value)];
      setValue('meetingLocations', arr.join(','), {
        shouldValidate: true,
        shouldDirty: true
      });
      // setValue('meetingOnline', getValues('meetingOnline'), {
      //   shouldValidate: true
      // });
      setMeetingLocations([
        ...meetingLocations.filter((v) => v !== e.target.value)
      ]);
      // setFormData({ ...formData, fields: fields.join(',') });
    }
  };
  const meetingLocationView = (v, i) => {
    return (
      <span className={i !== -1 && 'ml-4'} key={i}>
        <input
          type="checkbox"
          name={`meeting_locations_${i}`}
          color="primary"
          value={v}
          checked={meetingLocations.includes(v)}
          onChange={handleMeetingLocationCheck}
        />
        <Label className="pl-2">{v}</Label>
      </span>
    );
  };

  // form
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    getValues,
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    console.log('+++ data', data);
    console.log('+++ query.page', query.page);
    // data.page = 1;
    const newQuery = {};
    newQuery.page = 1;

    for (var key in data) {
      if (data[key]) newQuery[key] = data[key];
    }
    // str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    history.replace(
      history.location.pathname + '?' + objectToQueryString(newQuery)
    );
    // history.replace(
    //   history.location.pathname +
    //     '?' +
    //     objectToQueryString({ ...data, page: 1 })
    // );
    // history.replace(
    //   history.location.pathname + '?' + objectToQueryString(query)
    // );
    // dispatch(projectsIndex({ reqData: data }));
  };

  useEffect(() => {
    console.log('q q query', query);
    const resetData = {};
    if (query.sword) {
      resetData.sword = query.sword;
      console.log('q q sword', query.sword);
    }
    if (query.workTypes) {
      resetData.workTypes = query.workTypes;
      console.log('q q workTypes', query.workTypes);
      setWorkTypes(query.workTypes.split(','));
    }
    if (query.fields) {
      resetData.fields = query.fields;
      setFields(query.fields.split(','));
    }
    reset({ ...resetData });
    if (query.workTypes || query.fields) setIsDetail(true);
    return () => {
      reset();
    };
  }, [query.sword, query.workTypes, query.fields]);

  return (
    <>
      <div className="flex w-full flex-row-reverse">
        <div
          className="relative pr-6 text-base text-blue-600 cursor-pointer"
          onClick={handleDetail}
        >
          <div className="text-base">상세검색</div>
          {isDetail ? (
            <ArrowUpIcon className="absolute -top-0.5 right-0" />
          ) : (
            <ArrowDownIcon className="absolute -top-0.5 right-0" />
          )}
        </div>
      </div>
      <SearchMenuContainer>
        <form id="form" onSubmit={handleSubmit(onSubmit)}>
          <div className="py-4 px-8">
            <div className="flex w-full items-center">
              <div className="w-1/5 text-sm">검색어</div>
              <div className="flex-grow">
                <Controller
                  render={({ field, fieldState }) => (
                    <InputSearch
                      id="sword"
                      value={field.value}
                      onChange={field.onChange}
                      inputRef={field.ref} // wire up the input ref
                      placeholder="프로젝트에서 검색..."
                      error={fieldState.error}
                      errorMessage={errors.sword && errors.sword.message}
                    />
                  )}
                  name="sword"
                  control={control}
                  // rules={{ required: '파트너스 구분을 선택해 주세요' }}
                />
              </div>
            </div>
            {isDetail && (
              <>
                <div className="flex w-full items-center mt-5 ">
                  <div className="w-1/5 text-sm">상주 / 외주</div>
                  <div className="flex-grow">
                    <span>
                      <input
                        type="checkbox"
                        id="work_type_in"
                        value="IN"
                        checked={workTypes.includes('IN')}
                        onChange={handleWorkTypesChange}
                      />
                      <Label htmlFor="work_type_in" className="pl-2">
                        상주
                      </Label>
                    </span>
                    <span className="ml-3">
                      <input
                        type="checkbox"
                        id="work_type_out"
                        value="OUT"
                        checked={workTypes.includes('OUT')}
                        onChange={handleWorkTypesChange}
                      />
                      <Label htmlFor="work_type_out" className="pl-2">
                        외주(도급)
                      </Label>
                    </span>
                  </div>
                  <input type="hidden" {...register('workTypes')} />
                </div>
                <div className="flex w-full items-center mt-4">
                  <div className="w-1/5 text-sm">전문분야</div>
                  <div className="flex-grow">
                    <FieldSelect
                      id="fields"
                      label="분야선택"
                      selected={fieldSelected}
                    />
                    {fieldsView(fields)}
                    <input type="hidden" {...register('fields')} />
                  </div>
                </div>
                {/* <div className="flex w-full items-center mt-3">
                  <div className="w-1/5 text-sm">프로젝트 지역</div>
                  <div className="flex-grow">
                    <AddressSelect
                      label="지역선택"
                      meetingLocationAdd={meetingLocationAdd}
                    />
                    {meetingLocations &&
                      meetingLocations.map(meetingLocationView)}
                    <input type="hidden1" {...register('meetingLocations')} />
                  </div>
                </div> */}
              </>
            )}
          </div>
          <div className="mt-3 py-4 px-8 bg-gray-100 rounded-b-lg">
            <div className="flex justify-between items-center w-full">
              <div className="font-medium text-base">
                {indexData.totalPage} 건이 검색 되었습니다.
              </div>
              <div>
                <Button type="submit" form="form">
                  검색
                </Button>
              </div>
            </div>
          </div>
        </form>
      </SearchMenuContainer>
    </>
  );
}
