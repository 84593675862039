import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { useHistory } from 'react-router-dom';
import { getSession } from '../../../utils/session';
import { useSelector, useDispatch } from 'react-redux';
import {
  technologyIndex,
  technologySelector,
  clearState
} from './technologySlice';

import Navbar from '../../../components/navbar';
import topImage from '../../../../assets/images/top.png';
import goLogin from '../../../utils/goLogin';
import Content from './content';

const PageContiner = styled.div`
  ${tw`
    flex
    flex-col
    w-full
    h-full
    items-center
    overflow-x-hidden
  `}
`;

const TopImageContainer = styled.div`
  min-height: 81px;
  background-color: #e3f8f2;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  ${tw`
    w-full
    // items-center  
    justify-items-center
    
  `}
  img {
    margin: auto;
    /* width: auto; */
    /* height: 100%; */
  }
`;

const ContentsContiner = styled.div`
  ${tw`
    // bg-red-50
    relative
    w-full
    max-w-screen-lg
    items-start
    py-6 px-6 
    `};
`;

export default function Technology() {
  // auth
  const history = useHistory();
  useEffect(() => {
    dispatch(clearState());
    // console.log('getSession(expires)', getSession('expires'));
    // console.log('JS', moment(getSession('expires')).format('YYYY-MM-DD'));
    // console.log('UT', moment(getSession('expires')).utc().format('YYYY-MM-DD'));
    // console.log('diff(moment())', moment(getSession('expires')).diff());
    // console.log('getSession(login)', getSession('login'));
    // console.log('getSession(isExpires)', getSession('isExpires'));

    if (!getSession('login')) {
      goLogin(history);
    } else if (getSession('isExpires')) {
      alert('세션이 만료 되었습니다');
      goLogin(history);
    } else {
      setPageLoading(false);
    }
  }, []);

  // redux
  const dispatch = useDispatch();
  const [pageLoading, setPageLoading] = useState(true);

  // useEffect(() => {
  //   if (indexStatus === 'completed') {
  //     console.log('menu user', indexData.verified);
  //   }
  // }, [indexStatus]);

  return pageLoading ? (
    <></>
  ) : (
    <PageContiner>
      <Navbar />
      <TopImageContainer>
        <img src={topImage} />
      </TopImageContainer>
      <ContentsContiner>
        <Content />
      </ContentsContiner>
    </PageContiner>
  );
}
