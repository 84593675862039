import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { setSession, getToken } from '../../../utils/session';

// index
export const signUpEmailCreate = createAsyncThunk(
  '/sign-up/email/create',
  (_, thunkAPI) => {
    return axios
      .get('/sign-up/email', {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          // 'content-type': 'multipart/form-data'
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        console.log('response.data', response.data);
        if (response.data.success) {
          setSession(response.data.data.user);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const signUpEmailStore = createAsyncThunk(
  '/sign-up/email/store',
  ({ reqData }, thunkAPI) => {
    const url = '/sign-up/email';
    const formData = {
      email: reqData.email
    };
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        // 'content-type': 'multipart/form-data'
        'Content-Type': 'application/json'
      }
    };
    return axios
      .post(url, formData, config)
      .then((response) => {
        console.log('response.data', response.data);
        setSession(response.data.data.user);
        if (!response.data.success && response.data.error === 'auth') {
          // 시간만료등....
          // alert(response.data.message);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const signUpEmailSlice = createSlice({
  name: 'signUpEmail',
  initialState: {
    createStatus: '',
    createMessage: '',
    createData: {},
    storeStatus: '',
    storeMessage: '',
    storeData: {}
  },
  reducers: {
    clearState: (state) => {
      state.createStatus = '';
      state.createMessage = '';
      state.createData = {};
      state.storeStatus = '';
      state.storeMessage = '';
      state.storeData = {};
      return state;
    }
  },
  extraReducers: {
    [signUpEmailCreate.pending]: (state) => {
      state.createStatus = 'loading';
    },
    [signUpEmailCreate.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.createStatus = 'completed';
      state.createMessage = payload?.message;
      state.createData = payload?.data?.pageData;
    },
    [signUpEmailCreate.rejected]: (state, { payload }) => {
      state.createStatus = 'error';
    },
    [signUpEmailStore.pending]: (state) => {
      state.storeStatus = 'loading';
    },
    [signUpEmailStore.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.storeStatus = 'completed';
      state.storeMessage = payload.message;
      state.storeData = payload?.data?.pageData;
    },
    [signUpEmailStore.rejected]: (state, { payload }) => {
      state.storeStatus = 'error';
    }
  }
});

export const { clearState } = signUpEmailSlice.actions;
export const signUpEmailSelector = (state) => state.signUpEmail;
export default signUpEmailSlice.reducer;
