import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  leaveEdit,
  leaveUpdate,
  leaveSelector,
  clearState
} from './leaveSlice';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

import Label from '../../../components/label';
import Input, { ErrorMessage } from '../../../components/input';
import Button, { IconButton } from '../../../components/button';

import { toast } from 'react-toastify';
import CodeSelect from '../../../components/codeSelect';
import leaveTypeCode from '../../../code/leaveTypes';
import Textarea from '../../../components/textarea';
import { useHistory } from 'react-router';
import { removeSession } from '../../../utils/session';

export default function Edit(props) {
  const { open, editClose, refresh } = props;
  const history = useHistory();

  // submit button
  const [submitEnable, setSubmitEnable] = useState(false);

  // redux
  const dispatch = useDispatch();
  const {
    editStatus,
    editResult,
    editData,
    updateStatus,
    updateResult,
    updateData
  } = useSelector(leaveSelector);

  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      editClose();
    }
  };

  // react-form-hook
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    getValues,
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    console.log('onSubmit data', data);
    dispatch(leaveUpdate({ reqData: data }));
  };

  // form comment visible
  const [commentVisible, setCommentVisible] = useState(false);
  const handleTypeChange = (code) => {
    setCommentVisible(code === 'L99');
  };

  // update return
  useEffect(() => {
    console.log('updateStatus 1', updateStatus);
    if (updateStatus === 'completed') {
      if (updateResult === 'success') {
        dispatch(clearState());
        toast.success('완료 되었습니다');
        refresh();
        editClose();
        sessionStorage.clear();
        console.log('SignOut', sessionStorage);
        removeSession();
        history.replace('/');
      } else {
        toast.error(updateResult);
      }
    }
    return () => {};
  }, [updateStatus]);

  const [pageLoading, setPageLoading] = useState(true);
  // page data
  useEffect(() => {
    console.log('시작 open', open);
    // fieldSelected();

    if (open) {
      setSubmitEnable(false);
      reset();
      console.log('effect ++++ ');
      setPageLoading(true);
      dispatch(leaveEdit());
    }
    return () => {
      // setPageLoading(true);
      reset();
    };
  }, [open]);

  // console.log('렌더 open', open);

  // page data status
  useEffect(() => {
    console.log('editStatus 1', editStatus);

    if (editStatus === 'completed') {
      if (editData && editData.row) {
        console.log('결과 있슴.', editData.row.companyName);
        // reset({});
      }
      setPageLoading(false);
    }
    return () => {};
  }, [editStatus]);

  // const cancelButtonRef = useRef(null);
  return (
    !pageLoading && (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
        transitionDuration={0}
        className=""
      >
        <DialogTitle id="form-dialog-title">
          <div className="font-medium text-2xl pt-3 pl-3">회원 탈퇴</div>
        </DialogTitle>
        <DialogContent>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left">
            <form id="form" onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-5">
                <Label htmlFor="leave_type">회원탈퇴 이유</Label>
                <Controller
                  render={({ field, fieldState }) => (
                    <CodeSelect
                      id="leave_type"
                      value={field.value}
                      onChange={(e) => {
                        handleTypeChange(e.target.value);
                        field.onChange(e);
                      }}
                      inputRef={field.ref} // wire up the input ref
                      placeholder="회원탈퇴 이유을 선택해 주세요"
                      error={fieldState.error}
                      errorMessage={
                        errors.leaveType && errors.leaveType.message
                      }
                      data={leaveTypeCode}
                    />
                  )}
                  name="leaveType"
                  control={control}
                  rules={{ required: '회원탈퇴 이유을 선택해 주세요' }}
                />
              </div>
              {commentVisible && (
                <div className="mt-5">
                  <Label htmlFor="comment">기타 이유에 대한 내용</Label>
                  <div className="relative">
                    <Controller
                      render={({ field, fieldState }) => (
                        <Textarea
                          id="comment"
                          value={field.value}
                          onChange={field.onChange}
                          inputRef={field.ref} // wire up the input ref
                          placeholder="기타 이유에 대한 내용을 입력해 주세요"
                          error={fieldState.error}
                          errorMessage={
                            errors.comment && errors.comment.message
                          }
                          rows={5}
                        />
                      )}
                      name="comment"
                      control={control}
                      rules={{
                        validate: (value) => {
                          console.log('LEAVE TYPE', getValues('leaveType'));
                          console.log('VALUE', value);
                          return getValues('leaveType') === 'L99' && !value
                            ? '기타 이유에 대한 내용을 입력해 주세요'
                            : true;
                        }
                      }}
                    />
                  </div>
                </div>
              )}
            </form>
          </div>
        </DialogContent>
        <DialogActions className="flex w-full p-0 bg-gray-100 mt-5">
          <div className="w-full m-0 bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse flow-root">
            <Button
              type="submit"
              form="form"
              // disabled={!submitEnable}
              className="sm:mx-1"
            >
              확인
            </Button>
            <Button
              type="button"
              idx="outline"
              onClick={handleClose}
              className="sm:mx-1"
            >
              취소
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    )
  );
}
