import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import queryString from 'query-string';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { postsIndex, postsSelector, clearState } from './postsSlice';

import Loading from '../../../components/loading';
import goLogin from '../../../utils/goLogin';
import nl2br from 'react-nl2br';
import { TitleXl } from '../../../components/title';
import Pagination from '../../../components/pagination';
import moment from 'moment';

const ContentContiner = styled.div`
  ${tw`
    h-full
    items-center
    ml-66
    py-10 px-10
    rounded
    border
    border-gray-300
    bg-white
  `};
`;

const Table = styled.table`
  ${tw`w-full`}
`;

const Thead = styled.thead`
  ${tw`border-t-2 border-b border-gray-200 bg-gray-50`}
`;

const Th = styled.th`
  ${tw`py-4 px-4 border-r border-gray-200 text-base font-semibold`}
  ${({ t1 }) => t1 && tw`w-1/12`}
  ${({ t2 }) => t2 && tw`w-2/12`}
  ${({ t3 }) => t3 && tw`w-7/12`}
  ${({ t4 }) => t4 && tw`w-2/12 border-r-0`}
`;

const Tr = styled.tr`
  ${tw`hover:bg-gray-100`}
`;

const Td = styled.td`
  ${tw`relative py-4 text-center text-base border-b border-r border-gray-200 w-4/5`}
  ${({ t1 }) => t1 && tw`w-1/12`}
  ${({ t2 }) => t2 && tw`w-2/12`}
  ${({ t3 }) => t3 && tw`w-7/12`}
  ${({ t4 }) => t4 && tw`w-2/12 border-r-0`}
`;

export default function Content(props) {
  // query-string
  const { search } = useLocation();
  const query = queryString.parse(search);

  // redux
  const dispatch = useDispatch();
  const { indexStatus, indexMessage, indexData } = useSelector(postsSelector);

  // page data
  const [pageLoading, setPageLoading] = useState(true);
  const [rowsLoading, setRowsLoading] = useState(true);
  const [page, setPage] = React.useState();

  if (query && query.page && query.page !== page) {
    setPage(query.page || 1);
  }

  useEffect(() => {
    // console.log('useEffect page', page);
    // console.log('query.page', query.page);
    dispatch(postsIndex({ page: page }));
    return () => {
      setRowsLoading(true);
    };
  }, [page]);

  useEffect(() => {
    if (indexStatus === 'completed') {
      // 로그인 자료 없으면 로그인으로 보낸다.
      if (!indexMessage && indexMessage !== 'success') {
        // alert(indexMessage);
        // goLogin(history);
      } else {
        setPageLoading(false);
        setRowsLoading(false);
      }
    }
    return () => {
      //
    };
  }, [indexStatus]);

  return pageLoading ? (
    <ContentContiner>
      <Loading />
    </ContentContiner>
  ) : (
    <ContentContiner>
      <div className="flex mb-10 rounded relative">
        <div className="flex-grow">
          <TitleXl>정보센터</TitleXl>
        </div>
      </div>
      <div>
        <Table>
          <Thead>
            <tr>
              <Th t1>No</Th>
              <Th t2>분류</Th>
              <Th t3>제목</Th>
              <Th t4>작성일</Th>
            </tr>
          </Thead>
          <tbody>
            {rowsLoading ? (
              <Loading />
            ) : (
              indexData &&
              indexData.rows &&
              indexData.rows.map((row, i) => {
                return (
                  <Tr className="">
                    <Td t1>{row.id}</Td>
                    <Td t2>{row.category}</Td>
                    <Td t3>
                      <Link to={`/posts/${row.id}`}>{row.subject}</Link>
                    </Td>
                    <Td t4>{moment(row.createdAt).format('YYYY.MM.DD')}</Td>
                  </Tr>
                );
              })
            )}
          </tbody>
        </Table>
      </div>
      <div className="flex mt-5">
        <Pagination
          page={indexData.page}
          totalPage={indexData.totalPage}
          query={query}
        />
      </div>

      <Link to="/posts/create">작성하기</Link>
    </ContentContiner>
  );
}
