import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import moment from 'moment';
import queryString from 'query-string';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { partnersIndex, partnersSelector, clearState } from './partnersSlice';
import {
  fieldName2,
  techLevelName,
  experienceName
} from '../../../utils/codeView';
import Loading from '../../../components/loading';

import {
  CheckIcon,
  CommentIcon,
  FavoriteIcon,
  NewIcon,
  StarIcon
} from '../../../components/icon';
import A from '../../../components/a';
import { TextSm, TitleSm, TitleXl } from '../../../components/title';

import RecruitmentBadge from './recruitmentBadge';
import WorkTypeBadge from './workTypeBadge';
import FieldsView from './fieldsView';
import NewBadge from './newBadge';
import Pagination from '../../../components/pagination';
import FavoriteBotton from '../../../components/favoriteButton';
import SearchMenu from './searchMenu';

const ContentContainer = styled.div`
  ${tw` h-full items-center `};
`;

const SortContainer = styled.div`
  ${tw` bg-gray-100  border-b border-gray-300 mt-8 pl-5 hidden sm:flex `}
`;

const SortItem = (props) => {
  // text-gray-700
  const textColor = props.active ? 'text-blue-800' : 'text-gray-700';
  return (
    <div className={`text-gray-700 px-2 py-2 text-sm relative ${textColor}`}>
      <CheckIcon className="absolute" />
      <TextSm className="pl-6">{props.children}</TextSm>
    </div>
  );
};

export default function Content(props) {
  // query-string
  const history = useHistory();
  const { search } = useLocation();
  const query = queryString.parse(search);

  // auth
  // const history = useHistory();
  if (!query?.page) {
    history.replace(history.location.pathname + '?page=1');
  }

  // redux
  const dispatch = useDispatch();
  const { indexStatus, indexResult, indexData } = useSelector(partnersSelector);

  // page data
  const [pageLoading, setPageLoading] = useState(true);
  const [rowsLoading, setRowsLoading] = useState(true);
  // const [limitCount, setLimitCount] = React.useState(3);
  // const [page, setPage] = React.useState();

  // console.log('query.page', query.page);

  // if (query && query.page && query.page !== page) {
  //   setPage(query.page);
  // }

  // console.log('----> page', page);

  useEffect(() => {
    console.log('useEffect page', query.page);
    dispatch(partnersIndex({ reqData: query }));
    return () => {
      setRowsLoading(true);
    };
  }, [query.page, query.sword, query.fields, query.workTypes]);

  useEffect(() => {
    if (indexStatus === 'completed') {
      if (!indexResult.success) {
        alert(indexResult.message);
        // if (indexResult.error === 'auth') {
        //   goLogin(history);
        // }
      } else {
        setPageLoading(false);
        setRowsLoading(false);
      }
    }
    return () => {
      //
    };
  }, [indexStatus]);

  return pageLoading ? (
    <ContentContainer>
      <Loading />
    </ContentContainer>
  ) : (
    <ContentContainer>
      <div className="flex mt-3 mb-2 rounded relative">
        <div className="font-medium text-xl">파트너스</div>
      </div>
      <SearchMenu query={query} />
      <SortContainer>
        {/* <SortItem active>기본정렬</SortItem>
        <SortItem>최신 등록순</SortItem>
        <SortItem>마감 임박순</SortItem>
        <SortItem>금액 높은순</SortItem>
        <SortItem>금액 낮은순</SortItem> */}
      </SortContainer>
      <div className="border-t border-gray-300">
        {rowsLoading && indexData ? (
          <Loading />
        ) : (
          indexData?.rows &&
          indexData.rows.map((row) => {
            return (
              <div className="py-5 px-2 border-b border-gray-300" key={row.id}>
                <div className="flex">
                  <div className="bg-gray-1001 w-24 h-full">
                    <div className="w-16 h-16 border border-gray-400 rounded-md flex">
                      <img
                        className="m-auto"
                        src={`${process.env.REACT_APP_IMG_URL}/${row.image}`}
                        alt="partner"
                      />
                    </div>
                  </div>
                  <div className="flex-grow">
                    <div className="flex mt-0">
                      <div className="flex-grow">
                        <Link
                          to={`/partners/${row.id}`}
                          className="text-lg font-medium"
                        >
                          {row.name} ({row.companyTypeCode})
                        </Link>
                      </div>
                      <div className="ml-2">{row.isActivity} 활동중</div>
                      <div className="mr-3 flex h-6">
                        <StarIcon className="text-yellow-500" />
                        <span className="pl-1 pt-0.5 text-sm">4.8</span>
                      </div>
                      <FavoriteBotton
                        checked={row.favorite}
                        cnt={row.favoriteCnt}
                        favType="partner"
                        favId={row.id}
                      />
                      {/* <div className="flex border rounded-full px-3 pt-0.25 h-6">
                        <FavoriteIcon className="pt-0.5" />
                        <span className="pl-2 pt-0.25 text-sm">0</span>
                      </div> */}
                    </div>
                    <div className="mt-1">
                      {row.summary}
                      {/* 식약청 인/허가 전문으로 빠른 인/허가 처리를 보장합니다.... */}
                    </div>
                  </div>
                </div>
                <div className="flex mt-3 text-sm">
                  <div className="flex-grow">
                    {/* 모아야 한다. */}
                    <span>인허가 &gt; 인허가 &gt; 보험 </span>
                  </div>
                  <div className="w-36 text-right">등록된 상품 12건</div>
                </div>
                <div className="flex mt-1 text-sm">
                  <div className="">
                    {row.addressState} &gt; {row.addressCity}
                  </div>
                  {row.issueTax === 1 && (
                    <div className="ml-4">세금계산서 가능</div>
                  )}
                  {row.inWork === 1 && <div className="ml-4">상주가능</div>}
                  <div className="flex-grow"></div>
                  <div className="w-36 text-right">프로젝트 계약 12건</div>
                </div>
              </div>
            );
          })
        )}
      </div>
      <Pagination
        page={indexData.page}
        totalPage={indexData.totalPage}
        query={query}
      />
    </ContentContainer>
  );
}
