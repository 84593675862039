import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  myClientsIndex,
  myClientsSelector,
  clearState
} from './myClientsSlice';
import { useLocation, useHistory } from 'react-router-dom';

import { Title2, TitleXl } from '../../../components/title';

import { Link } from 'react-router-dom';
import Button, { IconButton } from '../../../components/button';
import Edit from './edit';

import {
  fieldName2,
  techLevelName,
  experienceName,
  companyTypeName,
  fieldNames2,
  inWorkName
} from '../../../utils/codeView';
import Loading from '../../../components/loading';
import { getSession } from '../../../utils/session';
import goLogin from '../../../utils/goLogin';
// import VerifiedBadge from '../Index/verifiedBadge';
import { diffYM } from '../../../utils/calDate';
import nl2br from 'react-nl2br';
import Input from '../../../components/input';
import {
  CheckDoubleIcon,
  CheckIcon,
  CheckIcon2,
  CloseIcon,
  ContactPhone,
  ExclamationTriangle
} from '../../../components/icon';
import VerifyBox from './verifyBox';

export default function Content(props) {
  // auth
  const history = useHistory();

  // redux
  const dispatch = useDispatch();
  const { indexStatus, indexResult, indexData } =
    useSelector(myClientsSelector);

  // form
  console.log('content re render');

  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      editClose();
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    // dispatch(technologySubmit(data));
    // dispatch(technologySubmit(data));
    // console.log('onSubmit technologyId', technologyId);
    // dispatch(technologySubmit({ technologyId: technologyId, reqData: data }));
  };

  // Edit Handle
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const handleEditOpen = (e) => {
    setEditOpen(true);
  };
  const editClose = () => {
    setEditOpen(false);
  };
  const handleDeleteOpen = (e, id) => {
    setDeleteOpen(true);
  };
  const deleteClose = () => {
    setDeleteOpen(false);
  };

  // refresh
  const [rowsRefreshKey, setRowsRefreshKey] = React.useState(0);
  const rowsRefresh = () => {
    const key = rowsRefreshKey + 1;
    setRowsRefreshKey(key);
  };

  // page data
  const [pageLoading, setPageLoading] = useState(true);
  const [rowsLoading, setRowsLoading] = useState(true);

  useEffect(() => {
    dispatch(myClientsIndex());
    return () => {
      setRowsLoading(true);
    };
  }, [rowsRefreshKey]);

  useEffect(() => {
    if (indexStatus === 'completed') {
      if (!indexResult.success) {
        alert(indexResult.message);
        if (indexResult.error === 'auth') {
          goLogin(history);
        }
      } else {
        setPageLoading(false);
        setRowsLoading(false);
      }
    }
    return () => {
      //
    };
  }, [indexStatus]);

  return pageLoading ? (
    <div>
      <Loading />
    </div>
  ) : (
    <div>
      <div className="flex-grow text-xl my-4">클라이언트 정보</div>
      <VerifyBox />
      <div className="border border-gray-500 rounded-md my-6">
        <div className="px-2 my-5">
          <div className="text-sm my-4 mx-4">기본정보를 입력해 주세요</div>

          {rowsLoading ? (
            <Loading />
          ) : (
            !rowsLoading &&
            indexData && (
              <>
                <table className="w-full">
                  <tbody>
                    <tr>
                      <td className="w-3/12 font-medium py-4 px-6 align-top border-t border-b border-r border-gray-300">
                        프로필 이미지
                      </td>
                      <td className="w-9/12 py-4 px-6 border-t border-b border-gray-300">
                        {/* <img src="/sample_profile.png" alt="introduction" /> */}
                        <div className="w-28 h-28 border border-gray-200 rounded-xl flex">
                          <img
                            className="m-auto rounded-xl"
                            src={`${process.env.REACT_APP_IMG_URL}/${indexData.image}`}
                            alt="clients"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="font-medium py-4 px-6 align-top border-b border-r border-gray-300">
                        업체(프리랜서) 명
                      </td>
                      <td className="py-4 px-6 border-b border-gray-300">
                        {indexData.name}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-medium py-4 px-6 align-top border-b border-r border-gray-300">
                        구분
                      </td>
                      <td className="py-4 px-6 border-b border-gray-300">
                        {companyTypeName(indexData.companyTypeCode)}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-medium py-4 px-6 align-top border-b border-r border-gray-300">
                        파트너스 지역
                      </td>
                      <td className="py-4 px-6 border-b border-gray-300">
                        {indexData.addressState} {indexData.addressCity}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-medium py-4 px-6 align-top border-b border-r border-gray-300">
                        파트너스 소개
                      </td>
                      <td className="py-4 px-6 border-b border-gray-300">
                        {nl2br(indexData.introduce)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )
          )}
        </div>
        <div className="flex flex-row-reverse w-full bg-gray-100 mt-10 p-3 items-end rounded-b">
          <Button onClick={(e) => handleEditOpen(e)}>기본정보 수정</Button>
        </div>
      </div>
      <Edit open={editOpen} editClose={editClose} refresh={rowsRefresh} />
    </div>
  );
}
