import moment from 'moment';

export const setSession = (obj) => {
  for (let key in obj) {
    sessionStorage.setItem(key, obj[key]);
  }
};
export const getSession = (key) => {
  if (key === 'isExpires')
    return moment(sessionStorage.getItem('expires')).diff() <= 0;
  else if (key === 'login') return sessionStorage.getItem('login') === 'true';
  else return sessionStorage.getItem(key);
};
export const getToken = () => sessionStorage.getItem('token');
export const removeSession = () => sessionStorage.clear();
