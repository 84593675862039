import styled from 'styled-components';
import tw from 'twin.macro';

const VerifiedBadgeContainer = styled.div`
  ${tw`
    relative 
    rounded-2xl 
    h-8 
    mt-1 
    bg-green-500
  `};
  ${({ invalid }) => invalid && tw`bg-red-500`}
`;

// const MainContainer = styled.div`
//   ${tw`
//     h-full
//     items-center
//     ml-66
//     py-10 px-10
//     rounded
//     border
//     border-gray-300
//     bg-white
//   `};
// `;

export default function VerifiedBadge(props) {
  return (
    <VerifiedBadgeContainer invalid={!props.val}>
      {props.invalid ? (
        <svg
          className="w-4 h-4 fill-current text-white absolute top-2 left-3"
          viewBox="0 0 192 512"
        >
          <path
            fill="currentColor"
            d="M176 432c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80zM25.26 25.199l13.6 272C39.499 309.972 50.041 320 62.83 320h66.34c12.789 0 23.331-10.028 23.97-22.801l13.6-272C167.425 11.49 156.496 0 142.77 0H49.23C35.504 0 24.575 11.49 25.26 25.199z"
          ></path>
        </svg>
      ) : (
        <svg
          className="w-4 h-4 fill-current text-white absolute top-2 left-3"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
          ></path>
        </svg>
      )}
      <h2 className="pl-9 pr-4 pt-1.5 text-sm font-semibold text-white">
        {props.label}
        {props.val ? '됨' : '안됨'}
      </h2>
    </VerifiedBadgeContainer>
  );
}
