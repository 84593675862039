import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import Logo from '../logo';
import NavItems from './navItems';
import NavLeftItems from './navLeftItems';

const NavbarContainer = styled.div`
  /* min-height: 68px; */
  ${tw` w-full min-h-68px bg-white border-b border-gray-300 sticky top-0 z-50`}
`;

const NavMenuContainer = styled.div`
  ${tw` h-full w-full m-auto flex items-center max-w-screen-lg `}
`;

const FlexGrow = styled.div`
  ${tw` flex-grow `}
`;

const LogoConatiner = styled.div`
  ${tw` ml-1 `}
`;

export default function Navbar(props) {
  return (
    <NavbarContainer>
      <NavMenuContainer>
        <LogoConatiner>
          <Logo />
        </LogoConatiner>
        <NavLeftItems active={props.active} />
        <FlexGrow />
        <NavItems />
      </NavMenuContainer>
    </NavbarContainer>
  );
}
