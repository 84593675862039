import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { setSession, getToken } from '../../../utils/session';

// index
export const signUpCreate = createAsyncThunk(
  '/sign-up/create',
  (_, thunkAPI) => {
    return axios
      .get('/sign-up', {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          // 'content-type': 'multipart/form-data'
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        console.log('response.data', response.data);
        if (response.data.success) {
          setSession(response.data.data.user);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const signUpStore = createAsyncThunk(
  '/sign-up/store',
  ({ reqData }, thunkAPI) => {
    const url = '/sign-up';
    const formData = {
      name: reqData.name,
      email: reqData.email,
      password: reqData.password,
      userType: reqData.userType
    };
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        // 'content-type': 'multipart/form-data'
        'Content-Type': 'application/json'
      }
    };
    return axios
      .post(url, formData, config)
      .then((response) => {
        console.log('response.data', response.data);
        setSession(response.data.data.user);
        if (!response.data.success && response.data.error === 'auth') {
          // 시간만료등....
          // alert(response.data.message);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const signUpSlice = createSlice({
  name: 'signUp',
  initialState: {
    createStatus: '',
    createMessage: '',
    createData: {},
    storeStatus: '',
    storeMessage: '',
    storeData: {}
  },
  reducers: {
    clearState: (state) => {
      state.createStatus = '';
      state.createMessage = '';
      state.createData = {};
      state.storeStatus = '';
      state.storeMessage = '';
      state.storeData = {};
      return state;
    }
  },
  extraReducers: {
    [signUpCreate.pending]: (state) => {
      state.createStatus = 'loading';
    },
    [signUpCreate.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.createStatus = 'completed';
      state.createMessage = payload?.message;
      state.createData = payload?.data?.pageData;
    },
    [signUpCreate.rejected]: (state, { payload }) => {
      state.createStatus = 'error';
    },
    [signUpStore.pending]: (state) => {
      state.storeStatus = 'loading';
    },
    [signUpStore.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.storeStatus = 'completed';
      state.storeMessage = payload.message;
      state.storeData = payload?.data?.pageData;
    },
    [signUpStore.rejected]: (state, { payload }) => {
      state.storeStatus = 'error';
    }
  }
});

export const { clearState } = signUpSlice.actions;
export const signUpSelector = (state) => state.signUp;
export default signUpSlice.reducer;
