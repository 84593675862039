import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  signInCreate,
  signInStore,
  signInSelector,
  clearState
} from './signInSlice';
import { getToken } from '../../utils/session';

import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import Label from '../../components/label';
import { LoadingButton } from '../../components/button';
import Input, { InputPassword } from '../../components/input';
import { Title2 } from '../../components/title';
import A from '../../components/a';
import isEmail from 'validator/lib/isEmail';

const SignInContainer = styled.div`
  ${tw`
    h-full
    items-center
    pt-10 pr-20 pb-10 pl-20
  `};
`;

export default function Content() {
  // query-string
  const history = useHistory();
  const { search } = useLocation();
  const query = queryString.parse(search);

  // redux
  const dispatch = useDispatch();
  const { createStatus, createMessage, storeStatus, storeMessage } =
    useSelector(signInSelector);

  // form
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    dispatch(signInStore({ reqData: data }));
    console.log(data);
  };

  // page data
  useEffect(() => {
    dispatch(signInCreate());
  }, []);

  // page data return
  // 로딩 처리
  useEffect(() => {
    //
  }, [createStatus]);

  // submit return
  // 로딩 처리
  useEffect(() => {
    if (storeStatus === 'loading') {
      // dispatch(clearState());
    }
    if (storeStatus === 'completed') {
      dispatch(clearState());
      if (storeMessage === 'success') {
        toast.success('로그인 되었습니다.');
        console.log('query.next', query.next);
        // history.push('/');
        query.next === undefined || query.next === ''
          ? history.replace('/')
          : history.replace(query.next);
        console.log('완료');
      } else {
        toast.error(storeMessage);
      }
    }
    console.log('storeStatus', storeStatus);
  }, [storeStatus]);

  return (
    <SignInContainer>
      <div className="font-semibold text-2xl">로그인</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-10">
          <Label htmlFor="email">이메일</Label>
          <Controller
            render={({ field, fieldState }) => (
              <Input
                id="email"
                value={field.value}
                onChange={field.onChange}
                inputRef={field.ref}
                placeholder="이메일을 입력해 주세요"
                error={fieldState.error}
                errorMessage={errors.email && errors.email.message}
              />
            )}
            name="email"
            control={control}
            rules={{
              validate: (value) => {
                // console.log('value', value);
                if (!value) return '이메일을 입력해 주세요';
                if (!isEmail(value)) return '이메일 형식이 잘못되었습니다';
                return true;
              }
            }}
          />
        </div>
        <div className="mt-8">
          <Label htmlFor="email">비밀번호</Label>
          <Controller
            render={({ field, fieldState }) => (
              <InputPassword
                id="password"
                value={field.value}
                onChange={field.onChange}
                inputRef={field.ref}
                placeholder="비밀번호를 입력해 주세요"
                error={fieldState.error}
                errorMessage={errors.password && errors.password.message}
              />
            )}
            name="password"
            control={control}
            rules={{ required: '비밀번호를 입력해 주세요' }}
          />
        </div>
        <div className="mt-8">
          <LoadingButton
            fullSize
            type="submit"
            disabled={storeStatus === 'loading'}
          >
            로그인
          </LoadingButton>
        </div>
        <div className="flex items-center justify-between mt-8">
          <A to="/sign-up">회원가입</A>
          <A to="/password-reset">비밀번호 재설정</A>
        </div>
        {/* <div>type : {sessionStorage.getItem('type')}</div> */}
        {/* <div>token : {getToken()}</div> */}
      </form>
    </SignInContainer>
  );
}
