import { TitleSm } from '../../../components/title';
import { fieldName2 } from '../../../utils/codeView';

export default function FieldsView(props) {
  const { fields } = props;
  const arr = fields.split(',');
  return arr.map((code, i) => {
    return (
      <TitleSm className="bg-gray-200 mr-4" key={i}>
        {fieldName2(code)}
      </TitleSm>
    );
  });
}
