import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { setSession, getToken } from '../../../utils/session';

// index
export const introductionIndex = createAsyncThunk(
  '/my-partners/introduction/index',
  (reqData, thunkAPI) => {
    return axios
      .get('/my-partners/introduction', {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        console.log('response.data', response.data);
        console.log('response.data.data.user', response.data.data?.user);
        if (response.data.success && response.data.error !== 'auth') {
          setSession(response.data.data?.user);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

// edit
export const introductionEdit = createAsyncThunk(
  '/my-partners/introduction/edit',
  (_, thunkAPI) => {
    const url = `/my-partners/introduction/edit`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        console.log('response.data', response.data);
        setSession(response.data.data.user);
        if (!response.data.success && response.data.error === 'auth') {
          alert(response.data.message);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

// update
export const introductionSubmit = createAsyncThunk(
  '/my-partners/introduction/update',
  ({ reqData }, thunkAPI) => {
    console.log('reqData', reqData);
    const url = `/my-partners/introduction`;
    // const formData = {
    //   companyTypeCode: reqData.companyTypeCode,
    //   founded: reqData.founded,
    //   name: reqData.name,
    //   addressState: reqData.addressState,
    //   addressCity: reqData.addressCity,
    //   inWork: reqData.inWork,
    //   issueTax: reqData.issueTax,
    //   summary: reqData.summary,
    //   introduce: reqData.introduce,
    //   fields: reqData.fields
    // };
    const formData = new FormData();
    formData.append('companyTypeCode', reqData.companyTypeCode);
    formData.append('founded', reqData.founded);
    formData.append('name', reqData.name);
    formData.append('addressState', reqData.addressState);
    formData.append('addressCity', reqData.addressCity);
    formData.append('inWork', reqData.inWork);
    formData.append('issueTax', reqData.issueTax);
    formData.append('summary', reqData.summary);
    formData.append('introduce', reqData.introduce);
    formData.append('fields', reqData.fields);
    reqData.image[0] && formData.append('image', reqData.image[0]);
    console.log('formData()', formData);
    console.log('reqData.image', reqData.image);
    // console.log('getToken()', getToken());
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'content-type': 'multipart/form-data'
        // 'Content-Type': 'application/json'
      }
    };
    return axios
      .post(url, formData, config)
      .then((response) => {
        console.log('response.data', response.data);
        setSession(response.data.data.user);
        if (!response.data.success && response.data.error === 'auth') {
          // 시간만료등....
          alert(response.data.message);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const introductionSlice = createSlice({
  name: 'myPartnersIntroduction',
  initialState: {
    indexStatus: '',
    indexMessage: '',
    indexData: {},
    editStatus: '',
    editMessage: '',
    editData: {},
    submitStatus: '',
    submitMessage: ''
  },
  reducers: {
    clearState: (state) => {
      state.indexStatus = '';
      state.indexMessage = '';
      state.indexData = {};
      state.editStatus = '';
      state.editMessage = '';
      state.editData = {};
      state.submitStatus = '';
      state.submitMessage = '';
      return state;
    }
  },
  extraReducers: {
    [introductionIndex.pending]: (state) => {
      state.indexStatus = 'loading';
    },
    [introductionIndex.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.indexStatus = 'completed';
      state.indexMessage = payload?.message;
      state.indexData = payload?.data?.pageData;
    },
    [introductionIndex.rejected]: (state, { payload }) => {
      state.indexStatus = 'error';
    },
    [introductionEdit.pending]: (state) => {
      state.editStatus = 'loading';
    },
    [introductionEdit.fulfilled]: (state, { payload }) => {
      console.log('payload edit', payload);
      state.editStatus = 'completed';
      state.editData = payload?.data?.pageData;
      // state.editData = payload.data.pageData;
    },
    [introductionEdit.rejected]: (state, { payload }) => {
      state.editStatus = 'error';
    },
    [introductionSubmit.pending]: (state) => {
      state.submitStatus = 'loading';
    },
    [introductionSubmit.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.submitStatus = 'completed';
      state.submitMessage = payload.message;
    },
    [introductionSubmit.rejected]: (state, { payload }) => {
      state.submitStatus = 'error';
    }
  }
});

export const { clearState } = introductionSlice.actions;
export const introductionSelector = (state) => state.myPartnersIntroduction;
export default introductionSlice.reducer;
