import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

const BaseTextarea = styled.textarea`
  ${tw`
    // shadow
    appearance-none
    border
    rounded
    w-full
    py-3 px-4
    text-gray-700
    leading-tight
    focus:outline-none
    // focus:shadow-outline
  `}
  ${({ error }) => (error ? tw`border-red-500` : tw`border-gray-400`)}
`;

export const ErrorMessage = styled.p`
  ${tw`
    text-red-500 
    text-xs 
    italic
  `}
`;

export const Textarea = (props) => {
  return (
    <>
      <BaseTextarea {...props}>{props.children}</BaseTextarea>
      {props.errorMessage && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
    </>
  );
};

export default Textarea;
