import React, { useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import DatePicker from 'react-datepicker';
import { VisibilityIcon, VisibilityOffIcon } from '../icon';

const BaseInput = styled.input`
  ${tw`
    appearance-none
    border
    border-gray-700
    rounded
    w-full
    py-3 px-4
    text-gray-700
    leading-tight
    focus:outline-none
    focus:ring-1
  `}
  ${({ error }) => (error ? tw`border-red-500` : tw`border-gray-400`)}
`;

export const ErrorMessage = styled.p`
  ${tw`
    text-red-500 
    text-xs 
    italic
  `}
`;

export const Input = (props) => {
  return (
    <>
      <BaseInput {...props}>{props.children}</BaseInput>
      {props.errorMessage && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
    </>
  );
};

const BaseInputSearch = styled.input`
  ${tw`
    appearance-none
    w-full
    rounded-md
    border
    py-2 px-4
    text-gray-800
    leading-tight
    focus:outline-none
    focus:border-amber-900
  `}
  ${({ error }) => (error ? tw`border-red-500` : tw`border-gray-500`)}
`;

export const InputSearch = (props) => {
  return (
    <>
      <BaseInputSearch {...props}>{props.children}</BaseInputSearch>
      {props.errorMessage && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
    </>
  );
};

const BaseInputPercent = styled.input`
  ${tw`
    // shadow
    appearance-none
    border
    rounded
    w-full
    py-3 pl-4 pr-8
    text-gray-700
    leading-tight
    focus:outline-none
    // focus:shadow-outline
    focus:ring-1
  `}
  ${({ error }) => (error ? tw`border-red-500` : tw`border-gray-400`)}
`;

export const InputPercent = (props) => {
  return (
    // <>
    //   <BaseInput {...props}>{props.children}</BaseInput>
    //   {props.errorMessage && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
    // </>
    <>
      <div class="relative text-gray-700">
        {/* <input
        class="w-full h-10 pl-3 pr-8 text-base 
        placeholder-gray-600 border rounded-lg focus:outline"
        type="number"
        placeholder="Regular input"
      /> */}
        <BaseInputPercent type="number" {...props}>
          {props.children}
        </BaseInputPercent>
        <div class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
          %
        </div>
      </div>
      {props.errorMessage && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
    </>
  );
};

export const InputDatePicker = styled(DatePicker)`
  ${tw`
    // shadow
    appearance-none
    border
    rounded
    w-full
    py-3 px-4
    text-gray-700
    leading-tight
    focus:outline-none
    // focus:shadow-outline
    focus:ring-1
  `}
  ${({ error }) => (error ? tw`border-red-500` : tw`border-gray-400`)}
`;

const BaseInputPassword = styled.input`
  ${tw`
    // shadow
    appearance-none
    border
    rounded
    w-full
    py-3 px-4
    text-gray-700
    leading-tight
    focus:outline-none
    // focus:shadow-outline
    focus:ring-1
  `}
  ${({ error }) => (error ? tw`border-red-500` : tw`border-gray-400`)}
`;

export const InputPassword = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <div className="relative">
        <BaseInputPassword {...props} type={showPassword ? 'text' : 'password'}>
          {props.children}
        </BaseInputPassword>
        <div
          className="absolute right-12 top-2.5 cursor-pointer"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </div>
      </div>
      {props.errorMessage && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
    </>
  );
};

export default Input;
