import isStrongPassword from 'validator/lib/isStrongPassword';

export default function passwordCheck(password) {
  let passwordOption = {
    minLength: 8,
    minLowercase: 0,
    minUppercase: 0,
    minNumbers: 0,
    minSymbols: 0
  };

  if (!isStrongPassword(password, { ...passwordOption })) {
    return '8자 이상 입력해 주세요';
  }

  passwordOption = { ...passwordOption, minNumbers: 1 };
  if (!isStrongPassword(password, { ...passwordOption })) {
    return '숫자를 포함해 주세요';
  }

  passwordOption = { ...passwordOption, minLowercase: 1 };
  if (!isStrongPassword(password, { ...passwordOption })) {
    return '소문자를 포함해 주세요';
  }

  passwordOption = { ...passwordOption, minUppercase: 1 };
  if (!isStrongPassword(password, { ...passwordOption })) {
    return '대문자를 포함해 주세요';
  }

  passwordOption = { ...passwordOption, minSymbols: 1 };
  if (!isStrongPassword(password, { ...passwordOption })) {
    return '특수문자를 포함해 주세요';
  }

  return true;
}
