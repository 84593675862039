import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import Navbar from '../../../components/navbar';
import LeftMenu from '../Index/leftMenu';

import topImage from '../../../../assets/images/top.png';
import Content from './content';

const PageContiner = styled.div`
  ${tw`
    flex
    flex-col
    w-full
    h-full
    items-center
    overflow-x-hidden
  `}
`;

const TopImageContainer = styled.div`
  min-height: 81px;
  background-color: #e3f8f2;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  ${tw`
    w-full
    // items-center  
    justify-items-center
    
  `}
  img {
    margin: auto;
  }
`;

const ContentsContiner = styled.div`
  ${tw`
    // bg-red-50
    relative
    w-full
    max-w-screen-xl
    items-start
    py-6 px-6 
    `};
`;

export default function Create(props) {
  console.log('props.match.params.id', props.match.params.id);
  return (
    <PageContiner>
      <Navbar />
      <TopImageContainer>
        <img src={topImage} />
      </TopImageContainer>
      <ContentsContiner>
        <LeftMenu active="portfolio" />
        <Content {...props} />
      </ContentsContiner>
    </PageContiner>
  );
}
