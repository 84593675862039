import { CheckIcon, CloseIcon } from '../icon';

export default function Toggle(props) {
  return (
    <div className="m-auto w-14">
      <label for={props.id} className="flex items-center cursor-pointer">
        <div className="relative">
          <input type="checkbox" {...props} className="sr-only" />
          <div className="toggle-bg block bg-gray-700 w-14 h-6 rounded-full"></div>
          <CheckIcon className="toggle-on absolute left-3 top-1 text-white" />
          <CloseIcon className="toggle-off absolute right-3 top-1 text-white" />
          <div className="toggle-dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
        </div>
      </label>
    </div>
  );
}
