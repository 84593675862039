import React from 'react';
import {
  CheckIcon,
  CloseIcon,
  ExclamationTriangle
} from '../../../components/icon';

export default function VerifyBox() {
  return (
    <div className="border border-gray-500 rounded-md my-6 py-4 px-4 bg-gray-50">
      <div className="flex font-medium text-base">
        <ExclamationTriangle className="text-red-600 mt-1 mr-1" />
        프로젝트 지원 및 상품몰을 이용하기 위해서는 아래 항목의 인증(입력)이
        필요합니다.
      </div>
      <div className="flex align-middle text-base px-4 pt-2 ">
        [1/3]
        <CheckIcon className="text-green-600 rounded-full mt-1 mx-1" />
        이메일 인증되었습니다.
      </div>
      <div className="flex align-middle text-base px-4 pt-2 ">
        [2/3]
        <CheckIcon className="text-green-600 mt-1 mx-1" />
        휴대폰번호 인증받으세요
      </div>
      <div className="flex align-middle text-base px-4 pt-2 text-red-600">
        [3/3]
        <CloseIcon className="text-red-600 mt-1 mx-1" />
        기술정보를 입력해 주세요
      </div>
    </div>
  );
}
