import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { myPageIndex, myPageSelector, clearState } from './myPageSlice';
import { useLocation, useHistory } from 'react-router-dom';

import Button from '../../../components/button';
import EmailEdit from '../Email/edit';
import PhoneNumberEdit from '../PhoneNumber/edit';
import PasswordEdit from '../Password/edit';
import LeaveEdit from '../Leave/edit';

import Loading from '../../../components/loading';
import goLogin from '../../../utils/goLogin';

export default function Content(props) {
  // auth
  const history = useHistory();

  // redux
  const dispatch = useDispatch();
  const { indexStatus, indexResult, indexData } = useSelector(myPageSelector);

  // Edit Handle email
  const [emailEditOpen, setEmailEditOpen] = useState(false);
  const handleEmailEditOpen = () => {
    setEmailEditOpen(true);
  };
  const handleEmailEditClose = () => {
    setEmailEditOpen(false);
  };

  // Edit Handle phone number
  const [phoneNumberEditOpen, setPhoneNumberEditOpen] = useState(false);
  const handlePhoneNumberEditOpen = () => {
    setPhoneNumberEditOpen(true);
  };
  const handlePhoneNumberEditClose = () => {
    setPhoneNumberEditOpen(false);
  };

  // Edit Handle password
  const [passwordEditOpen, setPasswordEditOpen] = useState(false);
  const handlePasswordEditOpen = () => {
    setPasswordEditOpen(true);
  };
  const handlePasswordEditClose = () => {
    setPasswordEditOpen(false);
  };

  // Edit Handle leave
  const [leaveEditOpen, setLeaveEditOpen] = useState(false);
  const handleLeaveEditOpen = () => {
    setLeaveEditOpen(true);
  };
  const handleLeaveEditClose = () => {
    setLeaveEditOpen(false);
  };

  // refresh
  const [rowsRefreshKey, setRowsRefreshKey] = React.useState(0);
  const rowsRefresh = () => {
    const key = rowsRefreshKey + 1;
    setRowsRefreshKey(key);
  };

  // page data
  const [pageLoading, setPageLoading] = useState(true);
  const [rowsLoading, setRowsLoading] = useState(true);

  useEffect(() => {
    dispatch(myPageIndex());
    return () => {
      setRowsLoading(true);
    };
  }, [rowsRefreshKey]);

  useEffect(() => {
    if (indexStatus === 'completed') {
      if (!indexResult.success) {
        alert(indexResult.message);
        if (indexResult.error === 'auth') {
          goLogin(history);
        }
      } else {
        setPageLoading(false);
        setRowsLoading(false);
      }
    }
    return () => {
      //
    };
  }, [indexStatus]);

  return pageLoading ? (
    <div>
      <Loading />
    </div>
  ) : (
    <div>
      <div className="flex-grow text-xl my-4">마이페이지</div>
      {rowsLoading ? (
        <Loading />
      ) : (
        !rowsLoading &&
        indexData && (
          <>
            <div className="flex w-full h-44 mt-4 border border-gray-500 rounded-md">
              <div className="w-2/3 py-8 px-10 border-r border-gray-500">
                <div className="font-medium text-xl">이메일 설정</div>
                <div className="mt-5">
                  '프로젝트 지원' 할 경우 해당 클라이언트에게만 공개 됩니다.
                  <br />
                  파트너스 검색할 경우 공개 되지 않습니다.
                </div>
              </div>
              <div className="flex flex-grow justify-center items-center rounded-r-md bg-gray-200">
                <div>
                  <div className="text-center pb-2">
                    {indexData.email || `등록되지 않았습니다.`}
                  </div>
                  <div className="text-center">
                    <Button onClick={(_) => handleEmailEditOpen()}>
                      이메일 변경
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full h-44 mt-4 border border-gray-500 rounded-md">
              <div className="w-2/3 py-8 px-10 border-r border-gray-500">
                <div className="font-medium text-xl">휴대전화 설정</div>
                <div className="mt-5">
                  '프로젝트 지원' 할 경우 해당 클라이언트에게만 공개 됩니다.
                  <br />
                  파트너스 검색할 경우 공개 되지 않습니다.
                </div>
              </div>
              <div className="flex flex-grow justify-center items-center rounded-r-md bg-gray-200">
                <div>
                  <div className="text-center pb-2">
                    {indexData.phoneNumber || `등록되지 않았습니다.`}
                  </div>
                  <div className="text-center">
                    <Button onClick={(_) => handlePhoneNumberEditOpen()}>
                      {indexData.phoneNumber
                        ? `휴대전화 변경`
                        : `휴대전화 입력`}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full h-44 mt-4 border border-gray-500 rounded-md">
              <div className="w-2/3 py-8 px-10 border-r border-gray-500">
                <div className="font-medium text-xl">비밀번호 설정</div>
                <div className="mt-5">
                  비밀번호를 변경합니다.
                  <br />
                </div>
              </div>
              <div className="flex flex-grow justify-center items-center rounded-r-md bg-gray-200">
                <Button onClick={(_) => handlePasswordEditOpen()}>
                  비밀번호 변경
                </Button>
              </div>
            </div>
            <div className="flex w-full h-44 mt-4 border border-gray-500 rounded-md">
              <div className="w-2/3 py-8 px-10 border-r border-gray-500">
                <div className="font-medium text-xl">회원탈퇴</div>
                <div className="mt-5">
                  회원탈퇴 합니다.
                  <br />
                </div>
              </div>
              <div className="flex flex-grow justify-center items-center rounded-r-md bg-gray-200">
                <Button onClick={(_) => handleLeaveEditOpen()}>
                  회원 탈퇴
                </Button>
              </div>
            </div>
          </>
        )
      )}
      <EmailEdit
        open={emailEditOpen}
        editClose={handleEmailEditClose}
        refresh={rowsRefresh}
      />
      <PhoneNumberEdit
        open={phoneNumberEditOpen}
        editClose={handlePhoneNumberEditClose}
        refresh={rowsRefresh}
      />
      <PasswordEdit
        open={passwordEditOpen}
        editClose={handlePasswordEditClose}
        refresh={rowsRefresh}
      />
      <LeaveEdit
        open={leaveEditOpen}
        editClose={handleLeaveEditClose}
        refresh={rowsRefresh}
      />
    </div>
  );
}
