import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  projectsCreate,
  projectsStore,
  projectsSelector,
  clearState
} from '../Index/projectsSlice';
import { useLocation, useHistory } from 'react-router-dom';

import {
  CheckIcon,
  CloseIcon,
  CommentIcon,
  FavoriteIcon
} from '../../../components/icon';
import { TextSm, TitleSm, TitleXl } from '../../../components/title';
import RecruitmentBadge from '../Index/recruitmentBadge';
import WorkTypeBadge from '../Index/workTypeBadge';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ko from 'date-fns/locale/ko';
import goLogin from '../../../utils/goLogin';
import Label from '../../../components/label';
import Input, {
  ErrorMessage,
  InputDatePicker
} from '../../../components/input';
import FieldSelect from '../../../components/fieldSelect';
import { fieldName2 } from '../../../utils/codeView';
import AddressSelect from '../../../components/addressSelect';
import Textarea from '../../../components/textarea';
import Button, { IconButton } from '../../../components/button';
import moment from 'moment';
registerLocale('ko', ko);
setDefaultLocale('ko');

const ContentContiner = styled.div`
  ${tw`
    h-full
    items-center
    py-5 px-16
    rounded-lg
    border
    border-gray-400
    bg-white
  `};
`;

export default function Content(props) {
  // auth
  const history = useHistory();

  // redux
  const dispatch = useDispatch();
  const {
    createStatus,
    createResult,
    createData,
    storeStatus,
    storeResult,
    storeData
  } = useSelector(projectsSelector);

  // field
  //const [fieldCode, setFieldCode] = useState('');
  const [fields, setFields] = useState([]);
  const fieldView = (code, i) => {
    return (
      <div className={i === 0 ? 'flex' : 'flex mt-1'}>
        {fieldName2(code)}
        <span
          className="mt-1 ml-3 cursor-pointer"
          onClick={(e) => handleFieldRemove(e, code)}
        >
          <CloseIcon />
        </span>
      </div>
    );
  };
  console.log('fields ++++', fields);
  const fieldSelected = (code) => {
    if (!fields.includes(code)) {
      const arr = [...fields];
      arr.push(code);
      setValue('fields', arr.join(','), {
        shouldValidate: true,
        shouldDirty: true
      });
      setFields([...fields, code]);
    }

    // console.log('fields selected', fields.join(','));
  };

  const handleFieldRemove = (e, code) => {
    const arr = [...fields.filter((v) => v !== code)];
    setValue('fields', arr.join(','), {
      shouldValidate: true,
      shouldDirty: true
    });
    setFields([...fields.filter((v) => v !== code)]);
  };

  // company_types
  const [companyTypes, setCompanyTypes] = React.useState([]);
  const handleCompanyTypesChange = (e) => {
    const code = e.target.value;
    if (e.target.checked) {
      if (!companyTypes.includes(code)) {
        const arr = [...companyTypes];
        arr.push(code);
        setValue('companyTypes', arr.join(','), {
          shouldValidate: true,
          shouldDirty: true
        });
        setCompanyTypes([...companyTypes, code]);
      }
    } else {
      const arr = [...companyTypes.filter((v) => v !== code)];
      setValue('companyTypes', arr.join(','), {
        shouldValidate: true,
        shouldDirty: true
      });
      setCompanyTypes([...companyTypes.filter((v) => v !== code)]);
    }
  };

  // meeting_locations
  const [meetingLocations, setMeetingLocations] = React.useState([]);
  const meetingLocationAdd = (code) => {
    // console.log('존재하지 않으면 추가시킨다.', code);
    if (!meetingLocations.includes(code)) {
      const arr = [...meetingLocations];
      arr.push(code);
      setValue('meetingLocations', arr.join(','), {
        shouldValidate: true,
        shouldDirty: true
      });
      setValue('meetingOnline', getValues('meetingOnline'), {
        shouldValidate: true
      });
      setMeetingLocations([...meetingLocations, code]);
      // setFormData({ ...formData, fields: fields.join(',') });
    }
  };
  const handleMeetingLocationCheck = (e) => {
    if (!e.target.checked) {
      const arr = [...meetingLocations.filter((v) => v !== e.target.value)];
      setValue('meetingLocations', arr.join(','), {
        shouldValidate: true,
        shouldDirty: true
      });
      setValue('meetingOnline', getValues('meetingOnline'), {
        shouldValidate: true
      });
      setMeetingLocations([
        ...meetingLocations.filter((v) => v !== e.target.value)
      ]);
      // setFormData({ ...formData, fields: fields.join(',') });
    }
  };
  const meetingLocationView = (v, i) => {
    return (
      <span className={i !== 0 && 'ml-4'} key={i}>
        <input
          type="checkbox"
          name={`meeting_locations_${i}`}
          color="primary"
          value={v}
          checked={meetingLocations.includes(v)}
          onChange={handleMeetingLocationCheck}
        />
        <Label className="pl-2">{v}</Label>
      </span>
    );
  };

  // form
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    getValues,
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    console.log('DDDD Data', data);
    console.log(
      'DDDD deadlineAt',
      moment(data.deadlineAt).format('YYYY-MM-DD')
    );
    dispatch(projectsStore({ reqData: data }));
  };

  // page store
  useEffect(() => {
    console.log('submitStatus 1', storeStatus);
    if (storeStatus === 'completed') {
      // toast.success('완료 되었습니다');
      // refresh();
      // editClose();
    }
    return () => {};
  }, [storeStatus]);

  // page data
  const [pageLoading, setPageLoading] = useState(true);
  useEffect(() => {
    setPageLoading(true);
    dispatch(projectsCreate());
    return () => {
      //
    };
  }, []);

  useEffect(() => {
    if (createStatus === 'completed') {
      if (!createResult.success) {
        alert(createResult.message);
        if (createResult.error === 'auth') {
          goLogin(history);
        }
      } else {
        setPageLoading(false);
      }
    }
    return () => {
      //
    };
  }, [createStatus]);

  return (
    <ContentContiner>
      <div className="flex rounded relative mt-5">
        <TitleXl>프로젝트 등록</TitleXl>
      </div>
      <div className="mt-8 text-center sm:mt-8 sm:ml-0 sm:mr-0 sm:text-left">
        <form id="form" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Label htmlFor="subject">프로젝트명</Label>
            <Controller
              render={({ field, fieldState }) => (
                <Input
                  id="subject"
                  value={field.value}
                  onChange={field.onChange}
                  inputRef={field.ref} // wire up the input ref
                  placeholder="프로젝트명을 입력해 주세요"
                  error={fieldState.error}
                  errorMessage={errors.subject && errors.subject.message}
                />
              )}
              name="subject"
              control={control}
              rules={{ required: '프로젝트명을 입력해 주세요' }}
            />
          </div>
          <div className="mt-5 grid grid-cols-2 gap-5">
            <div>
              <Label htmlFor="amount">예상금액(원)</Label>
              <Controller
                render={({ field, fieldState }) => (
                  <Input
                    id="amount"
                    type="number"
                    value={field.value}
                    onChange={field.onChange}
                    inputRef={field.ref} // wire up the input ref
                    placeholder="예상금액(원)을 입력해 주세요"
                    error={fieldState.error}
                    errorMessage={errors.amount && errors.amount.message}
                  />
                )}
                name="amount"
                control={control}
                rules={{ required: '예상금액(원)을 입력해 주세요' }}
              />
            </div>
            <div>
              <Label htmlFor="period">예상기간(일)</Label>
              <Controller
                render={({ field, fieldState }) => (
                  <Input
                    id="period"
                    type="number"
                    value={field.value}
                    onChange={field.onChange}
                    inputRef={field.ref} // wire up the input ref
                    placeholder="예상기간(일)을 입력해 주세요"
                    error={fieldState.error}
                    errorMessage={errors.period && errors.period.message}
                  />
                )}
                name="period"
                control={control}
                rules={{ required: '예상기간(일)을 입력해 주세요' }}
              />
            </div>
          </div>
          <div className="mt-5 grid grid-cols-2 gap-5">
            <div>
              <Label htmlFor="deadline_at">모집마감일</Label>
              <Controller
                render={({ field, fieldState }) => (
                  <InputDatePicker
                    id="deadline_at"
                    locale="ko"
                    dateFormat="yyyy-MM-dd"
                    selected={field.value}
                    onChange={(date) => {
                      // console.log(
                      //   'deadlineAt',
                      //   moment(date).format('YYYY-MM-DD')
                      // );
                      field.onChange(date);
                    }}
                    placeholderText="모집마감일을 입력해 주세요"
                    error={fieldState.error}
                    wrapperClassName="w-full"
                  />
                )}
                name="deadlineAt"
                control={control}
                rules={{ required: '모집마감일을 입력해 주세요' }}
              />
              {errors.deadlineAt && errors.deadlineAt.message && (
                <ErrorMessage>{errors.deadlineAt.message}</ErrorMessage>
              )}
            </div>
            <div>
              <Label htmlFor="start_at">예상시작일</Label>
              <Controller
                render={({ field, fieldState }) => (
                  <InputDatePicker
                    id="start_at"
                    locale="ko"
                    dateFormat="yyyy-MM-dd"
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    placeholderText="예상시작일을 입력해 주세요"
                    error={fieldState.error}
                    wrapperClassName="w-full"
                  />
                )}
                name="startAt"
                control={control}
                rules={{ required: '예상시작일을 입력해 주세요' }}
              />
              {errors.startAt && errors.startAt.message && (
                <ErrorMessage>{errors.startAt.message}</ErrorMessage>
              )}
            </div>
          </div>
          <div className="mt-5">
            {/* <Label htmlFor="field_code">전문분야</Label> */}
            {/* <div className="flex rounded p-0.5"> */}
            <FieldSelect id="fields" selected={fieldSelected} />
            <input
              type="hidden"
              {...register('fields', {
                required: '전분분야를 한개이상 선택해 주세요'
              })}
            />
            {/* fields.length : {fields.length} */}
            {fields.length > 0 && (
              <div className="relative rounded border border-gray-400 mt-1 py-3 px-3 text-base">
                {fields.map((d, i) => {
                  return fieldView(d, i);
                  // return d;
                })}
              </div>
            )}
            {/* </div> */}
            {errors.fields && errors.fields.message && (
              <ErrorMessage>{errors.fields.message}</ErrorMessage>
            )}
          </div>
          <div className="mt-5">
            <Label htmlFor="">기간제상주 / 도급외주</Label>
            <div className="mt-2">
              <span>
                <input
                  type="radio"
                  id="work_type_in"
                  value="IN"
                  {...register('workType', {
                    required: '기간제상주 / 도급외주 를 선택해 주세요'
                  })}
                />
                <Label htmlFor="work_type_in" className="pl-1">
                  기간제상주 (특정기간동안 내부상주해서 작업)
                </Label>
              </span>
              <span className="ml-3">
                <input
                  type="radio"
                  id="work_type_out"
                  value="OUT"
                  {...register('workType', {
                    required: '기간제상주 / 도급외주 를 선택해 주세요'
                  })}
                />
                <Label htmlFor="work_type_out" className="pl-1">
                  도급외주 (외부에서 작업)
                </Label>
              </span>
            </div>
            {errors.workType && errors.workType.message && (
              <ErrorMessage>{errors.workType.message}</ErrorMessage>
            )}
          </div>

          <div className="mt-5">
            <Label htmlFor="">희망 파트너스</Label>
            <div className="mt-2">
              <span>
                <input
                  type="checkbox"
                  id="company_type_free"
                  value="FREE"
                  checked={companyTypes.includes('FREE')}
                  onChange={handleCompanyTypesChange}
                />
                <Label htmlFor="company_type_free" className="pl-2">
                  개인
                </Label>
              </span>
              <span className="ml-3">
                <input
                  type="checkbox"
                  id="company_type_pers"
                  value="PERS"
                  checked={companyTypes.includes('PERS')}
                  onChange={handleCompanyTypesChange}
                />
                <Label htmlFor="company_type_pers" className="pl-2">
                  개인사업자
                </Label>
              </span>
              <span className="ml-3">
                <input
                  type="checkbox"
                  id="company_type_corp"
                  value="CORP"
                  checked={companyTypes.includes('CORP')}
                  onChange={handleCompanyTypesChange}
                />
                <Label htmlFor="company_type_corp" className="pl-2">
                  법인사업자
                </Label>
              </span>
            </div>
            <input
              type="hidden"
              {...register('companyTypes', {
                required: '희망 파트너스를 한개이상 선택해 주세요'
              })}
            />
            {errors.companyTypes && errors.companyTypes.message && (
              <ErrorMessage>{errors.companyTypes.message}</ErrorMessage>
            )}
          </div>
          <div className="mt-5">
            <Label htmlFor="">미팅가능 위치</Label>
            <div className="mt-2">
              <span>
                <input
                  type="checkbox"
                  id="meeting_online"
                  {...register('meetingOnline', {
                    validate: (value) => {
                      console.log('vvvalue', value);
                      console.log('vvvalue', getValues('meetingLocations'));

                      if (!value && !getValues('meetingLocations')) {
                        return '미팅가능 위치를 한개 이상 선택해 주세요';
                      } else {
                        return true;
                      }
                    }
                  })}
                />
                <Label htmlFor="meeting_online" className="pl-2">
                  온라인
                </Label>
              </span>
            </div>
            <div className="mt-2">
              {meetingLocations && meetingLocations.map(meetingLocationView)}
            </div>
            {errors.meetingOnline && errors.meetingOnline.message && (
              <ErrorMessage>{errors.meetingOnline.message}</ErrorMessage>
            )}
            <div className="mt-2">
              <AddressSelect
                label="위치추가"
                meetingLocationAdd={meetingLocationAdd}
              />
            </div>
            <input type="hidden" {...register('meetingLocations')} />
          </div>
          <div className="mt-5">
            <Label htmlFor="work_contents">
              요청내용 (현재상황에 대해서도 입력해 주세요)
            </Label>
            <Controller
              render={({ field, fieldState }) => (
                <Textarea
                  id="work_contents"
                  value={field.value}
                  onChange={field.onChange}
                  inputRef={field.ref} // wire up the input ref
                  placeholder="요청내용 (현재상황에 대해서도 입력해 주세요) &#13;&#10;개인정보 및 연락처는 입력을 금지 합니다"
                  error={fieldState.error}
                  errorMessage={
                    errors.workContents && errors.workContents.message
                  }
                  rows={3}
                />
              )}
              name="workContents"
              control={control}
              rules={{ required: '소개를 입력해 주세요' }}
            />
          </div>
          <div className="mt-5">
            <Label htmlFor="work_environment">
              상주인 경우 근무 조건 / 외주인 경우 미팅 조건
            </Label>
            <Controller
              render={({ field, fieldState }) => (
                <Textarea
                  id="work_environment"
                  value={field.value}
                  onChange={field.onChange}
                  inputRef={field.ref} // wire up the input ref
                  placeholder="상주인 경우 근무 조건 / 외주인 경우 미팅 조건 &#13;&#10;개인정보 및 연락처는 입력을 금지 합니다"
                  error={fieldState.error}
                  errorMessage={
                    errors.workEnvironment && errors.workEnvironment.message
                  }
                  rows={3}
                />
              )}
              name="workEnvironment"
              control={control}
              rules={{ required: '소개를 입력해 주세요' }}
            />
          </div>
          <div className="mt-20 mb-10">
            <Button full type="submit" form="form">
              확인
            </Button>
          </div>
        </form>
      </div>
    </ContentContiner>
  );
}
