import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import moment from 'moment';
import { sprintf } from 'sprintf-js';
import queryString from 'query-string';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { goodsIndex, goodsSelector, clearState } from './goodsSlice';
import {
  fieldName2,
  techLevelName,
  experienceName
} from '../../../utils/codeView';
import Loading from '../../../components/loading';

import {
  CheckIcon,
  CommentIcon,
  FavoriteIcon,
  NewIcon,
  StarIcon
} from '../../../components/icon';
import A from '../../../components/a';
import { TextSm, TitleSm, TitleXl } from '../../../components/title';

import RecruitmentBadge from './recruitmentBadge';
import WorkTypeBadge from './workTypeBadge';
import FieldsView from './fieldsView';
import NewBadge from './newBadge';
import Pagination from '../../../components/pagination';
import priceView from './priceView';
import FavoriteBotton from '../../../components/favoriteButton';
import SearchMenu from './searchMenu';

const ContentContainer = styled.div`
  ${tw` h-full items-center `};
`;

const SortContainer = styled.div`
  ${tw` bg-blue-100 border-b border-gray-300 mt-8 pl-5 hidden sm:flex `}
`;

const SortItem = (props) => {
  // text-gray-700
  const textColor = props.active ? 'text-blue-800' : 'text-gray-700';
  return (
    <div className={`text-gray-700 px-2 py-2 text-sm relative ${textColor}`}>
      <CheckIcon className="absolute" />
      <TextSm className="pl-6">{props.children}</TextSm>
    </div>
  );
};

export default function Content(props) {
  // query-string
  const history = useHistory();
  const { search } = useLocation();
  const query = queryString.parse(search);

  // auth
  // const history = useHistory();
  if (!query?.page) {
    history.replace(history.location.pathname + '?page=1');
  }

  // redux
  const dispatch = useDispatch();
  const { indexStatus, indexResult, indexData } = useSelector(goodsSelector);

  // page data
  const [pageLoading, setPageLoading] = useState(true);
  const [rowsLoading, setRowsLoading] = useState(true);
  // const [limitCount, setLimitCount] = React.useState(3);
  // const [page, setPage] = React.useState();

  // console.log('query.page', query.page);

  // if (query && query.page && query.page !== page) {
  //   setPage(query.page);
  // }

  // console.log('----> page', page);

  useEffect(() => {
    console.log('xxx useEffect page', query.page);
    dispatch(goodsIndex({ reqData: query }));
    return () => {
      setRowsLoading(true);
    };
  }, [query.page, query.sword, query.fields, query.workTypes]);

  useEffect(() => {
    if (indexStatus === 'completed') {
      if (!indexResult.success) {
        alert(indexResult.message);
        // if (indexResult.error === 'auth') {
        //   goLogin(history);
        // }
      } else {
        setPageLoading(false);
        setRowsLoading(false);
      }
    }
    return () => {
      //
    };
  }, [indexStatus]);

  return pageLoading ? (
    <ContentContainer>
      <Loading />
    </ContentContainer>
  ) : (
    <ContentContainer>
      <div className="flex mt-3 mb-2 rounded relative">
        <div className="font-medium text-xl">상품몰</div>
      </div>
      <SearchMenu query={query} />
      <SortContainer>
        {/* <SortItem active>기본정렬</SortItem>
        <SortItem>최신 등록순</SortItem>
        <SortItem>마감 임박순</SortItem>
        <SortItem>금액 높은순</SortItem>
        <SortItem>금액 낮은순</SortItem> */}
      </SortContainer>
      <div className="border-t border-gray-300">
        {rowsLoading ? (
          <Loading />
        ) : (
          indexData?.rows &&
          indexData.rows.map((row) => {
            return (
              <div className="py-5 px-2 border-b border-gray-300" key={row.id}>
                <div className="flex">
                  <div className="w-2/12 h-full">
                    <div className="w-28 h-28 border-2 border-gray-200 rounded-md flex">
                      <img
                        className="m-auto"
                        src={`${process.env.REACT_APP_IMG_URL}/${row.image}`}
                        alt="goods"
                      />
                    </div>
                  </div>
                  <div className="w-8/12">
                    <div>
                      <Link
                        to={`/goods/${row.id}`}
                        className="text-lg font-medium"
                      >
                        {row.name}
                      </Link>
                    </div>
                    <div className="mt-1 text-base font-medium">
                      {priceView(row)}
                    </div>
                    <div className="mt-2 text-sm">
                      {fieldName2(row.fieldCode)}
                      {/* 인허가 &gt; 인허가 &gt; 보험{' '} */}
                    </div>
                    <div className="mt-1 text-sm">{row.summary}</div>
                  </div>
                  <div className="w-3/12">
                    <div className="flex justify-end text-right">
                      <div className="mr-3 flex h-6">
                        <StarIcon className="text-yellow-500" />
                        <span className="pl-1 pt-0.5 text-sm">4.8</span>
                      </div>
                      <FavoriteBotton
                        checked={row.favorite}
                        cnt={row.favoriteCnt}
                        favType="goods"
                        favId={row.id}
                      />
                    </div>
                    <div className="border-l-2 mt-3 pl-3 pt-0 pb-0">
                      <div className="text-base">{row.partner.name}</div>
                      <div className="text-xs text-gray-600 mt-1 ">
                        {row.partner.addressState} &gt;{' '}
                        {row.partner.addressCity}
                      </div>
                      <div className="text-xs text-gray-600 mt-1">
                        {row.partner.inWork ? '상주가능' : ''}{' '}
                        {row.partner.issueTax ? '세금계산서 발급' : ''}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
      <Pagination
        page={indexData.page}
        totalPage={indexData.totalPage}
        query={query}
      />
    </ContentContainer>
  );
}
