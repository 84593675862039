import React from 'react';
import { Link } from 'react-router-dom';
// import styled from 'styled-components';
// import tw from 'twin.macro';

// import LogoImg from '../../../assets/images/logo.png';
// import LogoDarkImg from '../../../assets/images/logo.png';

// const LogoContainer = styled.div`
//   ${tw`
//     flex
//     items-center
//   `}
// `;

// const Image = styled.div`
//   width: 100px;
//   /* ${tw`h-6 md:h-9`}; */

//   img {
//     /* width: auto;
//     height: 100%; */
//     max-width: 100%;
//     height: auto;
//   }
// `;

export default function Logo(props) {
  const { color, bgColor } = props;
  return (
    <div className="w-36">
      <Link to="/" className="w-max h-auto">
        {/* <img src={bgColor === 'dark' ? LogoDarkImg : LogoImg} /> */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 112.32 14"
          fill={color === 'white' ? `#fff` : `#bd571e`}
        >
          <g>
            <path d="M1.16,2.57,6.23,6.83,1.16,11.09,0,9.91,3.72,6.84,0,3.76Z" />
            <path d="M17.32,10.92l-.18-5.55-.06-2.22L16.6,4.66,15.51,8H14.36l-1-3.34L13,3.15l-.05,2.18-.17,5.59H11.17l.48-9.82h2.09l.87,2.79L15,5.46,15.43,4l.91-2.88h2.13L19,10.92Z" />
            <path d="M27,6.52c0,.12,0,.29,0,.49s0,.39,0,.56h-5a2.24,2.24,0,0,0,.16.89,1.68,1.68,0,0,0,.43.64,1.89,1.89,0,0,0,.68.39,2.65,2.65,0,0,0,.87.14,8.47,8.47,0,0,0,1.19-.09,9.76,9.76,0,0,0,1.32-.28v1.46l-.64.15-.7.12-.73.08-.72,0a4.73,4.73,0,0,1-1.56-.24,3.08,3.08,0,0,1-1.17-.74,3.21,3.21,0,0,1-.74-1.19,5,5,0,0,1-.25-1.66,5.31,5.31,0,0,1,.25-1.69A4,4,0,0,1,21,4.26a3.28,3.28,0,0,1,1.14-.84,3.53,3.53,0,0,1,1.48-.3,3.72,3.72,0,0,1,1.43.26,2.77,2.77,0,0,1,1.05.7,3.11,3.11,0,0,1,.64,1.07A4.28,4.28,0,0,1,27,6.52Zm-1.86-.26A2.13,2.13,0,0,0,25,5.47a1.5,1.5,0,0,0-.33-.55,1.27,1.27,0,0,0-.48-.32,1.63,1.63,0,0,0-.59-.11A1.51,1.51,0,0,0,22.48,5,2.15,2.15,0,0,0,22,6.26Z" />
            <path d="M33.59,10.92l0-1.13a5.23,5.23,0,0,1-.5.54,3.15,3.15,0,0,1-.55.41,2.62,2.62,0,0,1-.62.26,2.89,2.89,0,0,1-.74.09,2.59,2.59,0,0,1-1.2-.27,2.35,2.35,0,0,1-.89-.77,3.45,3.45,0,0,1-.54-1.21,6.08,6.08,0,0,1-.19-1.59,5,5,0,0,1,.3-1.82,3.36,3.36,0,0,1,.82-1.28,3.24,3.24,0,0,1,1.25-.75,4.92,4.92,0,0,1,1.57-.25,5.46,5.46,0,0,1,.57,0l.52.1v-3h1.83V10.92ZM30.24,7.17a5.41,5.41,0,0,0,.09,1.07,2.46,2.46,0,0,0,.25.74,1.17,1.17,0,0,0,.42.41,1.16,1.16,0,0,0,.56.13,1.3,1.3,0,0,0,.89-.37,7,7,0,0,0,.9-1V4.78a3.06,3.06,0,0,0-.54-.13,3.45,3.45,0,0,0-.64-.05,1.71,1.71,0,0,0-.8.18,1.76,1.76,0,0,0-.61.51,2.53,2.53,0,0,0-.38.81A3.85,3.85,0,0,0,30.24,7.17Z" />
            <path d="M39.71,4.72H37.56V3.29h4v6.2h2.2v1.43H37.3V9.49h2.41Zm2-3.52a1.23,1.23,0,0,1-.09.47,1.19,1.19,0,0,1-.25.38A1.25,1.25,0,0,1,41,2.3a1.2,1.2,0,0,1-.47.1,1.29,1.29,0,0,1-.48-.1,1.25,1.25,0,0,1-.38-.25,1.19,1.19,0,0,1-.25-.38,1.23,1.23,0,0,1-.09-.47,1.18,1.18,0,0,1,.09-.46,1.14,1.14,0,0,1,.25-.39,1.28,1.28,0,0,1,.38-.26A1.28,1.28,0,0,1,40.55,0,1.19,1.19,0,0,1,41,.09a1.28,1.28,0,0,1,.38.26,1.14,1.14,0,0,1,.25.39A1.18,1.18,0,0,1,41.74,1.2Z" />
            <path d="M51.88,10.63a6,6,0,0,1-1.12.32,6.72,6.72,0,0,1-1.21.11,5.16,5.16,0,0,1-1.63-.25,3.2,3.2,0,0,1-1.22-.74,3.14,3.14,0,0,1-.77-1.21,4.7,4.7,0,0,1-.27-1.66A4.55,4.55,0,0,1,46,5.54a3.57,3.57,0,0,1,.82-1.27A3.76,3.76,0,0,1,48,3.45a4.61,4.61,0,0,1,1.65-.28,8.63,8.63,0,0,1,1.25.08,6.52,6.52,0,0,1,.91.2V5.23a4,4,0,0,0-1-.36,4.21,4.21,0,0,0-1-.13,2.47,2.47,0,0,0-.92.17,2.09,2.09,0,0,0-.68.48,2.12,2.12,0,0,0-.42.75,3,3,0,0,0-.15,1,2.76,2.76,0,0,0,.16,1,1.92,1.92,0,0,0,.45.74,1.82,1.82,0,0,0,.69.46,2.37,2.37,0,0,0,.9.17,4.75,4.75,0,0,0,.51,0,3.29,3.29,0,0,0,.53-.1l.53-.14L51.88,9Z" />
            <path d="M61,7.05a5,5,0,0,1-.26,1.64A3.57,3.57,0,0,1,60.05,10a3.2,3.2,0,0,1-1.18.83,4,4,0,0,1-1.6.29,4.43,4.43,0,0,1-1.51-.24,3.08,3.08,0,0,1-1.14-.74,3.21,3.21,0,0,1-.71-1.24,5.24,5.24,0,0,1-.25-1.72,5,5,0,0,1,.26-1.65,3.55,3.55,0,0,1,.74-1.27,3.47,3.47,0,0,1,1.19-.82,4.11,4.11,0,0,1,1.57-.29A4.27,4.27,0,0,1,59,3.38a2.89,2.89,0,0,1,1.13.74,3.24,3.24,0,0,1,.71,1.23A5.16,5.16,0,0,1,61,7.05Zm-1.91,0a2.92,2.92,0,0,0-.45-1.78,1.53,1.53,0,0,0-1.32-.6,1.57,1.57,0,0,0-.81.19,1.52,1.52,0,0,0-.56.51,2.26,2.26,0,0,0-.32.76,4,4,0,0,0-.1.94,2.83,2.83,0,0,0,.48,1.79,1.57,1.57,0,0,0,1.31.61,1.55,1.55,0,0,0,.8-.19,1.42,1.42,0,0,0,.55-.51A2.34,2.34,0,0,0,59,8,4.06,4.06,0,0,0,59.13,7.09Z" />
            <path d="M67.17,10.92V6q0-1.26-.93-1.26a1.35,1.35,0,0,0-.89.38,6.88,6.88,0,0,0-.9,1v4.83H62.62V3.29h1.59l0,1.13a5.17,5.17,0,0,1,.49-.54,3.07,3.07,0,0,1,.54-.41,2.89,2.89,0,0,1,.63-.26,3,3,0,0,1,.76-.09,2.47,2.47,0,0,1,1,.2,1.79,1.79,0,0,1,.73.54,2.24,2.24,0,0,1,.44.84A3.75,3.75,0,0,1,69,5.8v5.12Z" />
            <path d="M77.37,8.67a2,2,0,0,1-.29,1.12,2.18,2.18,0,0,1-.76.75,3.68,3.68,0,0,1-1.08.42,5.74,5.74,0,0,1-1.24.13A11.73,11.73,0,0,1,72.48,11a9.59,9.59,0,0,1-1.27-.22V9.13a7.09,7.09,0,0,0,2.73.56A2.07,2.07,0,0,0,75,9.46a.68.68,0,0,0,.36-.6.65.65,0,0,0-.08-.31A.74.74,0,0,0,75,8.27,4.75,4.75,0,0,0,74.44,8c-.25-.09-.58-.19-1-.31a5.3,5.3,0,0,1-1-.36,2.31,2.31,0,0,1-.71-.47,1.72,1.72,0,0,1-.41-.62,2.18,2.18,0,0,1-.14-.84,1.93,1.93,0,0,1,.21-.88A2.11,2.11,0,0,1,72,3.8a3.25,3.25,0,0,1,1-.5,5.35,5.35,0,0,1,1.46-.18,9.63,9.63,0,0,1,1.28.08c.37,0,.7.1,1,.16v1.5a7.83,7.83,0,0,0-1.23-.3,8.71,8.71,0,0,0-1.13-.08,1.73,1.73,0,0,0-.91.2.66.66,0,0,0-.27.87.64.64,0,0,0,.26.26,2.94,2.94,0,0,0,.56.27c.24.09.55.19.94.3a6.91,6.91,0,0,1,1.11.41,2.57,2.57,0,0,1,.73.48,1.63,1.63,0,0,1,.39.62A2.37,2.37,0,0,1,77.37,8.67Z" />
            <path d="M86.41,7.05a5,5,0,0,1-.26,1.64A3.57,3.57,0,0,1,85.42,10a3.2,3.2,0,0,1-1.18.83,4,4,0,0,1-1.59.29,4.45,4.45,0,0,1-1.52-.24A3.17,3.17,0,0,1,80,10.11a3.34,3.34,0,0,1-.71-1.24A5.52,5.52,0,0,1,79,7.15a5,5,0,0,1,.26-1.65A3.57,3.57,0,0,1,80,4.23a3.33,3.33,0,0,1,1.18-.82,4.12,4.12,0,0,1,1.58-.29,4.21,4.21,0,0,1,1.52.26,2.93,2.93,0,0,1,1.14.74,3.22,3.22,0,0,1,.7,1.23A5.16,5.16,0,0,1,86.41,7.05Zm-1.91,0a2.92,2.92,0,0,0-.45-1.78,1.52,1.52,0,0,0-1.31-.6,1.61,1.61,0,0,0-.82.19,1.52,1.52,0,0,0-.56.51,2.49,2.49,0,0,0-.32.76,4,4,0,0,0-.1.94,2.83,2.83,0,0,0,.48,1.79,1.58,1.58,0,0,0,1.32.61,1.54,1.54,0,0,0,.79-.19,1.42,1.42,0,0,0,.55-.51A2.35,2.35,0,0,0,84.4,8,4.07,4.07,0,0,0,84.5,7.09Z" />
            <path d="M90.46,1.73H88.3V.31h4V9.49h2.21v1.43H88V9.49h2.42Z" />
            <path d="M103.1,6.52c0,.12,0,.29,0,.49s0,.39,0,.56H98a2.44,2.44,0,0,0,.15.89,1.7,1.7,0,0,0,.44.64,1.85,1.85,0,0,0,.67.39,2.71,2.71,0,0,0,.88.14,8.58,8.58,0,0,0,1.19-.09,9.63,9.63,0,0,0,1.31-.28v1.46l-.63.15-.71.12-.73.08-.71,0a4.73,4.73,0,0,1-1.56-.24,3.12,3.12,0,0,1-1.18-.74,3.19,3.19,0,0,1-.73-1.19,4.94,4.94,0,0,1-.26-1.66,5.3,5.3,0,0,1,.26-1.69,4,4,0,0,1,.72-1.31,3.25,3.25,0,0,1,1.13-.84,3.56,3.56,0,0,1,1.48-.3,3.81,3.81,0,0,1,1.44.26,2.81,2.81,0,0,1,1,.7,3.11,3.11,0,0,1,.64,1.07A4,4,0,0,1,103.1,6.52Zm-1.86-.26a2.34,2.34,0,0,0-.12-.79,1.63,1.63,0,0,0-.32-.55,1.27,1.27,0,0,0-.48-.32,1.67,1.67,0,0,0-.59-.11A1.54,1.54,0,0,0,98.59,5a2.15,2.15,0,0,0-.52,1.31Z" />
            <path d="M103.86,14V12.55h8.46V14Z" />
          </g>
        </svg>
      </Link>
    </div>
  );
}
