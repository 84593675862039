import React from 'react';
import Select from '../select';

export const MonthSelect = (props) => {
  const getOptions = () => {
    const result = [];
    const startM = 1;
    const endM = 12;
    for (let i = startM; i <= endM; i++) {
      result.push(
        // <option key={i} value={i} selected={i === props.defaultValue}>
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return result;
  };

  return (
    <>
      <Select {...props}>
        {props.placeholder && (
          <option value="" disabled selected>
            {props.placeholder}
          </option>
        )}
        {getOptions()}
      </Select>
    </>
  );
};

export default MonthSelect;
