const code = {
  A01000000: { code: 'A01000000', upCode: null, name: '의료기기' },
  A05000000: { code: 'A05000000', upCode: null, name: '보험-치료재료' },
  A04000000: { code: 'A04000000', upCode: null, name: '체외진단용기기' },
  A03000000: { code: 'A03000000', upCode: null, name: '임상시험' },
  A02000000: { code: 'A02000000', upCode: null, name: '의약품' },
  A01006000: {
    code: 'A01006000',
    upCode: 'A01000000',
    name: '수리(repair)',
    upCodeInfo: [{ code: 'A01000000', upCode: null, name: '의료기기' }]
  },
  A01005000: {
    code: 'A01005000',
    upCode: 'A01000000',
    name: '영업',
    upCodeInfo: [{ code: 'A01000000', upCode: null, name: '의료기기' }]
  },
  A01004000: {
    code: 'A01004000',
    upCode: 'A01000000',
    name: '기획 / 마케팅',
    upCodeInfo: [{ code: 'A01000000', upCode: null, name: '의료기기' }]
  },
  A01003000: {
    code: 'A01003000',
    upCode: 'A01000000',
    name: '수탁제조(OEM)',
    upCodeInfo: [{ code: 'A01000000', upCode: null, name: '의료기기' }]
  },
  A01002002: {
    code: 'A01002002',
    upCode: 'A01002000',
    name: 'Digital Therapeutics',
    upCodeInfo: [
      { code: 'A01000000', upCode: null, name: '의료기기' },
      { code: 'A01002000', upCode: 'A01000000', name: '개발' }
    ]
  },
  A01002001: {
    code: 'A01002001',
    upCode: 'A01002000',
    name: 'S/W (SaMD)',
    upCodeInfo: [
      { code: 'A01000000', upCode: null, name: '의료기기' },
      { code: 'A01002000', upCode: 'A01000000', name: '개발' }
    ]
  },
  A01002000: {
    code: 'A01002000',
    upCode: 'A01000000',
    name: '개발',
    upCodeInfo: [{ code: 'A01000000', upCode: null, name: '의료기기' }]
  },
  A01001004: {
    code: 'A01001004',
    upCode: 'A01001000',
    name: 'Other',
    upCodeInfo: [
      { code: 'A01000000', upCode: null, name: '의료기기' },
      { code: 'A01001000', upCode: 'A01000000', name: '컨설팅' }
    ]
  },
  A01001003: {
    code: 'A01001003',
    upCode: 'A01001000',
    name: '보험',
    upCodeInfo: [
      { code: 'A01000000', upCode: null, name: '의료기기' },
      { code: 'A01001000', upCode: 'A01000000', name: '컨설팅' }
    ]
  },
  A01001002: {
    code: 'A01001002',
    upCode: 'A01001000',
    name: 'GMP',
    upCodeInfo: [
      { code: 'A01000000', upCode: null, name: '의료기기' },
      { code: 'A01001000', upCode: 'A01000000', name: '컨설팅' }
    ]
  },
  A01001001: {
    code: 'A01001001',
    upCode: 'A01001000',
    name: '인허가',
    upCodeInfo: [
      { code: 'A01000000', upCode: null, name: '의료기기' },
      { code: 'A01001000', upCode: 'A01000000', name: '컨설팅' }
    ]
  },
  A01001000: {
    code: 'A01001000',
    upCode: 'A01000000',
    name: '컨설팅',
    upCodeInfo: [{ code: 'A01000000', upCode: null, name: '의료기기' }]
  },
  A06000000: { code: 'A06000000', upCode: null, name: '해외인증' }
};
export default code;
