import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';
import { setSession, getToken } from '../../../utils/session';

// index
export const projectsIndex = createAsyncThunk(
  '/projects/index',
  ({ reqData }, thunkAPI) => {
    console.log('=== reqData', reqData);
    return axios
      .get('/projects', {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        },
        params: {
          page: reqData?.page || 1,
          sword: reqData?.sword,
          workTypes: reqData?.workTypes,
          fields: reqData?.fields
        }
      })
      .then((response) => {
        console.log('response.data', response.data);
        console.log('response.data.data.user', response.data.data?.user);
        setSession(response.data.data.user);
        if (response.data.success && response.data.error !== 'auth') {
          setSession(response.data.data?.user);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

// show
export const projectsShow = createAsyncThunk(
  '/projects/show',
  ({ projectId }, thunkAPI) => {
    const url = `/projects/${projectId}`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        console.log('response.data', response.data);
        console.log('response.data.data.user', response.data.data?.user);
        if (response.data.success && response.data.error !== 'auth') {
          setSession(response.data.data?.user);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

// create
export const projectsCreate = createAsyncThunk(
  '/projects/create',
  (_, thunkAPI) => {
    const url = `/projects/create`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        console.log('response.data', response.data);
        console.log('response.data.data.user', response.data.data?.user);
        if (response.data.success && response.data.error !== 'auth') {
          setSession(response.data.data?.user);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

// const url = process.env.REACT_APP_BASE_URL + path;
// const postData = new FormData();
// postData.append('subject', formData.subject);
// postData.append('amount', formData.amount);
// postData.append('period', formData.period);
// postData.append('deadlineAt', formData.deadline_at.format('YYYY-MM-DD'));
// postData.append('startAt', formData.start_at.format('YYYY-MM-DD'));
// postData.append('workType', formData.work_type);
// postData.append('workContents', formData.work_contents);
// postData.append('workEnvironment', formData.work_environment);
// postData.append('companyTypes', companyTypes.join(','));
// postData.append('meetingLocations', meetingLocations.join(','));
// postData.append('fields', fields.join(','));
// postData.append('meetingOnline', formData.meeting_online ? '1' : '0');
//moment(data.deadlineAt).format('YYYY-MM-DD')

// store
export const projectsStore = createAsyncThunk(
  '/projects/store',
  ({ reqData }, thunkAPI) => {
    // console.log('xxx', 'xxx');
    const url = `/projects`;
    const formData = {
      subject: reqData.subject,
      amount: reqData.amount,
      period: reqData.period,
      deadlineAt: moment(reqData.deadlineAt).format('YYYY-MM-DD'),
      startAt: moment(reqData.startAt).format('YYYY-MM-DD'),
      workType: reqData.workType,
      workContents: reqData.workContents,
      workEnvironment: reqData.workEnvironment,
      companyTypes: reqData.companyTypes,
      meetingLocations: reqData.meetingLocations,
      fields: reqData.fields,
      meetingOnline: reqData.meetingOnline
    };

    console.log('formData()', formData);
    console.log('getToken()', getToken());
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        // 'content-type': 'multipart/form-data'
        'Content-Type': 'application/json'
      }
    };
    return axios
      .post(url, formData, config)
      .then((response) => {
        console.log('response.data', response.data);
        console.log('response.data.data.user', response.data.data?.user);
        if (response.data.success && response.data.error !== 'auth') {
          setSession(response.data.data?.user);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

// edit
export const projectsEdit = createAsyncThunk(
  '/projects/edit',
  ({ projectId }, thunkAPI) => {
    const url = `/projects/${projectId}/edit`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        console.log('response.data', response.data);
        console.log('response.data.data.user', response.data.data?.user);
        if (response.data.success && response.data.error !== 'auth') {
          setSession(response.data.data?.user);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

// update
export const projectsUpdate = createAsyncThunk(
  '/projects/update',
  ({ projectId, reqData }, thunkAPI) => {
    const url = `/projects/${projectId}`;
    const formData = {
      companyName: reqData.companyName,
      department: reqData.department,
      jobTitle: reqData.jobTitle,
      hireYear: reqData.hireYear,
      hireMonth: reqData.hireMonth,
      leaveYear: reqData.leaveYear,
      leaveMonth: reqData.leaveMonth,
      work: reqData.work
    };

    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        // 'content-type': 'multipart/form-data'
        'Content-Type': 'application/json'
      }
    };
    return axios
      .post(url, formData, config)
      .then((response) => {
        console.log('response.data', response.data);
        console.log('response.data.data.user', response.data.data?.user);
        if (response.data.success && response.data.error !== 'auth') {
          setSession(response.data.data?.user);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const projectsSlice = createSlice({
  name: 'projects',
  initialState: {
    indexStatus: '',
    indexResult: {},
    indexData: {},
    // indexPage: 0,
    showStatus: '',
    showResult: {},
    showData: {},
    createStatus: '',
    createResult: {},
    createData: {},
    storeStatus: '',
    storeResult: {},
    storeData: {},
    editStatus: '',
    editResult: {},
    editData: {},
    updateStatus: '',
    updateResult: {},
    updateData: {}
  },
  reducers: {
    clearState: (state) => {
      state.indexStatus = '';
      state.indexResult = {};
      state.indexData = {};
      // state.indexPage = 0;
      state.showStatus = '';
      state.showResult = {};
      state.showData = {};
      state.createStatus = '';
      state.createResult = {};
      state.createData = {};
      state.storeStatus = '';
      state.storeResult = '';
      state.storeData = {};
      state.editStatus = '';
      state.editResult = {};
      state.editData = {};
      state.updateStatus = '';
      state.updateResult = {};
      state.updateData = {};
      return state;
    }
  },
  extraReducers: {
    [projectsIndex.pending]: (state) => {
      state.indexStatus = 'loading';
    },
    [projectsIndex.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.indexStatus = 'completed';
      state.indexResult = {
        success: payload?.success,
        message: payload?.message,
        error: payload?.error
      };
      state.indexData = payload?.data?.pageData;
    },
    [projectsIndex.rejected]: (state, { payload }) => {
      state.indexStatus = 'error';
    },
    [projectsShow.pending]: (state) => {
      state.showStatus = 'loading';
    },
    [projectsShow.fulfilled]: (state, { payload }) => {
      console.log('payload edit', payload);
      state.showStatus = 'completed';
      state.showResult = {
        success: payload?.success,
        message: payload?.message,
        error: payload?.error
      };
      state.showData = payload?.data?.pageData;
      if (payload && payload.data && payload.data.pageData) {
        state.showData.diffDeadlineDays = moment(
          payload.data.pageData.deadlineAt
        ).diff(moment(), 'days');
        state.showData.diffCreatedMinute = moment().diff(
          moment(payload.data.pageData.createdAt),
          'minutes'
        );
      }
    },
    [projectsShow.rejected]: (state, { payload }) => {
      state.showStatus = 'error';
    },
    [projectsCreate.pending]: (state) => {
      state.createStatus = 'loading';
    },
    [projectsCreate.fulfilled]: (state, { payload }) => {
      console.log('payload edit', payload);
      state.createStatus = 'completed';
      state.createResult = {
        success: payload?.success,
        message: payload?.message,
        error: payload?.error
      };
      state.createData = payload?.data?.pageData;
    },
    [projectsCreate.rejected]: (state, { payload }) => {
      state.createStatus = 'error';
    },
    [projectsStore.pending]: (state) => {
      state.storeStatus = 'loading';
    },
    [projectsStore.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.storeStatus = 'completed';
      state.storeResult = {
        success: payload?.success,
        message: payload?.message,
        error: payload?.error
      };
    },
    [projectsStore.rejected]: (state, { payload }) => {
      state.storeStatus = 'error';
    },

    [projectsEdit.pending]: (state) => {
      state.editStatus = 'loading';
    },
    [projectsEdit.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.editStatus = 'completed';
      state.editResult = {
        success: payload?.success,
        message: payload?.message,
        error: payload?.error
      };
      state.editData = payload?.data?.pageData;
    },
    [projectsEdit.rejected]: (state, { payload }) => {
      state.editStatus = 'error';
    },
    [projectsUpdate.pending]: (state) => {
      state.updateStatus = 'loading';
    },
    [projectsUpdate.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.updateStatus = 'completed';
      state.updateResult = {
        success: payload?.success,
        message: payload?.message,
        error: payload?.error
      };
    },
    [projectsUpdate.rejected]: (state, { payload }) => {
      state.updateStatus = 'error';
    }
  }
});

export const { clearState } = projectsSlice.actions;
export const projectsSelector = (state) => state.projects;
export default projectsSlice.reducer;
