import { yellow } from '@material-ui/core/colors';
import React from 'react';
import { workTypeName } from '../../../utils/codeView';

export const workTypeBadge = (props) => {
  const { code } = props;
  // code : IN OUT
  return (
    <div
      className={`${
        code === 'IN' ? 'bg-yellow-400' : 'bg-indigo-400'
      } py-1 px-4 rounded-2xl text-xs text-white mr-2 max-h-6`}
    >
      {workTypeName(code)}
    </div>
  );
};

export default workTypeBadge;
