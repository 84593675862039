import React from 'react';
import { NewIcon } from '../../../components/icon';

export const NewBadge = (props) => {
  const { diffCreatedMinute } = props;
  // console.log('diffCreatedMinute', diffCreatedMinute);
  return (
    <div className="mr-4">
      {diffCreatedMinute > process.env.REACT_APP_NEW_DAYS * 24 * 60 && (
        <NewIcon className="text-yellow-700" />
      )}
    </div>
  );
};

export default NewBadge;
