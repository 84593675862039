import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  postsCreate,
  postsStore,
  postsSelector,
  clearState
} from '../Index/postsSlice';
import moment from 'moment';
import Loading from '../../../components/loading';
import {
  TextSm,
  Title2Xl,
  TitleBase,
  TitleSm,
  TitleXl
} from '../../../components/title';
import Label from '../../../components/label';
import Input from '../../../components/input';
import Textarea from '../../../components/textarea';
import Button from '../../../components/button';
import CodeSelect from '../../../components/codeSelect';
import categoriesCode from '../../../code/categories';

const ContentContiner = styled.div`
  ${tw`
    h-full
    items-center
    ml-66
    py-5 
    px-10
    rounded
    border
    border-gray-300
    bg-white
  `};
`;

export default function Content(props) {
  // page
  const postId = props.match.params.id;

  // redux
  const dispatch = useDispatch();
  const {
    createStatus,
    createMessage,
    createData,
    storeStatus,
    storeMessage,
    storeData
  } = useSelector(postsSelector);

  // form
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    getValues,
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    console.log('DDDD Data', data);
    console.log(
      'DDDD deadlineAt',
      moment(data.deadlineAt).format('YYYY-MM-DD')
    );
    dispatch(postsStore({ reqData: data }));
  };

  // page store
  useEffect(() => {
    console.log('submitStatus 1', storeStatus);
    if (storeStatus === 'completed') {
      // toast.success('완료 되었습니다');
      // refresh();
      // editClose();
    }
    return () => {};
  }, [storeStatus]);

  // page data
  const [pageLoading, setPageLoading] = useState(true);
  useEffect(() => {
    setPageLoading(true);
    dispatch(postsCreate());
    return () => {
      //
    };
  }, []);

  useEffect(() => {
    // console.log('showMessage2', showMessage);
    if (createStatus === 'completed') {
      // 로그인 자료 없으면 로그인으로 보낸다. (아무나)
      if (!createMessage && createMessage !== 'success') {
        // alert(indexMessage);
        // goLogin(history);
      } else {
        // setTotalPage(Math.ceil(rData.totalCount / rData.limitCount));
        setPageLoading(false);
      }
    }
    return () => {
      //
    };
  }, [createStatus]);

  return pageLoading ? (
    <ContentContiner>
      <Loading />
    </ContentContiner>
  ) : (
    <ContentContiner>
      <div className="flex rounded relative mt-5">
        <TitleXl>등록</TitleXl>
      </div>
      <div className="mt-8 text-center sm:mt-8 sm:ml-0 sm:mr-0 sm:text-left">
        <form id="form" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Label htmlFor="subject">제목</Label>
            <Controller
              render={({ field, fieldState }) => (
                <Input
                  id="subject"
                  value={field.value}
                  onChange={field.onChange}
                  inputRef={field.ref} // wire up the input ref
                  placeholder="제목을 입력해 주세요"
                  error={fieldState.error}
                  errorMessage={errors.subject && errors.subject.message}
                />
              )}
              name="subject"
              control={control}
              rules={{ required: '제목을 입력해 주세요' }}
            />
          </div>
          <div className="mt-5">
            <Label htmlFor="category">분류</Label>
            <Controller
              render={({ field, fieldState }) => (
                <CodeSelect
                  id="category"
                  value={field.value}
                  onChange={field.onChange}
                  inputRef={field.ref} // wire up the input ref
                  placeholder="분류를 선택해 주세요"
                  error={fieldState.error}
                  errorMessage={errors.category && errors.category.message}
                  data={categoriesCode}
                />
              )}
              name="category"
              control={control}
              rules={{ required: '분류를 선택해 주세요' }}
            />
          </div>
          <div className="mt-5">
            <Label htmlFor="content">내용</Label>
            <Controller
              render={({ field, fieldState }) => (
                <Textarea
                  id="content"
                  value={field.value}
                  onChange={field.onChange}
                  inputRef={field.ref} // wire up the input ref
                  placeholder="내용을 입력해 주세요"
                  error={fieldState.error}
                  errorMessage={errors.content && errors.content.message}
                  rows={10}
                />
              )}
              name="content"
              control={control}
              rules={{ required: '내용을 입력해 주세요' }}
            />
          </div>
          <div className="mt-10 mb-10">
            <Button full type="submit" form="form">
              확인
            </Button>
          </div>
        </form>
      </div>
    </ContentContiner>
  );
}
