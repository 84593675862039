import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import tw, { css } from 'twin.macro';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

import { Title2, Title3 } from '../../../components/title';
import Button, { IconButton } from '../../../components/button';
import { getToken } from '../../../utils/session';
import { toast } from 'react-toastify';

export default function Delete(props) {
  const { open, deleteClose, technologyId, refresh } = props;

  // page
  const [pageLoading, setPageLoading] = useState(true);
  // const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   getPage();
  //   setOpen(true);
  // };

  const handleClose = (e, reason) => {
    // setOpen(false);
    deleteClose();
  };

  const serviceDelete = (url) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json'
      }
    };
    return axios.delete(url, config).then((response) => response.data);
  };

  const handleDelete = () => {
    const url = `/my-partners/technology/${technologyId}`;

    serviceDelete(url).then((response) => {
      console.log('delete response', response);
      if (response.success) {
        const user = response.data.user;
        toast.success('삭제 되었습니다.');
        refresh();
        deleteClose();
      } else {
        //
      }
    });
  };

  useEffect(() => {
    setPageLoading(false);
  }, []);

  return pageLoading ? (
    <></>
  ) : (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">삭제</DialogTitle>
      <DialogContent>
        <Title3>선택한 기술정보를 삭제 하시겠습니까?</Title3>
      </DialogContent>
      <DialogActions className="flex w-full p-0 bg-gray-100 mt-5">
        <div className="w-full m-0 bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse flow-root">
          <IconButton
            type="button"
            onClick={() => handleDelete()}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm"
            red
          >
            확인
          </IconButton>
          <IconButton
            type="button"
            onClick={handleClose}
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            취소
          </IconButton>
        </div>
      </DialogActions>
    </Dialog>
  );
}

// export default FormDialog;
