import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { useSelector, useDispatch } from 'react-redux';
import {
  projectsShow,
  projectsSelector,
  clearState
} from '../Index/projectsSlice';

import { CheckIcon, CommentIcon, FavoriteIcon } from '../../../components/icon';
import RecruitmentBadge from '../Index/recruitmentBadge';
import WorkTypeBadge from '../Index/workTypeBadge';

import Loading from '../../../components/loading';
import NewBadge from '../Index/newBadge';
import moment from 'moment';
import nl2br from 'react-nl2br';
import { sprintf } from 'sprintf-js';
import {
  companyTypeName,
  fieldNames2,
  workTypeName
} from '../../../utils/codeView';
import { basePrice } from '../../../utils/priceFormat';
import FavoriteBotton from '../../../components/favoriteButton';
import goLogin from '../../../utils/goLogin';
import { useHistory } from 'react-router';

const ContentContiner = styled.div`
  ${tw`
    h-full
    items-center
    // py-5 
    // px-16
    // rounded
    // border
    // border-gray-300
    // bg-white
  `};
`;

const Td = styled.td`
  ${tw`
    relative
    py-4 px-10
    text-base
    text-gray-800
    border-b
    border-gray-200
    w-3/4
  `}
  ${({ header }) =>
    header && tw`w-1/4 p-0 text-center bg-gray-100 font-semibold`}
  ${({ start }) => start && tw`border-0`}
`;

const companyTypeViews = (companyTypes) => {
  const arr = companyTypes.split(',');
  return arr.map((code, i) => {
    return (
      <>
        {i !== 0 && ` / `} {companyTypeName(code)}
      </>
    );
  });
};

const meetingLocationViews = (meetingLocations) => {
  const arr = meetingLocations.split(',');
  return arr.map((code, i) => {
    return (
      <>
        {i !== 0 && ` / `} {code}
      </>
    );
  });
};

export default function Content(props) {
  // page
  const projectId = props.match.params.id;

  // auth
  const history = useHistory();

  // redux
  const dispatch = useDispatch();
  const { showStatus, showResult, showData } = useSelector(projectsSelector);

  // page data
  const [pageLoading, setPageLoading] = useState(true);
  useEffect(() => {
    setPageLoading(true);
    dispatch(projectsShow({ projectId: projectId }));
    return () => {
      //
    };
  }, [projectId]);

  useEffect(() => {
    if (showStatus === 'completed') {
      if (!showResult.success) {
        alert(showResult.message);
        // if (showResult.error === 'auth') {
        //   goLogin(history);
        // }
      } else {
        setPageLoading(false);
        // setRowsLoading(false);
      }
    }
    return () => {
      //
    };
  }, [showStatus]);

  return pageLoading ? (
    <ContentContiner>
      <Loading />
    </ContentContiner>
  ) : (
    <ContentContiner>
      <div className="flex items-center mt-8 rounded relative">
        <NewBadge diffCreatedMinute={showData.diffCreatedMinute} />
        <WorkTypeBadge code={showData.workType} />
        <RecruitmentBadge diffDeadlineDays={showData.diffDeadlineDays} />
        <div className="flex-grow"></div>
        <div className="text-sm">
          작성 | {moment(showData.createdAt).format('YYYY.MM.DD')}
        </div>
        <div className="py-0 text-sm ml-5">
          <FavoriteBotton
            checked={showData.favorite}
            cnt={showData.favoriteCnt}
            favType="project"
            favId={showData.id}
          />
        </div>
      </div>
      <div className="flex rounded relative mt-10 font-medium text-2xl">
        {showData.subject}
      </div>

      <div className="flex mt-12">
        <div className="w-24 h-full">
          <div className="w-24 h-24 border border-gray-400 rounded-xl flex">
            <img
              className="m-auto rounded-xl"
              src={`${process.env.REACT_APP_IMG_URL}/${showData.client.image}`}
              // src={`http://localhost:3002/images/partners/sample/145793_20200612_792cdf4c317e4213.png`}
              alt="client"
            />
          </div>
        </div>
        <div className="w-1/2 pl-8 bg-gray-51">
          <div className="flex py-1 items-center">
            <div className="text-sm w-24">클라이언트</div>
            <div className="text-base font-medium">
              {showData.client.name} (
              {companyTypeName(showData.client.companyTypeCode)})
            </div>
          </div>
          <div className="flex py-1 items-center">
            <div className="text-sm w-24">예상 금액</div>
            <div className="text-base font-medium mr-10">
              {basePrice(showData.amount)}
            </div>
            <div className="text-sm w-24">예상 기간</div>
            <div className="text-base font-medium">4 일</div>
          </div>
          <div className="flex py-1 items-center">
            <div className="text-sm w-24">희망파트너스</div>
            <div className="text-base font-medium">
              {companyTypeViews(showData.companyTypes)}
            </div>
          </div>
        </div>
        <div className="w-1/2 pl-10 border-l border-gray-400 bg-gray-101">
          <div className="flex py-1 items-center">
            <div className="text-sm mr-4">모집마감일</div>
            <div className="text-base font-medium mr-4">
              {moment(showData.deadlineAt).format('YYYY년 MM월 DD일')}{' '}
              <span>(마감 {showData.diffDeadlineDays}일 전)</span>
            </div>
            <div className="text-sm mr-4">지원자</div>
            <div className="text-base font-medium">10명</div>
          </div>
          <div className="mt-4 mr-0">
            <div className="bg-yellow-600 py-2 w-full rounded-lg text-white text-center text-sm">
              프로젝트 지원하기
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12 flex border-t-2 border-b border-gray-400 px-1 py-4 text-xl font-medium">
        프로젝트 상세내용
      </div>
      <div className="mt-0 flex border-b px-1 py-4">
        <div className="w-56 text-lg font-medium">분야</div>
        <div className="">{fieldNames2(showData.fields)}</div>
      </div>
      <div className="flex border-b px-1 py-4">
        <div className="w-56 text-lg font-medium">예상 금액</div>
        <div className="">{basePrice(showData.amount)}</div>
      </div>
      <div className="flex border-b px-1 py-4">
        <div className="w-56 text-lg font-medium">예상 기간</div>
        <div className="">{showData.period} 일</div>
      </div>
      <div className="flex border-b px-1 py-4">
        <div className="w-56 text-lg font-medium">모집 마감일</div>
        <div className="">
          {moment(showData.deadlineAt).format('YYYY년 MM월 DD일')}{' '}
          <span>(마감 {showData.diffDeadlineDays}일 전)</span>
        </div>
      </div>
      <div className="flex border-b px-1 py-4">
        <div className="w-56 text-lg font-medium">예상 시작일</div>
        <div className="">
          {moment(showData.startAt).format('YYYY년 MM월 DD일')}
        </div>
      </div>
      <div className="flex border-b px-1 py-4">
        <div className="w-56 text-lg font-medium">상주 / 외주</div>
        <div className="">{workTypeName(showData.workType)}</div>
      </div>
      <div className="flex border-b px-1 py-4">
        <div className="w-56 text-lg font-medium">희망 파트너스</div>
        <div className="">{companyTypeViews(showData.companyTypes)}</div>
      </div>
      <div className="flex border-b px-1 py-4">
        <div className="w-56 text-lg font-medium">미팅가능 위치</div>
        <div className="">
          {showData.meetingOnline === 1 && '온라인 / '}
          {meetingLocationViews(showData.meetingLocations)}
        </div>
      </div>
      <div className="flex border-b px-1 py-4">
        <div className="w-56 text-lg font-medium">요청 내용 및 현재 상황</div>
        <div className="">{nl2br(showData.workContents)}</div>
      </div>
      <div className="flex border-b px-1 py-4">
        <div className="w-56 text-lg font-medium">
          미팅 조건
          <br />
          (상주인 경우 근무조건)
        </div>
        <div className="">{nl2br(showData.workEnvironment)}</div>
      </div>
    </ContentContiner>
  );
}
