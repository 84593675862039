import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { useSelector, useDispatch } from 'react-redux';
import {
  partnersShow,
  partnersSelector,
  clearState
} from '../Index/partnersSlice';

import {
  CheckIcon,
  CommentIcon,
  FavoriteIcon,
  StarIcon,
  StoreIcon
} from '../../../components/icon';
import { TextSm, Title2Xl, TitleBase } from '../../../components/title';
import RecruitmentBadge from '../Index/recruitmentBadge';
import WorkTypeBadge from '../Index/workTypeBadge';

import Loading from '../../../components/loading';
import NewBadge from '../Index/newBadge';
import moment from 'moment';
import nl2br from 'react-nl2br';
import {
  companyTypeName,
  experienceName,
  fieldName2,
  fieldNames2,
  techLevelName,
  workTypeName
} from '../../../utils/codeView';
import { Link } from 'react-router-dom';
import priceView from '../../Goods/Index/priceView';
import FavoriteBotton from '../../../components/favoriteButton';

const ContentContiner = styled.div`
  ${tw`
    h-full
    items-center
    // py-5 px-16
    // rounded
    // border
    // border-gray-300
    // bg-gray-100
  `};
`;

const companyTypeViews = (companyTypes) => {
  const arr = companyTypes.split(',');
  return arr.map((code, i) => {
    return (
      <>
        {i !== 0 && ` / `} {companyTypeName(code)}
      </>
    );
  });
};

const meetingLocationViews = (meetingLocations) => {
  const arr = meetingLocations.split(',');
  return arr.map((code, i) => {
    return (
      <>
        {i !== 0 && ` / `} {code}
      </>
    );
  });
};

export default function Content(props) {
  // page
  const partnerId = props.match.params.id;

  // redux
  const dispatch = useDispatch();
  const { showStatus, showResult, showData } = useSelector(partnersSelector);

  // page data
  const [pageLoading, setPageLoading] = useState(true);
  useEffect(() => {
    setPageLoading(true);
    dispatch(partnersShow({ partnerId: partnerId }));
    return () => {
      //
    };
  }, [partnerId]);

  useEffect(() => {
    if (showStatus === 'completed') {
      if (!showResult.success) {
        alert(showResult.message);
        // if (showResult.error === 'auth') {
        //   goLogin(history);
        // }
      } else {
        setPageLoading(false);
        // setRowsLoading(false);
      }
    }
    return () => {
      //
    };
  }, [showStatus]);

  return pageLoading ? (
    <ContentContiner>
      <Loading />
    </ContentContiner>
  ) : (
    <ContentContiner>
      <div className="flex pt-10 pb-8">
        <div className="flex-grow text-xl font-semibold">
          {/* 한줄 요약   */}
          {showData.summary}
        </div>

        <div>
          <FavoriteBotton
            checked={showData.favorite}
            cnt={showData.favoriteCnt}
            favType="partner"
            favId={showData.id}
          />
        </div>
      </div>
      <div className="flex">
        <div className="w-56 h-full">
          <div className="w-44 h-44 border border-gray-200 rounded-xl flex">
            <img
              className="m-auto"
              src={`${process.env.REACT_APP_IMG_URL}/${showData.image}`}
              alt="partner"
            />
          </div>
        </div>
        <div className="flex-grow">
          <div className="flex items-center">
            <div className="text-sm w-24">파트너스명</div>
            <div className="text-lg font-semibold">
              {showData.name} ({showData.companyTypeCode}){' '}
            </div>
            <div>{showData.isActivity}</div>
          </div>
          <div className="flex items-center mt-5">
            <div className="text-sm w-24">전문분야</div>
            <div>인허가 &gt; 인허가 &gt; 보험 (총 10개분야)</div>
          </div>
          <div className="flex items-center mt-5">
            <div className="text-sm w-24">지역</div>
            <div className="text-sm">
              {showData.addressState} &gt; {showData.addressCity}
            </div>
          </div>
          <div className="flex items-center mt-5">
            <div className="text-sm w-24">상주 여부</div>
            <div className="text-sm">
              {showData.inWork === 1 ? `가능` : `불가`}
            </div>
          </div>
          <div className="flex items-center mt-5">
            <div className="text-sm w-24">세금계산서</div>
            <div className="text-sm">
              {showData.issueTax === 1 ? `발행가능` : `발행불가`}
            </div>
          </div>
        </div>
        <div className="w-52 border-l border-gray-400 pl-6">
          <div className="flex">
            <div className="text-sm font-normal flex-grow">프로젝트 계약</div>
            <div className="text-sm font-normal">11건</div>
          </div>
          <div className="flex mt-3">
            <div className="text-sm font-normal flex-grow">등록된 상품</div>
            <div className="text-sm font-normal">11건</div>
          </div>
          <div className="flex mt-3">
            <div className="text-sm font-normal flex-grow">평가</div>
            <div className="mr-3 flex h-6">
              <StarIcon className="text-yellow-500" />
              <span className="pl-1 pt-0.5 text-sm">4.8</span>
            </div>
            <div className="text-sm font-normal">11건</div>
          </div>
          <div className="mt-6">
            <div className="py-2 w-44 rounded-lg border border-yellow-600 text-yellow-600 text-center text-sm">
              문의하기
            </div>
          </div>
          <div className="mt-2">
            <div className="flex justify-center1 py-2 w-44 text-center text-sm text-blue-800 font-medium">
              <StoreIcon /> <span className="pl-1">instargram.com/@makeit</span>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-16 border-t-2 border-gray-400 border-b">
        <div className="flex">
          <div className="w-1/3 text-center">
            <a
              href="#introduction"
              className="block font-normal border-b-2 border-gray-800 py-3"
            >
              {showData.name} 소개
            </a>
          </div>
          <div className="w-1/3 text-center py-3">
            <a href="#another-goods" className="font-normal">
              기술/자격/교육정보(2)
            </a>
          </div>
          <div className="w-1/3 text-center py-3">
            <a href="#rating" className="font-normal">
              등록된 상품(12)
            </a>
          </div>
        </div>
      </div>
      <div id="introduction" className="border-b border-gray-600">
        <div className="px-1 py-4">
          <div className="text-2xl font-medium mt-4">{showData.name} 소개</div>
          <div className="mt-6">
            {showData.introduce}
            {/* 사진
            <br />
            사진
            <br />
            사진
            <br />
            사진
            <br />
            "브랜드에 적합한 콘텐츠를 찾고 있으신가요?" <br />
            안녕하세요. <br />
            홍익대학교 디자인 전공과 광고홍보학 부전공을 한 김민아 입니다.{' '}
            <br />
            적절한 기획, 콘텐츠을 만들기 위해서는 <br />
            브랜드에 대한 완벽한 이해가 필요합니다. <br />
            또한, <br />
            적합한 브랜드 기획과 스토리텔링이 기반 되어야합니다. <br />
            이를 위해 프리랜서 디자이너 활동을 중단하고 <br />
            광고기획, 전시기획, 유튜브 콘텐츠 작가, 콘텐츠 마케팅 등의 업무를{' '}
            <br />
            직접 경험한 뒤, 돌아왔습니다. <br /> */}
          </div>
        </div>
      </div>
      <div id="rating" className="border-b border-gray-600">
        <div className="px-1 py-4">
          <div className="text-2xl font-medium mt-4">기술/자격/교육정보(2)</div>
          <div className="mt-6">
            <div className="border-b pb-2 mb-2">
              {showData.technology.map((row) => {
                return (
                  <div className="mt-1">
                    <span>[기술정보]</span>
                    <span className="font-medium px-3">{row.name}</span>
                    <span className="bg-gray-200">
                      {fieldName2(row.fieldCode)}
                    </span>
                    <span className="pl-3 pr-3">
                      {techLevelName(row.techLevel)}
                    </span>
                    ({experienceName(row.experience)})
                  </div>
                );
              })}
            </div>
            <div className="border-b pb-2 mb-2">
              {showData.license.map((row) => {
                return (
                  <div className="mt-1">
                    <span>[자격정보]</span>
                    <span className="font-medium px-3">{row.name}</span>
                    <span className="bg-gray-200">{row.issuer}</span>
                    <span className="pl-3 pr-3">
                      {row.issueYear} 년 {row.issueMonth} 월
                    </span>
                  </div>
                );
              })}
            </div>
            <div>
              {showData.education.map((row) => {
                return (
                  <div className="mt-1">
                    <span>[교육정보]</span>
                    <span className="font-medium px-3">{row.name}</span>
                    <span className="bg-gray-200">{row.academy}</span>
                    <span className="pl-3 pr-3">
                      {row.endYear} 년 {row.endMonth} 월
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div id="another-goods" className="border-b border-gray-600">
        <div className="px-1 py-4">
          <div className="text-2xl font-medium mt-4">등록된 상품</div>
          <div className="mt-6">
            abcd : {showData.goods.length}
            {showData.goods &&
              showData.goods.map((row, i) => {
                return (
                  <div
                    className={`flex py-2 border-b ${i === 0 && `border-t`}`}
                  >
                    <div className="bg-yellow-1001 w-24">
                      <div className="w-20 h-20 border border-gray-200 rounded-xl flex">
                        <img
                          className="m-auto"
                          src={`${process.env.REACT_APP_IMG_URL}/${row.image}`}
                        />
                      </div>
                    </div>
                    <div className="bg-green-501 flex-grow">
                      <div className="text-base font-medium mt-2">
                        <Link
                          to={`/goods/${row.id}`}
                          className="text-lg font-medium"
                        >
                          {row.name}
                        </Link>
                        {/* FDA 승인 서비스 */}
                      </div>
                      <div className="text-sm font-medium mt-1">
                        {row.summary}
                        {/* 최저가 사이트 중 가장 최저가이지만 위시켓에서 10% 한번 더 할인! */}
                      </div>
                    </div>
                    <div className="bg-red-501 w-44">
                      <div className="text-sm mt-2">
                        {fieldName2(row.fieldCode)}
                        {/* 인허가 &gt; 인허가 &gt; 보험 */}
                      </div>
                      <div className="text-sm mt-1">
                        상주가능 세금계산서 발행
                      </div>
                    </div>
                    <div className="bg-yellow-501 w-40">
                      <div className="text-sm mt-2 font-medium text-right">
                        {priceView(row)}
                      </div>
                      <div className="text-sm mt-1 text-right">* 4.9 / 11</div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </ContentContiner>
  );
}
