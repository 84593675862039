import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  technologyIndex,
  technologySelector,
  clearState
} from './technologySlice';
import { useLocation, useHistory } from 'react-router-dom';

import { Title2 } from '../../../components/title';
import { Link } from 'react-router-dom';
import Button, { IconButton } from '../../../components/button';
import Edit from './edit';
import Delete from './delete';

import {
  fieldName2,
  techLevelName,
  experienceName
} from '../../../utils/codeView';
import Loading from '../../../components/loading';
import { getSession } from '../../../utils/session';
import goLogin from '../../../utils/goLogin';
import VerifiedBadge from '../Index/verifiedBadge';
import { PlusIcon } from '../../../components/icon';
import VerifyBox from '../Index/verifyBox';
import PartnersMenu from '../Index/partnersMenu';

const ContentContiner = styled.div`
  ${tw` h-full items-center `};
`;

const Table = styled.table`
  border-spacing: 0;
  ${tw` w-full `}
`;

const Th = styled.th`
  ${tw` py-2 px-2 text-left text-base font-medium bg-gray-50 border-t border-b border-gray-400 `}
  ${({ t1 }) => t1 && tw` w-3/12 border-l-0 `}
  ${({ t2 }) => t2 && tw` w-4/12 `}
  ${({ t3 }) => t3 && tw` w-3/12 `}
  ${({ t4 }) => t4 && tw` w-2/12 border-r-0 `}
`;

const Td = styled.td`
  ${tw` py-2 px-2 text-base font-normal border-b border-gray-300 `}
  ${({ t1 }) => t1 && tw`w-3/12 border-l-0`}
  ${({ t2 }) => t2 && tw`w-4/12`}
  ${({ t3 }) => t3 && tw`w-3/12`}
  ${({ t4 }) => t4 && tw`w-2/12 border-r-0 text-right`}
`;

export default function Content(props) {
  // auth
  const history = useHistory();

  // redux
  const dispatch = useDispatch();
  const { indexStatus, indexResult, indexData } =
    useSelector(technologySelector);

  // Edit Handle
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [technologyId, setTechnologyId] = useState();
  const handleEditOpen = (e, id) => {
    setTechnologyId(id);
    setEditOpen(true);
  };
  const editClose = () => {
    setEditOpen(false);
  };
  const handleDeleteOpen = (e, id) => {
    setTechnologyId(id);
    setDeleteOpen(true);
  };
  const deleteClose = () => {
    setDeleteOpen(false);
  };

  // refresh
  const [rowsRefreshKey, setRowsRefreshKey] = React.useState(0);
  const rowsRefresh = () => {
    const key = rowsRefreshKey + 1;
    setRowsRefreshKey(key);
  };

  // page data
  const [pageLoading, setPageLoading] = useState(true);
  const [rowsLoading, setRowsLoading] = useState(true);

  useEffect(() => {
    dispatch(technologyIndex());
    return () => {
      setRowsLoading(true);
    };
  }, [rowsRefreshKey]);

  useEffect(() => {
    if (indexStatus === 'completed') {
      if (!indexResult.success) {
        alert(indexResult.message);
        if (indexResult.error === 'auth') {
          goLogin(history);
        }
      } else {
        setPageLoading(false);
        setRowsLoading(false);
      }
    }
    return () => {
      //
    };
  }, [indexStatus]);

  return pageLoading ? (
    <ContentContiner>
      <Loading />
    </ContentContiner>
  ) : (
    <ContentContiner>
      <div className="flex-grow text-xl my-4">파트너스 정보</div>
      <VerifyBox />
      <div className="border border-gray-500 rounded-md my-6">
        <PartnersMenu active="technology" />
        <div className="px-2 my-5">
          <div className="text-sm my-4 mx-4">기술정보를 입력해 주세요</div>
          <Table>
            <thead>
              <tr>
                <Th t1>보유기술</Th>
                <Th t2>분야</Th>
                <Th t3>경험</Th>
                <Th t4></Th>
              </tr>
            </thead>
            <tbody>
              {rowsLoading ? (
                <Loading />
              ) : (
                indexData?.rows &&
                indexData?.rows.map((row, i) => {
                  return (
                    <tr key={i}>
                      <Td t1>{row.name}</Td>
                      <Td t2>{fieldName2(row.fieldCode)}</Td>
                      <Td t3>{experienceName(row.experience)}</Td>
                      <Td t4>
                        <Button
                          idx="text"
                          type="button"
                          color="blue"
                          className="mx-1"
                          onClick={(e) => handleEditOpen(e, row.id)}
                        >
                          수정
                        </Button>
                        <Button
                          idx="text"
                          color="red"
                          type="button"
                          className="mx-1"
                          onClick={(e) => handleDeleteOpen(e, row.id)}
                        >
                          삭제
                        </Button>
                      </Td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </Table>
        </div>
        <div className="flex flex-row-reverse w-full bg-gray-100 mt-10 p-3 items-end rounded-b">
          <Button type="button" onClick={handleEditOpen}>
            기술정보 추가
          </Button>
        </div>
      </div>
      <Edit
        open={editOpen}
        editClose={editClose}
        technologyId={technologyId}
        refresh={rowsRefresh}
      />
      <Delete
        open={deleteOpen}
        deleteClose={deleteClose}
        technologyId={technologyId}
        refresh={rowsRefresh}
      />
    </ContentContiner>
  );
}
